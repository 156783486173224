import React, { Component } from "react";
import Switch from "react-switch";
import ReactFileReader from "react-file-reader";
import Servicos from "../../../context/";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Button,
  Section,
  Logo,
  ListItem,
  List,
  OperationList
} from "./styles";
import User from "../../../assets/imgs/default.png";
import api from "../../../services/api";
import history from "../../../routes/history";
import Filter from "../../../components/Filter/Chart3";

export default class NewOperation extends Component {
  state = {
    value: 0,
    emails: [],
    logo_operation: "",
    name: "",
    description: "",
    status: true,
    users_in_operation: [],
    users: "",
    loading: true
  };
  deleteUser = (index, name) => {
    let novaLista = this.state.users_in_operation.filter(
      (valor, index) => valor !== name
    );
    this.setState({ users_in_operation: novaLista });
    var lista = this.state.users;
    let remover = lista.filter((valor, index) => valor !== name);
    remover.push(name);
    remover.sort();
    this.setState({ users: remover });
  };
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (e.target.value.indexOf("@") >= 0) {
        let users = this.state.users_in_operation;
        users.push(e.target.value);
        this.setState({ ...this.state, users });
        e.target.value = "";
      }
    }
  };
  handleFiles = files => {
    if (files.fileList[0].size < 400000) {
      this.setState({ logo_operation: files.base64 });
    } else {
      alert("Arquivo muito grande!");
    }
  };
  create = () => {
    if (
      this.state.name &&
      this.state.description &&
      this.state.logo_operation
    ) {
      let payload = {
        name: this.state.name,
        description: this.state.description,
        image: this.state.logo_operation,
        active: this.state.status,
        users_in_operation: this.state.users_in_operation
          ? this.state.users_in_operation
          : ""
      };
      this.context.acoes.showLoading();
      api
        .post("/operations", payload)
        .then(response => {
          this.context.acoes.hideLoading();
          this.context.acoes.setAccess(response.data.requester_profile);

          history.push("/home/operations");
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    }
    this.context.acoes.showError();
    this.context.acoes.setMessage("Faltando um nome para à operação!");
    let error = 403;
    this.context.acoes.setErrorStatus(error);
  };
  users = () => {
    this.context.acoes.showLoading();
    api
      .get("users")
      .then(response => {
        var list = [];
        for (let x in response.data.data) {
          list.push(response.data.data[x].user_id);
        }
        this.setState({ users: list.sort() });

        this.setState({ loading: false });
        // this.context.acoes.profileCall();
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);

        if (response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

        if (error.response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      }, this);
  };
  UsersToOperation = info => {
    let users = this.state.users_in_operation;
    users.push(info);
    this.setState({ users_in_operation: users });
  };
  componentDidMount() {
    this.users();
  }

  render() {
    return (
      <Container className="d-flex flex-wrap align-items-center ">
        <Header
          className="d-flex flex-wrap align-items-center w-100"
          style={{ height: "20px" }}
        >
          <div className="col-12 " style={{ marginTop: "1%" }}>
            <Title>Operações</Title>
          </div>
          <div className="d-flex flex-wrap w-100">
            <Subtitle className="col-12 ">Cadastro de Operações</Subtitle>
          </div>
        </Header>
        <Section className="col-12" style={{ marginTop: "10%" }}>
          <div className="w-100 d-flex flex-wrap">
            <div className="col-12 col-md-4 text-center">
              <ReactFileReader
                id="files"
                fileTypes={[".png", ".jpg", ".jpeg"]}
                base64={true}
                multipleFiles={false}
                handleFiles={this.handleFiles}
              >
                <Logo
                  src={
                    this.state.logo_operation ? this.state.logo_operation : User
                  }
                  className="mb-4 rounded-circle"
                  height={150}
                  style={{ cursor: "pointer" }}
                />
              </ReactFileReader>
              <p>Selecione um logo para cadastro. *</p>
            </div>
            <div className="col-12 col-md-4">
              <div className="pt-4 pb-4">
                <div className="mb-4">
                  <h6 className="color-bluedark font-weight-normal">Nome *</h6>
                  <input
                    style={{ height: "37px" }}
                    type="text"
                    className="w-100"
                    onChange={e => this.setState({ name: e.target.value })}
                  />
                </div>
                <div>
                  <h6 className="color-bluedark font-weight-normal">
                    Descrição *
                  </h6>
                  <input
                    type="text"
                    className="w-100"
                    onChange={e =>
                      this.setState({ description: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-12 p-0">
                <div className="d-flex align-items-center flex-wrap justify-content-between">
                  <h6 className="color-bluedark font-weight-normal col-12 col-md-6 p-0">
                    Status
                  </h6>
                  <div className="switch_input d-flex align-items-center justify-content-between col-12 col-md-6 pl-4 pr-4 ml-0">
                    <label className="pr-2">INATIVO</label>
                    <label htmlFor="small-radius-switch">
                      <Switch
                        checked={this.state.status}
                        onChange={value =>
                          this.setState({ status: !this.state.status })
                        }
                        onColor="#d0d0d0"
                        onHandleColor="#00A550"
                        offColor="#d0d0d0"
                        offHandleColor="#FA4D4D"
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          />
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          />
                        }
                        className="react-switch"
                        id="icon-switch"
                      />
                    </label>
                    {/* <Switch
                      className="m-0 pl-2 pr-2"
                      value={this.state.status}
                      on={true}
                      off={false}
                      style={{
                        transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                      }}
                      onChange={value =>
                        this.setState({ status: !this.state.status })
                      }
                      styles={{
                        trackChecked: {
                          backgroundColor: "#cccccc"
                        },
                        button: {
                          backgroundColor: "#999"
                        },
                        buttonChecked: {
                          backgroundColor: "green"
                        }
                      }}
                    /> */}
                    <label className="pl-2">ATIVO</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="pt-4 pb-4">
                <div className="mb-4">
                  <h6 className="color-bluedark font-weight-normal">
                    Usuários registrados
                  </h6>
                  {!this.state.loading ? (
                    <Filter
                      placeholder="Selecionar..."
                      style={{ height: "40px" }}
                      operation={this.state.users}
                      selectedUser={this.UsersToOperation}
                    />
                  ) : (
                    <small>loading</small>
                  )}
                  {/* <input
                    type="text"
                    className="w-100"
                    onKeyDown={event => this._handleKeyDown(event)}
                  /> */}
                </div>
                {typeof this.state.users_in_operation !== "undefined" &&
                this.state.users_in_operation.length > 0 ? (
                  <OperationList className="d-flex flex-wrap">
                    {this.state.users_in_operation.map((name, index) => (
                      <span class="badge" style={{ marginRight: "5px" }}>
                        {name}{" "}
                        <h7
                          onClick={() => this.deleteUser(index, name)}
                          style={{
                            fontWight: "bold",
                            cursor: "pointer",
                            marginLeft: "10px"
                          }}
                        >
                          X
                        </h7>
                      </span>
                    ))}
                  </OperationList>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 d-flex align-items-center justify-content-center pt-5">
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-around">
              <Button
                className="btn"
                variant="secondary"
                style={{ width: "200px", height: "50px", fontSize: 14 }}
                onClick={e => history.push("/home/operations")}
              >
                CANCELAR
              </Button>
              <button
                onClick={this.create}
                className={
                  !!this.state.logo_operation &&
                  !!this.state.name &&
                  !!this.state.description
                    ? "btn-success"
                    : "btn"
                }
                style={{
                  width: "200px",
                  height: "50px",
                  fontSize: 16,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                SALVAR
              </button>
            </div>
          </div>
        </Section>
      </Container>
    );
  }
}
NewOperation.contextType = Servicos;

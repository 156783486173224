import React from "react";

import { Switch, Route, Router, Redirect } from "react-router-dom";

import Main from "../pages/Main";
import Loading from '../pages/Loading'
import Portal from "../pages/Portal";

import history from "./history";
import { isAuthenticated } from "./auth";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/loading" component={Loading} />
      <PrivateRoute path="/home" component={Portal} />
    </Switch>
  </Router>
);

export default Routes;

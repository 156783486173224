import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../../../../routes'
import Xls from "./xls"
import Sql from "./sql"
import Apirest from "./apirest"
import xls from "./xlsimport"

class Wizzard extends Component{
    render(){
        return(
                <Switch>
                    <Route path="/home/imports/xls" component={Xls} />
                    <Route path="/home/importfile/xls" component={xls} />
                    <Route path="/home/imports/sql" component={Sql} />
                    <Route path="/home/imports/apirest" component={Apirest} />
                </Switch>
        )
    }
}
export default Wizzard
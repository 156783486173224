import React, { Component } from "react";
import "./xls.css";
import { Redirect } from "react-router-dom";
import Dropzone from "./dropzone/dropzone";
import Progress from "./progress/Progress";

import api from "../../../../../services/api";
// import Spinner from "../../../../utils/spinner/spinner";
// import ReactS3 from "react-s3";
import Signature from "../../../../../services/lib/Signature";
import Policy from "../../../../../services/lib/Policy";
import {
  dateISOString,
  xAmzDate,
  dateYMD
} from "../../../../../services/lib/Date";
import { throwError } from "../../../../../services/lib/ErrorThrower";
import history from "../../../../../routes/history";
import Servicos from "../../../../../context";

class Xls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      redirect: false,
      loading: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState({ files: files });
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      promises.push(this.sendRequest(file));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise(async (resolve, reject) => {
      //   {
      //     "bucketName": "analytics-import-file",
      //     "region": "us-east-1",
      //     "KeyId": "AKIAI522KNOEVKPCR55Q",
      //     "secretKey": "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"
      // }
      var config = {
        dirName: localStorage.getItem("analysis_id") /* optional */
      };
      var credentials = await api.get("/credentials");
      config.bucketName = credentials.data.bucketName;
      config.region = credentials.data.region;
      config.accessKeyId = credentials.data.KeyId;
      config.secretAccessKey = credentials.data.secretKey;

      // config.bucketName = "analytics-import-file";
      // config.region = "us-east-1";
      // config.accessKeyId = "ASIA5G5XXSHTLVHY4IEQ";
      // config.secretAccessKey = "j16t4xqIiLPz7rtwQK1O0rQvN8covheqOIC77p5m";
      // config.SessionToken = "FQoGZXIvYXdzEIL//////////wEaDGiH4jJQyQHOwvYe1yLzARlXnPB8Ptx3c4cQYnxpgJwlTzbqWY7GUBojQdGmVONiK+k0x2Osj8nz2XnLFjerzN3KNFXkk1TwsOr3LaSeh22NFRmF2Co19emtjwYAHhOQ++9NGUrmr/h823SRqXuuoLDgt3vv0M6oTJ0pYbnXtX/PnzyvVMwdTJHiyjpGUN613GTG3A0FpqhyRpb+Vf9Ja34QrhdiyuUx1sVZGVts1cT2+nVk6ala7MkAJVx1xme9OlS+6oU/+2HbxaP07soChMT0b12sae34Ioc+h5COJm9+9GHo9y5JhDfovGhswVccs0qYZw1hZaamEbKyt6VpXltULiieuYvnBQ=="
      const req = new XMLHttpRequest();
      throwError(config, file);
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        let location = `${url}${config.dirName ? config.dirName + "/" : ""}${
          file.name
        }`;
        resolve(location);

        let payload = { caminhoArquivo: location };
        this.setState({ loading: true });

        api
          .post(
            "/analysis/" + localStorage.getItem("analysis_id") + "/csv",
            payload
          )
          .then(response => {
            let relation = response.data.data.primeirasLinhas;
            this.setState({ loading: false });
            localStorage.setItem("info", response.data.requester_hash);
            history.push('/home/columnrelation');
          })
          .catch(error => {
            this.context.acoes.hideLoading();
            this.context.acoes.showError();
            this.context.acoes.setMessage(error.response.data.message);
            this.context.acoes.setErrorStatus(error.response.status);
            if (error.response.status != 500) {
              localStorage.setItem("info", error.response.data.requester_hash);
            }
          })
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      const key = `${config.dirName ? config.dirName + "/" : ""}${file.name}`;
      const url = `https://${config.bucketName}.s3.amazonaws.com/`;
      formData.append("key", key);
      formData.append("acl", "public-read");
      formData.append("Content-Type", file.type);
      formData.append("x-amz-meta-uuid", "14365123651274");
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append(
        "X-Amz-Credential",
        `${config.accessKeyId}/${dateYMD}/${config.region}/s3/aws4_request`
      );
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      // X-Amz-Security-Token
      formData.append("X-Amz-Date", xAmzDate);
      formData.append("x-amz-meta-tag", "");
      // formData.append("x-amz-security-token", config.SessionToken);
      formData.append("Policy", Policy.getPolicy(config));
      formData.append(
        "X-Amz-Signature",
        Signature.getSignature(config, dateYMD, Policy.getPolicy(config))
      );
      formData.append("file", file);
      req.open("POST", url);
      req.send(formData);
    });
  }
  redirect() {
    if (this.state.redirect) {
      return <Redirect to="/home/imports/columnrelation" />
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="./a.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <button
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Upload
        </button>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
       {this.redirect()}
       {/* <div style={{ visibility: this.state.loading ? "visible" : "hidden" }}>
              <Spinner />
            </div> */}
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default Xls;
Xls.contextType = Servicos;
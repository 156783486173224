import styled from "styled-components";

export const OperationList = styled.div`
  display: flex;
`;
export const Button = styled.div``;
export const Logo = styled.img`
  border-radius: 50px;
  max-width: 100px;
  max-height: 100px;
`;

export const Form_control = styled.select`
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  display: block;
  width: 100%;
  // height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  // font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  // background-color: #fff;
  // background-clip: padding-box;
  // border: 1px solid #ced4da;
  /* border-radius: 0.25rem; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

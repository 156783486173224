import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { Button, Logo, OperationList, Form_control } from "./styles";
import ReactFileReader from "react-file-reader";
import history from "../../../routes/history";

import User from "../../../assets/imgs/user.png";
import Operations from "../../../pages/Portal/Operations";


import api from "../../../services/api";
import Servicos from "../../../context";

export default class UserEdit extends Component {
  state = {
    value: 0,
    user_name: "",
    user_email: "",
    user_profile: "",
    active: "",
    logo_user: "",
    operations: [],
    users_in_operation: "",
    nickname: ""
  };


  componentDidMount() {}
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.user !== prevProps.user) {
      this.setState({ active: this.props.user.active });
      this.setState({ operations: this.props.user.operations });
    }
  }

  update = index => {
    this.context.acoes.showLoading();

    let userOperationsForPayload = [];

    this.props.user.user_operations.map(op => {
      userOperationsForPayload.push(op.operation_id);
    });

    let payload = {
      active: this.state.active,
      user_avatar: this.state.logo_user
        ? this.state.logo_user
        : this.props.user.user_avatar,
      user_email: this.state.user_email
        ? this.state.user_email
        : this.props.user.user_email,
      user_name: this.state.user_name
        ? this.state.user_name
        : this.props.user.user_name,
      user_profile: this.state.user_profile
        ? this.state.user_profile
        : this.props.user.user_profile,
      nickname: this.state.nickname
        ? this.state.nickname
        : this.props.user.nickname,

      user_operations: userOperationsForPayload
    };
    api
      .put("users/" + this.props.user.user_id, payload)
      .then(async response => {
        // this.context.acoes.hideLoading();
        this.setState({ logo_user: "" });
        this.context.acoes.setAccess(response.data.requester_profile)
        if(this.props.user.user_id === localStorage.getItem("data")){
          await this.context.acoes.profileCall();
          return this.props.update();
        }
        return this.props.update();
        
        // return this.props.onHide();
        // this.users()
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        // return this.props.onHide();
      });
    return this.props.onHide();
    // .then(this.users())
  };
  handleFiles = files => {
    if (files.fileList[0].size < 1048576) {
      this.setState({ logo_user: files.base64 });
    } else {
      alert("Arquivo muito grande!");
    }
  };

  defineOperationsToUser = (operation_id, event) => {
    let flagUserOperation = event.target.checked;
    this.props.defineOperationsToUser(operation_id, flagUserOperation, this);
  };
  // CheckOperation=index=>{
  //   (window.confirm("Deseja remover essa operação?") == true)
  //   ? (
  //     api.delete("/users/"+this.props.user.user_id+"/operations/"+this.state.operations[index].operation_id)
  //       .then(response =>{
  //       })
  //       .catch(error=>{
  //         localStorage.setItem("info", error.response.requester_hash);
  //       })
  //     ) : ""

  // }
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-white mr-0 mb-0" closeButton />
        <Modal.Body className="m-0 w-100 d-flex flex-wrap pt-2 ">
          <div className="col-12 col-md-4 mb-3">
            <div className="col-12 text-center">
              <ReactFileReader
                id="files"
                fileTypes={[".png", ".jpg", ".jpeg"]}
                base64={true}
                multipleFiles={false}
                handleFiles={this.handleFiles}
              >
                <Logo
                  src={
                    this.state.logo_user
                      ? this.state.logo_user
                      : this.props.user.user_avatar
                  }
                  alt="Algar"
                  for="files"
                  height="100vh"
                  width="100vh"
                  className="mb-3"
                  // onClick={e => this.updateImg()}
                  style={{ cursor: "pointer", objectFit: "cover" }}
                />
              </ReactFileReader>
              {/* <input type="file" id="files" accept="image/jpeg, image/png" multiple={false} className="d-none" /> */}
              <h6 className="color-bluedark font-weight-normal">Status</h6>
            </div>
            <div className="switch_input d-flex align-items-center justify-content-between w-100 pl-4 pr-4 ml-0">
              {/* INATIVO
              <Switch
                className="m-0"
                value={this.state.active}
                on={true}
                off={false}
                style={{
                  transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                }}
                onChange={value =>
                  this.setState({ active: !this.state.active })
                }
                styles={{
                  trackChecked: {
                    backgroundColor: "#cccccc"
                  },
                  button: {
                    backgroundColor: "red"
                  },
                  buttonChecked: {
                    backgroundColor: "green"
                  }
                }}
              />
              ATIVO */}
              <label className="pr-2">INATIVO</label>
              <label htmlFor="small-radius-switch">
                <Switch
                  checked={this.state.active}
                  onChange={value =>
                    this.setState({ active: !this.state.active })
                  }
                  onColor="#d0d0d0"
                  onHandleColor="#00A550"
                  offColor="#d0d0d0"
                  offHandleColor="#FA4D4D"
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "orange",
                        paddingRight: 2
                      }}
                    />
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "orange",
                        paddingRight: 2
                      }}
                    />
                  }
                  className="react-switch"
                  id="icon-switch"
                  disabled={(localStorage.getItem("data") == this.props.user.user_id) ? true : false}
                />
              </label>
              <label className="pl-2">ATIVO</label>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <div className="mb-4">
              <h6 className="color-bluedark font-weight-normal">Nome</h6>
              <input
                type="text"
                defaultValue={this.props.user.user_name}
                onChange={e => this.setState({ user_name: e.target.value })}
                className="w-100"
                disabled
              />
            </div>
            
            <div className="mb-4">
              <h6 className="color-bluedark font-weight-normal">Usuário</h6>
              <input
                type="text"
                value={this.props.user.user_id}
                disabled
                className="w-100"
              />
            </div>
            <div className="mb-4">
              <h6 className="color-bluedark font-weight-normal">Apelido</h6>
              <input
                type="text"
                defaultValue={this.props.user.nickname}
                onChange={e => this.setState({ nickname: e.target.value })}
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <div className="mb-4">
              <h6 className="color-bluedark font-weight-normal">Perfil</h6>
              {this.props.user?
              <Form_control
                className="m-0 p-0"
                style={{ height: 25, fontSize: 16 }}
                defaultValue={this.props.user.user_profile}
                onChange={e => this.setState({ user_profile: e.target.value })}
                id="exampleFormControlSelect1"
                disabled={
                  (this.context.acoes.access === "Administrador") ? false : true
                }
              >
                <option>Administrador</option>
                <option>Operação</option>
                <option>Mesa Digital</option>
                <option>Pré-venda</option>
              </Form_control>:"loading"}
              {/* <input type="text" defaultValue={this.props.user.user_profile} onChange={e => this.setState({user_profile: e.target.value})} className="w-100" /> */}
            </div>
            <div>
              <h6 className="color-bluedark font-weight-normal">E-mail</h6>
              <input
                type="text"
                defaultValue={this.props.user.user_email}
                onChange={e => this.setState({ user_email: e.target.value })}
                className="w-100"
                disabled
              />
            </div>
          </div>
          <div className="col-12 pt-4 pb-2">
            <div>
              <h6 className="color-bluedark font-weight-normal">Operações</h6>
              {typeof this.props.user.user_operations !== "undefined" ? (
                <OperationList className="d-flex justify-content-start flex-wrap">
                  {this.props.user.user_operations.map((op, index) => (
                    <span class="badge" style={{ marginLeft: "10px", cursor:"default" }}>
                      {this.context.acoes.access === "Administrador"  ? (
                        <input
                          id="operationCheck"
                          defaultChecked
                          type="checkbox"
                          onClick={e =>
                            this.defineOperationsToUser(op.operation_id, e)
                          }
                        />
                      ) : (
                        ""
                      )}
                      {op.operation_name}
                    </span>
                  ))}
                  {this.context.acoes.profile.user_profile === "Operação" ? null : 
                  this.props.user.other_operations.map((op, index) => (
                    <span class="badge" style={{ marginLeft: "10px" }}>
                      {this.context.acoes.access === "Administrador" ? (
                        <input
                          id="operationCheck"
                          type="checkbox"
                          onClick={e =>
                            this.defineOperationsToUser(op.operation_id, e)
                          }
                        />
                      ) : (
                        ""
                      )}
                      {op.operation_name}
                    </span>
                  ))}
                </OperationList>
              ) : (
                <small>Sem Operação</small>
              )
              }
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="m-0 w-100 border-white d-flex flex-wrap">
          <div className="col-12 col-md-6 d-flex align-items-center">
            <Button 
              onClick={this.update}
              className="btn-success"
              style={{
                width: "200px",
                height: "50px",
                fontSize: 16,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
              }}
            >
              SALVAR
            </Button>
          </div>
          {this.context.acoes.access === "Operação" ? null : (
          <div className="col-12 text-center pt-2 pb-2">
            <a href="#" style={{ cursor: "pointer"}} onClick={e => history.push("/home/operations/new")}>
              Nova operação
            </a>
          </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
UserEdit.contextType = Servicos;

import styled from "styled-components";

export const Nav = styled.nav`
  transition: 0.6s;
  background: #2a2c44;
  width: 5%;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const NavMobile = styled.nav`
  transition: 0.6s;
  background: #2a2c44;
  width: 100%;
  display: none;
  @media (max-width: 900px) {
    height: 20%;
    display: block;
  }
`;
export const HeaderNav = styled.div`
  height: 30%;
  display: flex;
  padding: 25px 5px 5px;

  img {
  }
  @media (max-width: 900px) {
    padding: 0px;
    margin-top: 0px !impotant;
    height: 50%;
    box-sizing: border-box;
  }
`;
export const Person = styled.div`
  // flex-wrap: wrap;
  // align-content: space-between;
  img {
  }
`;
export const Title = styled.h4`
  margin-bottom: 2px;
`;
export const BodyNav = styled.div`
  height: 60%;
  ul {
    .active span:before{
      color: #FFCA05!important;
    }
    .active h5{
      color: #FFCA05!important;
    }
    padding: 15px 5px 0px;
    li a{
      font-size: 22px;
      display: flex;
      padding: 18px 5px;
      color: #fff;
      align-items: center;
      justify-content: center;
      
      img{
          margin-left: 15px;
          margin-right: 10px;
      }
      h5{
        width: 80%;
        margin-left 5px;
        margin-bottom 0px;
        font-weight: 400;
      }
    }
  }
  @media (max-width: 900px) {
    height: 50%;
    display: flex;
      justify-content: space-between;
      align-items: center;

    ul{
      width: 100%;
      margin-bottom: 0px;
      padding: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      
    }
  }
`;
export const FooterNav = styled.div`
  height: 10%;
  display: flex;
  justify-content: center;
`;
export const ProfilePic = styled.img`
  border-radius: 50px;
  width:50px;
  height: 50px;
`;
export const Logo = styled.img``;
import { createGlobalStyle } from "styled-components";

// import "font-awesome/css/font-awesome.css";
import "bootstrap/dist/css/bootstrap.css";

export const GlobalStyle = createGlobalStyle`

  * {
    padding: 0px;
    margin: 0 auto;
  }
  html,body,#root {
    background: #fff;
    text-rendering: optimizeLegibility !important;
    font-family: "Helvetica Neue", Regular, sans-serif;
    height: 100%;
  }
  .chartFull{
    height:80vh;
    width:90vw;
    background: white;
  }
  .chartFullTwo{
    height:100%;
    width:100%;
    display:flex
    background: white;
  }
  .donutFull{
    height:460px;
    width:70vw;
    background: white;
  }
  .graficos {
    display:block;
    height:60vh;
    width:65vw;
    background: white;
  }
  .chart {
    display:block;
    height:100%;
    width:100%;
    background: white;
  }
  .Wordschart {
    display:flex;
    height:80vh;
    width:100%;
    background: white;
  }
  .donuts{
    height:50vh;
    width:50vw;
    background: white;
  }
  .donut{
    height: 460px;
    width: 30vw;
    background: white;
  }
  input{
    border: none;
    border-bottom: 1px solid #ddd;
    background: transparent;
  }
  .btn{
    background: #808184;
    color: #fff!important;
    width: 200px;
    padding: 8px 15px;
    border-radius: 2vw;
  }
  .btn-success { 
    color: #fff!important;
    backgorund: #00A550;
    width: 200px;
    padding: 8px 15px;
    border-radius: 2vw;
  }
  .btn-bootstrap{
    width: 200px;
    display:inline-block;
    font-weight:400;
    color:#212529;
    text-align:center;
    vertical-align:middle;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    background-color:transparent;
    border:1px solid transparent;
    padding:.375rem .75rem;
    font-size:1rem;
    line-height:1.5;
    border-radius:.25rem;
    transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
  }
  .btn-primary{
    background: #00A550;
  }
  .bg-bluelight{
    background: #00ADEE!important;
  }
  .color-bluelight{
    color: #00ADEE!important;
  }
  .bg-bluedark{
    background: #0068A5!important;
  }
  .color-bluedark{
    color: #0068A5!important;
  }
  li{
    list-style: none;
  }
  a:hover {
    text-decoration: none;
}
.scroll{
  overflow-y:scroll;
}
.arrow-menu .icon-Grupo-68:before{
  color: #fff;
}

.excluir{
  max-width: 400px;
  margin: 1.75rem auto;
  max-height: 330px;
  .modal-content{
    position: relative;
    border-radius: 25px;
    background: #F8F8F8!important;
  }
  .modal-body{
    
    padding: 0.5rem;
    width: 70%;
    padding-top: 45%;
    .card{
      top: -35%;
      background: transparent;
      border: none;
    }
  }
}
.badge{
  margin: 0px;
  margin-bottom: 10px;
  color: #808184;
  padding:15px;
  background: #F8F8F8;
  input{
    margin: 5px 10px 5px 0px;
    border-radius: 50%;
  }
}
.w3-input{
  padding:8px;
  display:block;
  border:none;
  border-bottom:1px solid #ccc;
  width:100%
}
.btn-outline-secondary{
  color:#6c757d;
  border-color:#6c757d
}
.upload-drop-zone {
  height: 200px;
  width: 200px;
  border-width: 2px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

/* skin.css Style*/
.upload-drop-zone {
  color: #ccc;
  border-style: dashed;
  border-color: black;
  line-height: 200px;
  margin-top: 25px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.upload-drop-zone.drop {
  color: #222;
  border-color: #222;
}
.btn-secondary{
  float: right; 
  margin-right: 85px;  
  margin-top: 25px;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.w-20{
  width:20% !important;
}
.w-80{
  width:80% !important;
}
.react-date-picker__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: thin solid #d4d4d4!important;
  border-radius: 5px;
  width: 100%;
  padding-left: 5%;
  padding-right: 3px;
  height: 38px;
  color: #d4d4d4;
}
.react-calendar__month-view__days button {
  // color: black;
  /* width: 10px; */
  /* padding: 0; */
  min-width: 48px;
}
.react-calendar__navigation button {
  color: black;
}
.react-date-picker__inputGroup input {
  color: #383838;
}
.react-calendar__month-view__days__day{
  color: black;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  color: black;
}
.react-date-picker__button {
  min-width: 19px;
  /* border: 0; */
  background: transparent;
  /* padding: 4px 6px; */
  margin: 0!important;
  color: #0979fb;
  padding: 0!important;
}
.MuiPickersModal-dialog:first-child{
  margin:0;
  // width:100%!important;
}
.MuiPickersYearSelection-container {
  width: 100%;
}
.MuiPickersBasePicker-pickerView > div:first-of-type {
  width:100%;
}
button{
  min-width: auto!important;
}
.MuiInputBase-input{
 
    font-size:12px!important;
    text-align: center!important;
}
// .MuiInput-underline:before {
//   border-bottom: none!important;
// }
.MuiPickersToolbar-toolbar {
  width:100%!important;
}
.MuiInputBase-root{
  border: 1px solid #ddd!important;
  border-radius: 4px!important;
  height:38px!important;
  text-align: center!important;

}
.MuiIconButton-root{
  padding:0px!important;
}
`;

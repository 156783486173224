import styled from "styled-components";

export const Container = styled.div`
  height: 30%;
  background: #fff;
  padding: 0px 1%;
  @media (max-width: 900px) {
    padding-bottom: 80%;
  }
`;
export const OperationList = styled.div`
  display: flex;
`;
export const Header = styled.div`
  margin: 5% 0% 3.5%;
`;
export const Title = styled.h2`
  color: #000;
`;
export const Subtitle = styled.small`
  font-size: 18px;
  color: #d0d0d0;
`;
export const Button = styled.button`
  font-size: 18px;
  color: #d0d0d0;
`;
export const Form = styled.form`
  color: #d0d0d0;
  position: relative;
  input {
    width: 100%;
    border-radius: 5vw;
    border: 1px solid #ccc;
    padding: 3px 10px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
  button {
    position: absolute;
    padding: 3px 10px;
    background: transparent;
    border: 0px solid;
    right: 20px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
`;

export const Section = styled.div`
  width: 100%;
  color: #d0d0d0;
  margin-top: 5%;
  h6 {
    margin-bottom: 5px;
  }
  .switch_input {
  }
`;

export const List = styled.ul`
  width: 100%;
`;

export const Logo = styled.img`
  border-radius: 50px;
  width: 150px;
  height: 150px;

`;

export const ListItem = styled.li`
  width: 100%;
  color: #d0d0d0;
  padding: 8px;
  background: #fff;
  margin-bottom: 5px;
`;

import { ResponsiveLine } from "@nivo/line";

import React, { Component } from "react";

class MyResponsiveLine extends Component {
  state = {
    dados: "",
    max: 2000
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.line !== prevProps.line) {
      this.setState({ ...this.state, dados: this.props.line });
      if (this.props.max !== prevProps.max) {
        this.setState({ max: this.props.max });
      }
    }
  }
  componentDidMount() {
    this.setState({ dados: this.props.line, max: this.props.max });
  }

  selectLineLegend = (point, event) => {
    if (this.state.dados.length > 1) {
      if (point !== undefined) {
        const estado = this.state.dados;
        let maior = 0;
        const filtro = estado.filter(item => item.id === point.id);
        for (const key in filtro) {
          const data = filtro[key].data;
          for (const i in data) {
            if (key === 0) {
              maior = data[i].y;
            }
            if (data[i].y > maior) {
              maior = data[i].y;
            }
          }
        }

        this.setState({ max: maior, dados: filtro });
      }
    } else {
      this.setState({ max: this.props.max, dados: this.props.line });
    }
  };
  selectLine = (point, event) => {
    if (this.state.dados.length > 1) {
      if (point !== undefined) {
        const estado = this.state.dados;
        let maior = 0;
        const filtro = estado.filter(item => item.id === point.serieId);
        for (const key in filtro) {
          const data = filtro[key].data;
          for (const i in data) {
            if (key === 0) {
              maior = data[i].y;
            }
            if (data[i].y > maior) {
              maior = data[i].y;
            }
          }
        }

        this.setState({ max: maior, dados: filtro });
      }
    } else {
      this.setState({ max: this.props.max, dados: this.props.line });
    }
  };
  getMax = () => {
    // if (this.state.dados.length > 1) {
    //   return 1000;
    // }else{
    //   const dados = this.state.dados.data;
    //   for (const key in dados) {
    //   }
    //   return 1000;
    // }
    return this.state.max;
  };
  verifyLength = () => {
    if (this.state.dados.length > 0) {

      if (this.state.dados[0].data.length > 2) {
        for (let a in this.state.dados) {
          var tamanho = this.state.dados[a].data.length;
          var validos = 0;
          var invalidos = 0;
          this.state.dados[a].data.map(info => {
            if (info.y === null) {
              invalidos = invalidos +1
            } else {
              validos++;
            }
          });
          if (validos > 3) {

            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  render() {
    return (
      <div style={{ height: "50vw", width: "100%" }}>
        <ResponsiveLine
          data={this.state.dados}
          margin={{top:10, right: 180, bottom: 50, left: 60 }}
          // xScale={{ type: "linear" }}
          // yScale={{ type: "linear", stacked: true, min: "auto", max: "auto"}}
          curve="monotoneX"
          axisTop={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 35
          }}
          axisRight={null}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0
          }}
          colors={{ scheme: "nivo" }}
          pointSize={10}
          pointColor={{ from: "color", modifiers: [] }}
          pointBorderWidth={2}
          pointBorderColor={{ theme: "background" }}
          isInteractive={true}
          enableSlices={false}
          crosshairType="bottom-left"
          useMesh={true}
          tooltip={
            slice => {
              return (
                <div style={{minWidth: 200, boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", backgroundColor:"white", padding:"5%", }}>
                  <small><strong style={{color:slice.point.color, fontSize:14}}>Data: </strong>{slice.point.data.x}, </small>
                  <small><strong style={{color:slice.point.color, fontSize:14}}>Qtde: </strong>{slice.point.data.y}</small>
                </div>
              )
            }
          }
          legends={[
            {
              anchor: "right",
              direction: "column",
              onClick: this.selectLineLegend,
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 90,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 10,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              legendFormat:this.logAgora,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          onClick={this.selectLine}
        />
      </div>
    );
  }
}

export default MyResponsiveLine;

import React, { Component } from "react";
import api from "../../../../services/api";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Redirect } from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
// import IsAuth from "../../../store/IsAuth";
import "./import.css";
import ReactFileReader from "react-file-reader";
import Wizzard from "./importType/importType";
import { hidden } from "ansi-colors";
// import Spinner from "../../../utils/spinner/spinner";
import Error from "../../../../components/Modal/Error";
import { Container, Header, Title, Subtitle } from "./styles";
import Servicos from "../../../../context";
import history from "../../../../routes/history";
import Modal from "react-bootstrap/Modal";

class Import extends Component {
  state = {
    base64: "",
    loading: false,
    displayP2: true,
    name_analytics: "",
    operation_analytics: "",
    logo_analytics: "",
    error: false,
    errorMessage: "",
    data: "",
    user_operations: [],
    operationCheck: "",
    redirect: false,
    fileName: "",
    modalShow: false,
    analysis_id: "",
    analysis_name: "",
    operation_name: "",
    modalCancelarShow: false,
    modalNomeShow: false,
    analyses: [],
    alreadyExists: false
  };
  componentDidMount() {
    this.context.acoes.showLoading();
    this.chamarAnalises();
    setTimeout(() => {
      if (this.context.acoes.profile.user_profile === "Operação") {
        history.push("/home");
      }
    }, 3000);
    if (
      localStorage.getItem("analysis_id") != undefined &&
      localStorage.getItem("analysis_id") != "" &&
      localStorage.getItem("analysis_id") != "null"
    ) {
      this.getSelectedStateName();

      this.setState({ analysis_id: localStorage.getItem("analysis_id") });

      this.display(false);

      // this.context.acoes.hideLoading();
    } else {
      api
        .get("/users/" + localStorage.getItem("data"))
        .then(response => {
          localStorage.setItem("profile", response.data.requester_profile);
          this.setState({
            user_operations: response.data.data.user_operations
          });
          this.context.acoes.setAccess(response.data.requester_profile);

          this.context.acoes.hideLoading();
          if (this.state.user_operations == 0) {
            this.setState({ modalShow: true });
          }
          if (response.data.requester_profile === "Operação") {
            history.push("/home");
          }
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    }
  }
  chamarAnalises = async () => {
    await this.analyses();
  };

  analyses = () => {
    api
      .get("/analysis")
      .then(response => {
        this.setState({ analyses: response.data.data.Items });
        // this.setState({ loading: true });
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.setAccess(error.response.data.requester_profile);

        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
      });
    // this.context.acoes.hideLoading();
  };
  checkName = value => {
    this.setState({ alreadyExists: false });
    for (var i = 0; i < this.state.analyses.length; i++) {
      if (this.state.analyses[i].name === value) {
        this.openNomeClose();
        this.setState({ ...this.state, alreadyExists: true });
      }
    }
  };
  getSelectedStateName = () => {
    this.context.acoes.showLoading();
    api
      .get("/analysis/" + localStorage.getItem("analysis_id"))
      .then(response => {
        this.setState({ analysis_name: response.data.data.name });
        this.setState({ operation_name: response.data.data.operation_name });
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.showError();
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(error.response.data.requester_profile);

        this.context.acoes.setMessage(
          "Houve um erro ao carregar os dados da análise"
        );

        this.context.acoes.setErrorStatus(error.response.status);
      });
  };
  modalClose = () => {
    this.setState({ modalShow: false });
    history.push("/home");
  };
  modalCancelarClose = () => {
    this.setState({ ...this.state, modalCancelarShow: false });
  };
  openCancelarClose = () => {
    this.setState({ ...this.state, modalCancelarShow: true });
  };
  modalNomeClose = () => {
    this.setState({ ...this.state, modalNomeShow: false });
  };
  openNomeClose = () => {
    this.setState({ ...this.state, modalNomeShow: true });
  };
  handleFiles = files => {
    var type = files.fileList[0].type;
    // type.substr(6);
    if (
      type.substr(6) === "png" ||
      type.substr(6) === "jpg" ||
      type.substr(6) === "jpeg"
    ) {
      // if (Math.floor(img.width / img.height) < 2) {
      if (files.fileList[0].size < 1000000) {
        this.setState({ logo_analytics: files.base64 });
        this.setState({ fileName: files.fileList[0].name });
      } else {
        alert("Tamanha da imagem deve ser menor que 1 MB!");
      }
      // } else {
      //   alert("Tamanho de imagem muito grande!");
    } else {
      alert("Formato de arquivo inválido!");
    }
  };
  display = sendRequestToApi => {
    this.context.acoes.showLoading();
    var { name_analytics, logo_analytics, operationCheck } = this.state;
    this.setState({ loading: true });
    this.checkName(name_analytics);
    if (this.state.alreadyExists == false) {
      if (sendRequestToApi === false) {
        this.setState({ displayP2: !this.state.displayP2 });
        // this.context.acoes.hideLoading();
      } else {
        if (!!name_analytics && !!logo_analytics && !!operationCheck) {
          var data = {
            name: name_analytics,
            logo: logo_analytics,
            operation_id: operationCheck
          };
          this.setState({ ...this.state, name_analytics: null });
          api
            .post("/analysis", data)
            .then(response => {
              // this.setState({ loading: false });
              this.context.acoes.hideLoading();
              localStorage.setItem(
                "analysis_id",
                response.data.data.analysis_id
              );
              this.context.acoes.setAccess(response.data.requester_profile);

              this.setState({ displayP2: !this.state.displayP2 });
              this.setState({ name_analytics: "", operation_analytics: "" });
            })
            .catch(error => {
              if (error.response.status != 403) {
                this.context.acoes.hideLoading();
                this.context.acoes.showError();
                this.context.acoes.setMessage(error.response.data.message);
                this.context.acoes.setErrorStatus(error.response.status);
                this.context.acoes.setAccess(
                  error.response.data.requester_profile
                );
              } else {
                this.context.acoes.hideLoading();
                this.context.acoes.showError();
                this.context.acoes.setAccess(
                  error.response.data.requester_profile
                );

                this.context.acoes.setMessage(error.response.data.message);
                this.context.acoes.setErrorStatus(error.response.status);
              }
            });
        }
      }
    } else {
      this.context.acoes.hideLoading();
      this.openNomeClose();
    }
  };

  handleClose = () => {
    this.setState({ error: false });
    // this.setState({ redirect: true });
  };

  redirect() {
    if (this.state.redirect) {
      return <Redirect to="/app/columnrelation" />;
    }
  }

  liberar = () => {
    this.setState({ liberado: !this.state.liberado });
  };
  avancar = () => {
    this.context.acoes.liberar();
    return history.push("/home/columnrelation");
  };
  cancelar = () => {
    this.context.acoes.showLoading();

    api
      .delete("/analysis/" + localStorage.getItem("analysis_id") + "/hard")
      .then(response => {
        this.context.acoes.hideLoading();
        this.modalCancelarClose();
        this.context.acoes.setAccess(response.data.requester_profile);

        return history.push("/home");
      })
      .catch(error => {
        if (error.response.status != 403) {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
        } else {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        }
      });
  };
  componentWillUnmount() {
    this.context.acoes.liberar();
  }
  render() {
    const {
      displayP2,
      logo_analytics,
      name_analytics,
      loading,
      error,
      errorMessage,
      data,
      user_operations,
      operationCheck,
      fileName
    } = this.state;
    let hasAnalysisIdSet =
      this.state.analysis_id != null &&
      this.state.analysis_id != "" &&
      this.state.analysis_id != undefined;
    return (
      <Servicos.Consumer>
        {context => (
          <div className="IMPORT">
            <Error
              show={this.state.modalShow}
              onHide={e => this.modalClose()}
              message={"Você não possui uma operação associada ao seu usuário!"}
              status={401}
            />
            <Modal
              {...this.props}
              dialogClassName="modal-80w excluir"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              analise={this.state.selecionado}
              show={this.state.modalCancelarShow}
              onHide={e => this.modalCancelarClose()}
            >
              {/* <Modal.Header closeButton /> */}
              <Modal.Body
                className="position-relative d-flex justify-content-center"
                style={{ paddingTop: "10%" }}
              >
                <div>
                  <h4 style={{ "text-align": "center" }}>
                    Tem certeza que deseja cancelar o processo?
                  </h4>
                </div>
              </Modal.Body>

              <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
                <button
                  className="btn-success mb-3"
                  style={{
                    width: 200,
                    height: 50,
                    fontSize: 14,
                    marginRight: 0
                  }}
                  onClick={e => this.cancelar()}
                >
                  Sim
                </button>
                <button
                  className="btn mb-3"
                  style={{
                    width: 200,
                    height: 50,
                    fontSize: 14,
                    marginLeft: 0
                  }}
                  onClick={e => this.modalCancelarClose()}
                >
                  Não
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              {...this.props}
              dialogClassName="modal-80w excluir"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              analise={this.state.selecionado}
              show={this.state.modalNomeShow}
              onHide={e => this.modalNomeClose()}
            >
              {/* <Modal.Header closeButton /> */}
              <Modal.Body
                className="position-relative d-flex justify-content-center"
                style={{ paddingTop: "10%" }}
              >
                <div>
                  <h4 style={{ "text-align": "center" }}>
                    Nome de importação já existente!
                  </h4>
                </div>
              </Modal.Body>

              <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
                <button
                  className="btn mb-3"
                  style={{
                    width: 200,
                    height: 50,
                    fontSize: 14,
                    marginLeft: 0
                  }}
                  onClick={e => this.modalNomeClose()}
                >
                  Ok
                </button>
              </Modal.Footer>
            </Modal>
            <div className="IMPORT-LEFT">
              <Container className=" ">
                <Header
                  className="d-flex flex-wrap align-items-center w-100"
                  style={{ height: "20px" }}
                >
                  <div
                    className="col-12 "
                    style={{ marginTop: "10%", marginLeft: "3.5%" }}
                  >
                    <Title>Análises</Title>
                  </div>
                  <div
                    className="d-flex flex-wrap w-100"
                    style={{ marginLeft: "3.5%" }}
                  >
                    <Subtitle className="col-12">Criar análise</Subtitle>
                  </div>
                </Header>
              </Container>
              <div className="IMPORT-LEFT-FORM">
                <Form>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Control
                      bsPrefix="IMPORT-LEFT-FROM-NAME"
                      defaultValue={
                        hasAnalysisIdSet ? this.state.analysis_name : ""
                      }
                      disabled={!displayP2 ? true : false}
                      type="text"
                      placeholder="Nome *"
                      onBlur={e => this.checkName(e.target.value)}
                      onChange={event =>
                        this.setState({ name_analytics: event.target.value })
                      }
                      style={{ fontSize: "16px" }}
                    />
                  </Form.Group>
                  {!(this.state.analysis_id != "") ? (
                    <ReactFileReader
                      fileTypes={[".png", ".jpg", ".jpeg"]}
                      base64={true}
                      multipleFiles={false}
                      handleFiles={this.handleFiles}
                      disabled={displayP2 ? false : true}
                    >
                      <Button
                        style={{
                          fontSize: "16px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: 231
                        }}
                        bsPrefix="IMPORT-LEFT-FROM-LOGO"
                      >
                        {fileName ? fileName : "Importar logomarca *"}
                      </Button>
                    </ReactFileReader>
                  ) : (
                    ""
                  )}
                  {!(this.state.analysis_id != "") ? (
                    <Form.Group as={Row}>
                      <div className="IMPORT-LEFT-FORM-OPERATIONS ">
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "20px",
                            color: "#808184",
                            marginLeft: "5%",
                            fontWeight: "normal"
                          }}
                        >
                          Operações *
                        </p>
                      </div>
                      {typeof user_operations !== "undefined" &&
                      user_operations.length > 0 ? (
                        <div className="operations-options">
                          {user_operations.map(operation => (
                            <Form.Check
                              disabled={!displayP2 ? true : false}
                              style={{ fontSize: "16px" }}
                              type="radio"
                              className="operations-info"
                              label={operation.operation_name}
                              name="formHorizontalRadios"
                              id={operation.operation_id}
                              onClick={() =>
                                this.setState({
                                  operationCheck: operation.operation_id
                                })
                              }
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="operations-options">
                          Você não possui uma operação associada ao seu usuário,
                          contate um administrador!
                        </div>
                      )}
                    </Form.Group>
                  ) : (
                    <Form.Group as={Row}>
                      <div className="IMPORT-LEFT-FORM-OPERATIONS ">
                        <p
                          style={{
                            fontSize: "16px",
                            marginTop: "20px",
                            color: "#808184",
                            marginLeft: "5%",
                            fontWeight: "normal"
                          }}
                        >
                          Operação
                        </p>
                      </div>
                      <div className="operations-options">
                        <Form.Check
                          disabled={true}
                          style={{ fontSize: "16px" }}
                          type="radio"
                          className="operations-info"
                          label={this.state.operation_name}
                          name="formHorizontalRadios"
                          id={this.state.operation_id}
                          checked={true}
                        />
                      </div>
                      {/* <Form.Control
                        bsPrefix="IMPORT-LEFT-FROM-OPERATION"
                        type="text"
                        placeholder="Nome"
                        value={this.state.operation_name}
                        style={{ marginLeft: "5%"}}
                      /> */}
                    </Form.Group>
                  )}
                </Form>
              </div>
              <div className="IMPORT-LEFT-BUTTON">
                {this.state.analysis_id === "" ? (
                  <button
                    disabled={
                      !!name_analytics && !!logo_analytics && !!operationCheck
                        ? false
                        : true
                    }
                    onClick={this.display}
                    className={
                      !!name_analytics && !!logo_analytics && !!operationCheck
                        ? "btn-success"
                        : "btn"
                    }
                    style={{
                      width: "200px",
                      height: "50px",
                      fontSize: 16,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "6%"
                    }}
                  >
                    AVANÇAR
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="IMPORT-RIGHT">
              <div
                className="IMPORT-RIGHT-BLOCK"
                style={{
                  visibility: !displayP2 ? "hidden" : "visible"
                }}
              >
                <div style={{ visibility: loading ? "visible" : "hidden" }}>
                  {/* <Spinner /> */}
                </div>
              </div>
              <div className="IMPORT-RIGHT-HEADER">{/* <p></p> */}</div>
              <div className="IMPORT-LEFT-FORM-OPERATIONS ">
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "#838487",
                    height: "100%",
                    margin: 0
                  }}
                >
                  Modo de importação
                </p>
              </div>
              <div className="IMPORT-RIGHT-BUTTONS">
                <div className="IMPORT-RIGHT-BUTTONS-ACTION">
                  <Form.Check
                    type="radio"
                    className="operations-info"
                    label={"CSV/XLS"}
                    name="formHorizontalRadios"
                    onClick={() => history.push("/home/imports/xls")}
                    style={{
                      marginLeft: "5%",
                      marginRight: "5%",
                      height: "55px"
                    }}
                  />
                  <Form.Check
                    type="radio"
                    className="operations-info"
                    label={"SQL"}
                    name="formHorizontalRadios"
                    onClick={() => history.push("/home/imports/sql")}
                    style={{ marginRight: "5%", height: "55px" }}
                  />
                  <Form.Check
                    type="radio"
                    className="operations-info"
                    label={"API Rest"}
                    name="formHorizontalRadios"
                    onClick={() => history.push("/home/imports/apirest")}
                    style={{ marginRight: "5%", height: "55px" }}
                  />
                </div>
              </div>
              <div className="IMPORT-RIGHT-FORM">
                <Wizzard />
              </div>
              <div className="IMPORT-LEFT-BUTTON">
                <button
                  className="btn"
                  onClick={e => this.openCancelarClose()}
                  style={{
                    width: "200px",
                    height: "50px",
                    fontSize: 16,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "60px"
                  }}
                >
                  CANCELAR
                </button>

                <button
                  disabled={this.context.acoes.liberado ? false : true}
                  className={
                    this.context.acoes.liberado ? "btn-success" : "btn"
                  }
                  onClick={e => this.avancar()}
                  style={{
                    width: "200px",
                    height: "50px",
                    fontSize: 16,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  AVANÇAR
                </button>
              </div>
            </div>
          </div>
        )}
      </Servicos.Consumer>
    );
  }
}

export default Import;
Import.contextType = Servicos;

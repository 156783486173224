import React, { Component } from "react";
import Select from "react-select";
import Servicos from "../../context";

const customStyles = {
  control: styles => ({
    ...styles,
    height: 30,
    border: "none",
    borderBottom: "1px solid #ddd",
    background: "transparent",
    borderRadius: 0,
    minHeight:37
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 12,
    color:"black"
  })
};
export default class Filter extends Component {
  state = {
    operation: "",
    selectedUser: null,
    selectedrequester_users: null,
    selectedtopics: null,
    selectedticket_status: null,
    selectedautomatizaveis: null,
    topics: [],
    ticket_status: [],
    automatizaveis: [],
    requester_users: [],
    service_users: []
  };
  user = selectedUser => {
    let lista = [];
    for (let x in selectedUser) {
      lista.push(selectedUser[x].value);
    }
    let remover = this.state.operation.filter(
      (valor, index) => valor.label !== selectedUser[0].label
    );
    this.setState({ operation: remover });
    this.props.selectedUser(lista[0]);
  };

  componentDidUpdate(prevProps) {
    if (this.props.operation !== prevProps.operation) {
      var lista = [];
      for (let x in this.props.operation) {
        lista.push({
          label: this.props.operation[x],
          value: this.props.operation[x]
        });
      }
      lista.sort()
      this.setState({ operation: lista });
    }

  }
  componentDidMount() {
    var lista = [];
    for (let x in this.props.operation) {
      lista.push({
        label: this.props.operation[x],
        value: this.props.operation[x]
      });
    }
    lista.sort()
    this.setState({ operation: lista });
  }
  render() {
    const { selectedUser, operation } = this.state;
    return (
      <div className="w-100">
        <Select
          styles={customStyles}
          value={""}
          onChange={this.user}
          options={operation}
          placeholder="Selecionar"
          isMulti
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}
Filter.contextType = Servicos;

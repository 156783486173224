import React from 'react'
import Draggable from '../Draggable'
import Droppable from '../Droppable'




export default class DndTest extends React.Component{

    render(){
        return(
            <wrapper>
                <Droppable id="dr1">
                    <Draggable id="item1">teste01</Draggable>
                    <Draggable id="item2">teste02</Draggable>
                </Droppable>
                <Droppable id="dr2"></Droppable>
            </wrapper>
        )
    }
}
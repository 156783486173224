import styled from "styled-components";

export const Container = styled.div`
  height: 30%;
  background: #fff;
  padding: 0px 1%;
`;
export const Listagem = styled.div`
  margin-top: -40px;
  @media (max-width: 900px) {
    margin-top: -60px;
  }
`;
export const Button = styled.button`
  font-size: 18px;
  color: #d0d0d0;
`;
export const List = styled.div`
  width: 30%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const Charts = styled.div`
  // transition: 0.6s;
  width: 70%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const Header = styled.div`
  padding: 20% 0% 13.5%;
  @media (max-width: 900px) {
    padding: 5% 0% 0%;
  }
`;
export const Title = styled.h2`
  color: #000;
`;
export const Subtitle = styled.small`
  font-size: 18px;
  color: #d0d0d0;
`;
export const Form = styled.form`
  color: #d0d0d0;
  position: relative;
  input {
    width: 100%;
    border-radius: 5vw;
    border: 1px solid #ccc;
    padding: 3px 10px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
  button {
    position: absolute;
    padding: 3px 10px;
    background: transparent;
    border: 0px solid;
    right: 20px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
`;

export const Section = styled.section`
  width: 100%;
  color: #d0d0d0;
  h6 {
    margin-bottom: 2px;
  }
  .switch_input {
  }
`;

// export const List = styled.ul`
//   width: 100%;
//   color: #d0d0d0;
// `;

export const Logo = styled.img``;

export const ListItem = styled.li`
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 80px;
  display: flex;
  color: #d0d0d0;
  background: #fff;
  align-items: center;
  small {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    width: 150px;
    display: inline-block;
    height: 20px;
    overflow: hidden;
  }
  @media (max-width: 900px) {
    height: auto;
    padding-top: 15px;
    div {
      margin-bottom: 10px;
    }
  }
`;

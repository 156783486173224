import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center:
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index:0;
`;
export const Logo = styled.img``;
export const Title = styled.h2`
  font-size: 32px;
  font-weight: 400;
`;
export const Login = styled.div`
  width: 100%;
  height: 320px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color:white;

  small {
    text-transform: uppercase;
    font-size: 14px;
  }
`;
export const PrimaryBtn = styled.button`
  border-radius: 25px;
  border: none;
  padding: 10px 15px;
  width: 200px;
  height: 50px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 300;
  margin: 0 auto;
`;

export const Credentails = styled.div`
  margin-bottom: 5%
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color:white;

  small {
    text-transform: uppercase;
    font-size: 14px;
  }
`;
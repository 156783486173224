import { ResponsivePieCanvas } from "@nivo/pie";
import React, { Component } from "react";

// var data = require("./teste.json");

export default class Donuts extends Component {
  selectLineLegend = (point, event) => {
    this.props.selectedDonut(point.label);
  };
  state = {
    data: []
  };
  componentDidMount() {
    this.setState({ data: this.props.data });
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }
  render() {
    return (
      <div
        className="d-flex flex-wrap text-center w-50 mt-3 mb-3 h-100"
        style={{ boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)" }}
      >
        <div className={this.props.full ? "donutFull" : "donut"}>
          <div className="pt-3 pb-3 h-20" style={{ color: "#2b2c43" }}>
            <h4>{this.props.donutWords[0].number_of_tickets}</h4>
            <small>Chamados Totais</small>
          </div>
         
          <ResponsivePieCanvas
            data={this.state.data}
            margin={{right: 0, bottom: 100, left: 20 }}
            pixelRatio={1}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'paired' }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.6 ] ] }}
            enableRadialLabels={false}
            // radialLabelsSkipAngle={10}
            // radialLabelsTextXOffset={6}
            // radialLabelsTextColor="#333333"
            // radialLabelsLinkOffset={0}
            // radialLabelsLinkDiagonalLength={16}
            // radialLabelsLinkHorizontalLength={24}
            // radialLabelsLinkStrokeWidth={1}
            // radialLabelsLinkColor={{ from: 'color' }}
            // enableSlicesLabels={false}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#000"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            // onClick={this.selectLineLegend}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            legends={[
              {
                anchor: "right",
                onClick: this.selectLineLegend,
                direction: "column",
                translateX: 140,
                itemWidth: 60,
                itemHeight: 14,
                itemsSpacing: 2,
                symbolSize: 14,
                symbolShape: "circle"
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

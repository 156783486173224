import React, { Component } from "react";
import "./graficoquatro.css";
import Tooltip from "@material-ui/core/Tooltip";
// import "./GraphsThree.css";

class GraphsFour extends Component {
  constructor(props) {
    super(props);
    // this.setState({ id: this.props.info });
  }
  state = {
    loading: false,
    id: "",
    page: 1,
    chart_data: [],
    dados: ""
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.data !== prevProps.data) {
      this.setState({ chart_data: this.props.data });
    }
  }
  componentDidMount() {
    this.setState({ chart_data: this.props.data });
    // // if (this.state.id) {
    // api
    //   .get("analysis/" + this.props.info + "/chartdata/database?page="+this.state.page+"&rows_per_page=5")
    //   .then(response => {
    //       // this.setState({ show: !this.state.show });
    //       localStorage.setItem("info", response.data.requester_hash);
    //       // var test = response.data.chart_data.data;
    //       this.setState({ chart_data: response.data.chart_data });
    //   })
    //   .catch(err => {
    //     //   this.setState({ show: !this.state.show });
    //   });
    // // }
  }
  //   next=()=>{
  //     let pagina = this.state.page + 1
  //     this.setState({page: pagina})
  //     api
  //       .get("analysis/" + this.props.info + "/chartdata/database?page="+this.state.page+"&rows_per_page=5")
  //       .then(response => {
  //           // this.setState({ show: !this.state.show });
  //           localStorage.setItem("info", response.data.requester_hash);
  //           // var test = response.data.chart_data.data;
  //           this.setState({ chart_data: response.data.chart_data });
  //       })
  //       .catch(err => {
  //         //   this.setState({ show: !this.state.show });
  //       });
  //   }
  //   prev=()=>{
  //     let pagina = this.state.page - 1
  //     this.setState({page: pagina})
  //     api
  //       .get("analysis/" + this.props.info + "/chartdata/database?page="+this.state.page+"&rows_per_page=5")
  //       .then(response => {
  //           // this.setState({ show: !this.state.show });
  //           localStorage.setItem("info", response.data.requester_hash);
  //           // var test = response.data.chart_data.data;
  //           this.setState({ chart_data: response.data.chart_data });
  //       })
  //       .catch(err => {
  //         //   this.setState({ show: !this.state.show });
  //       });
  //   }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day,month,year].join("-");
  }
  render() {
    var { loading, chart_data } = this.state;

    return (
      <>
        <div className="PAGEFOUR-GRAFICO-TOP">
          <div className="PAGEFOUR-GRAFICO-CARD-ONE">
            <div className="PAGEFOUR-GRAFICO-CARD-ONE-CONTENT">
              <div className="GRAPHS-FOUR-TABLE">
                <div className="GRAPH-TABLE-HEADER">
                  <div>TICKET ID</div>
                  <div>DATA DE ABERTURA</div>
                  <div>USUÁRIO SOLICITANTE</div>
                  <div>% DE CONFIANÇA</div>
                  <div>TÓPICO</div>
                  <div>AUTOMATIZÁVEL</div>
                  <div>TICKET STATUS</div>
                  <div>DESCRIÇÃO</div>
                </div>
              </div>
              <div className="GRAPHS-FOUR-TABLE-CONTENT">
                {this.state.chart_data.map(
                  data => (
                    (
                      <div className="GRAPHS-FOUR-CONTENT-DATA">
                        <div>{data.Ticket_ID}</div>
                        <div>{this.formatDate(data.Dt_Abertura)}</div>
                        <Tooltip title={data.Usuario_solicitante}>
                          <div
                            style={{ textOverflow: "clip", cursor: "default" }}
                          >
                            {data.Usuario_solicitante.substr(0, 13)}
                            {data.Usuario_solicitante.length > 18 ? "..." : ""}
                          </div>
                        </Tooltip>
                        <div>{data.Confianca == "NaN" ? "0" : data.Confianca}%</div>
                        <div>{data.Topico}</div>
                        <div>{data.Automatizavel}</div>
                        <div>{data.Ticket_Status}</div>
                        <Tooltip title={data.Descricao_ticket}>
                          <div
                            style={{ textOverflow: "clip", cursor: "default" }}
                          >
                            {data.Descricao_ticket.substr(0, 18)}
                            {data.Descricao_ticket.length > 18 ? "..." : ""}
                          </div>
                        </Tooltip>
                      </div>
                    )
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GraphsFour;

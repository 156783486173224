import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//mport "../card/TOPICO-DATA-CARD-INFO.css";
import Switch from "react-switch";
//import "./card/TOPICO-MODAL.css";
import Demo from './demo'

class TopicosCard extends Component {
  state = {
    show: false,
    open: false,
    hidden: false,
    palavras:[]
  };
  handleHiddenChange = (description) => {
    this.props.setAutomatizavel(!this.state.hidden,this.props.data.topico, description)
    this.setState({hidden:!this.state.hidden})
    
  };
  handlePalavras=(description,e)=>{
    // this.setState({palavras:e})
    // this.setState(prevState => ({ palavras: [...this.state.palavras, e] }));
    this.props.onPalavras(e)
  }

  setCategoria=(description,e)=>{
    this.props.setCategoria(e.label,this.props.data.topico,description)
  }

  render() {
    const { hidden } = this.state;
    const data = this.props.data;
    const teste =[{ label: 'Afghanistan' },{ label: 'Aland Islands' }]
    this.props.addClassificacaoTopic(data.topico,data.palavras);
    return (
      <div className="TOPICO-DATA-CARD-INFO">
        <div class="TOPICO-DATA-CARD-TEXT">
          <div className="TOPICO-DATA-CARD-TEXT-ONE">
            <h1 style={{"color":"#00ADEE","font-family": "Helvetica Neue","font-size":"16px","margin-bottom": "10px !important"}}>Tópico</h1>
            <p>{data.palavras.join().substring(0,350)}</p>
          </div>
          
          <div className="TOPICO-DATA-CARD-TEXT-TWO">
          
            <div className="TOPICO-DATA-CARD-FLEX">
             <h1 style={{"color":"#00ADEE","font-family": "Helvetica Neue","font-size":"16px","margin-bottom": "10px !important"}}>Categoria</h1>
              <Demo palavras={this.props.palavras} setCategoria={e => this.setCategoria(data.palavras,e)} onTest={e => this.handlePalavras(data.palavras,e)} />
            </div>
          </div>

          <div className="TOPICO-DATA-CARD-TEXT-THREE">
          <h1 style={{"color":"#00ADEE","font-family": "Helvetica Neue","font-size":"16px","margin-bottom": "10px !important"}}>Auto</h1>

            <div className="TOPICO-DATA-CARD-TEXT-THREE-TITLE">

            <span style={{"color":"#808184","font-family": "Helvetica Neue","font-size":"16px"}}>Não</span>
            <FormControlLabel
              control={
                <Switch
                        checked={hidden}
                        onChange={e => this.handleHiddenChange(data.palavras)}
                        onColor="#d0d0d0"
                        onHandleColor="#00A550"
                        offColor="#d0d0d0"
                        offHandleColor="#FA4D4D"
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          >
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          >
                          </div>
                        }
                        className="react-switch"
                        id="icon-switch"
                      />
              }
              style={{    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    marginLeft: 5,
                    marginRight: 5,
                    verticalAlign: "middle",
                    marginBottom: 0}}
            />
            <span style={{"color":"#808184","font-family": "Helvetica Neue","font-size":"16px"}}>Sim</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopicosCard;



import React from 'react'
import PropsTypes from 'prop-types'
import { height } from 'window-size';


const normalStyle = {
    paddingRight:"1%",    
    height:"50px"
  };

  const allowDropStyle = {
    // marginTop: "15px",
    border: "2px dashed #00A54F",
    borderRadius: 25,
    height:50,
    paddingRight:"1%"
  };

export default class DroppableBack extends React.Component{
    state={
        drop:false
    }
    drop = (e) =>{
        e.preventDefault();
        this.setState({drop:false})
        this.props.droppedToDroppableBack(e);
    }
    allowDrop=(e)=>{
        e.preventDefault()  
        this.setState({drop:true})
    }

    leftDrag=(e)=>{
        e.preventDefault()  
        this.setState({drop:false})
    }
    render(){
        return(
            <div id={this.props.id} onDragLeave={this.dragExit} onDrop={this.drop} onDragOver={this.allowDrop} onDragLeave={this.leftDrag} style={(this.state.drop) ? allowDropStyle : normalStyle}>
                {this.props.children}
            </div>
        )
    }
}

DroppableBack.propsTypes ={
    id: PropsTypes.string,
    style: PropsTypes.object,
    children: PropsTypes.node
}
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  padding: 0px 1%;
`;
export const Header = styled.div`
  height: 15%;
  margin: 5% 0% 2%;
`;
export const Title = styled.h6`
  color: #000;
  margin-bottom: 0px;
`;

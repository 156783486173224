import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import api from "../../services/api";
import { Container, Title, SubTitle, Date } from "./styles";
import Servicos from "../../context";
import Tooltip from "@material-ui/core/Tooltip";

// import "./index.css";

import moment from "moment";
export default class Card extends Component {
  state = {
    analise: "",
    favorite: false
  };
  selecionar = () => {
    this.props.selecionado(this.props.analise);
    // this.context.acoes.showLoading();
  };

  favoriteAnalysis = (status, id, e) => {
    if (this.props.analise.status != "deleted"){
    
      e.stopPropagation();
      this.context.acoes.showLoading();
      api
        .put("/analysis/" + id + "/favorite", {
          favorite: status
        })
        .then(response => {
          this.context.acoes.setAccess(response.data.requester_profile);
          this.context.acoes.setAccess(response.data.requester_profile);
          this.props.update();
          // }, 100);
          return true;
        })
        .catch(error => {
          this.context.acoes.setAccess(error.response.data.requester_profile);

          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    }
    
  };
  componentDidMount() {
    this.setState({ analise: this.props.analise });
    moment.locale("pt"); // setar o locale para "pt" (Português)

    moment.updateLocale("pt", {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
    });
  }

  render() {
    let textoStatus = "";
    if (this.props.analise.data_pipeline_status == "FAILED") {
      textoStatus = "Erro";
    } else {
      switch (this.props.analise.next_stage_pending) {
        case "n/a":
          textoStatus = "Em execução";
          break;
        default:
          if (this.props.analise.data_pipeline_done == true) {
            textoStatus = "Finalizada";
          } else {
            textoStatus = "Ação pendente";
          }
          break;
      }
    }

    return (
      <Container
        onClick={this.selecionar}
        className="mb-4 position-relative items"
        style={{ cursor: "pointer" }}
      >
        <div className="position-absolute pr-3 pt-3 justify-content-end favorite">
          {this.props.analise.favorite ? (
            <div
              className="h3 mr-1"
              onClick={e =>
                this.favoriteAnalysis(false, this.props.analise.analysis_id, e)
              }
              isHidden={false}
            >
              <Tooltip title="Desfavoritar" placement="top">
                <span
                  className="fas fa-star"
                  style={{
                    color: "#feca31",
                    fontSize: 18,
                    cursor: "pointer",
                    margin: "0!important"
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
          {this.props.analise.favorite == false ? (
            <div
              className="h3 mr-1"
              onClick={e =>
                this.favoriteAnalysis(true, this.props.analise.analysis_id, e)
              }
              isVisible={false}
            >
              <Tooltip title="Favoritar" placement="top">
                <span
                  className="fas fa-star"
                  style={{
                    color: "#c2c2c2",
                    fontSize: 18,
                    cursor: "pointer",
                    margin: "0!important"
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
        <div className="position-absolute w-25 status">
          {this.props.analise.next_stage_pending === "base-import" ? (
            <div
              style={{
                width: 85,
                zIndex: 8,
                height: 20,
                borderRadius: 50,
                background: "rgb(194, 194, 194)",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#ffc107",
                  width: 10,
                  display: "block",
                  height: "100%",
                  margin: 0
                }}
              />
              <small
                style={{
                  color: "rgb(255, 255, 255)",
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  lineHeight: "20px"
                }}
              >
                10%
              </small>
            </div>
          ) : null}
          {this.props.analise.next_stage_pending === "column-relations" ? (
            <div
              style={{
                width: 85,
                zIndex: 8,
                height: 20,
                borderRadius: 50,
                background: "rgb(194, 194, 194)",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#ffc107",
                  width: "20%",
                  display: "block",
                  height: "100%",
                  margin: 0
                }}
              />
              <small
                style={{
                  color: "rgb(255, 255, 255)",
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  lineHeight: "20px"
                }}
              >
                20%
              </small>
            </div>
          ) : null}
          {this.props.analise.next_stage_pending === "stopwords" ? (
            <div
              style={{
                width: 85,
                zIndex: 8,
                height: 20,
                borderRadius: 50,
                background: "rgb(194, 194, 194)",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#ffc107",
                  width: "40%",
                  display: "block",
                  height: "100%",
                  margin: 0
                }}
              />
              <small
                style={{
                  color: "rgb(255, 255, 255)",
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  lineHeight: "20px"
                }}
              >
                40%
              </small>
            </div>
          ) : null}
          {this.props.analise.next_stage_pending === "topics" ? (
            <div
              style={{
                width: 85,
                zIndex: 8,
                height: 20,
                borderRadius: 50,
                background: "rgb(194, 194, 194)",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#ffc107",
                  width: "70%",
                  display: "block",
                  height: "100%",
                  margin: 0
                }}
              />
              <small
                style={{
                  color: "rgb(255, 255, 255)",
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  lineHeight: "20px"
                }}
              >
                70%
              </small>
            </div>
          ) : null}
          {this.props.analise.next_stage_pending === "failed" ? (
            // <ProgressBar variant="danger" now={100} />
            <div
              style={{
                width: 85,
                zIndex: 8,
                height: 20,
                borderRadius: 50,
                background: "rgb(194, 194, 194)",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  background: "#dc3545 ",
                  width: 100,
                  display: "block",
                  height: "100%",
                  margin: 0
                }}
              />
              <small
                style={{
                  color: "rgb(255, 255, 255)",
                  position: "absolute",
                  top: 0,
                  margin: 0,
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  lineHeight: "20px"
                }}
              />
            </div>
          ) : null}
          {this.props.analise.next_stage_pending === "n/a" ? (
            <ProgressBar
              style={{ height: 20, width: 85, borderRadius: 50 }}
              variant="bg-success"
              animated
              now={100}
            />
          ) : null}
        </div>

        <div className="img w-100 overflow-hidden">
          <img width="100%" src={this.props.analise.logo} />
        </div>

        <div className="p-3">
          <Title>{this.props.analise.name}</Title>
          <SubTitle>{this.props.analise.operation_name}</SubTitle>
          <Date>
            {textoStatus}
            {this.props.analise.data_pipeline_done
              ? ": " +
                moment(this.props.analise.last_stage_time).format(
                  "DD [de] MMMM [de] YYYY, HH:MM"
                )
              : ""}
          </Date>
        </div>
      </Container>
    );
  }
}

Card.contextType = Servicos;

import styled from "styled-components";

export const OperationList = styled.div`
  display: flex;
`;
export const Button = styled.div``;
export const Logo = styled.img`
  border-radius:50px;
  max-width:100px;
  max-height:100px;
  width:100px;
  height:100px;
`;

import React, { Component } from "react";

import { Container, Logo } from "./styles";

import api from '../../services/api'

import Algar from "../../assets/imgs/AlgarTech.svg";
import Spinner from '../../assets/svgs/loading.svg'
import history from "../../routes/history";

class Main extends Component {
    componentWillMount(){
        var search = decodeURIComponent(
        window.location.href.slice(window.location.href.indexOf("=") + 1)
        );
        api
          .get("/oauth/login/?code=" + search)
          .then(function(response) {
              localStorage.setItem("info", response.data.data.hash);
              localStorage.setItem("data", response.data.data.name);
              localStorage.setItem("user", response.data.data.user);
              localStorage.setItem("user_avatar", response.data.data.user_avatar)
              this.context.acoes.setAccess("Operação")
              history.push("/home")
          })
          .catch(err => window.location.href =  "/");
    }
 
  render() {
    return (
      <Container>
        <div className="col-12 col-md-3 d-flex align-items-center justify-content-center flex-wrap">
            <Logo src={Algar} alt="Algar" height="50" className="mb-5" />
            <Logo src={Spinner} alt="Algar" height="50" className="mb-5" />
        </div>
      </Container>
    );
  }
}

export default Main;

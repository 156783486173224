import React, { Component } from "react";
import "./xls.css";
import { Redirect } from "react-router-dom";
import Dropzone from "./dropzone/dropzone";
import Modal from "react-bootstrap/Modal";

import Progress from "./progress/Progress";
import AWS from "aws-sdk";

import api from "../../../../../services/api";
// import Spinner from "../../../../utils/spinner/spinner";
// import ReactS3 from "react-s3";
import Signature from "../../../../../services/lib/Signature";
import Policy from "../../../../../services/lib/Policy";
import {
  dateISOString,
  xAmzDate,
  dateYMD
} from "../../../../../services/lib/Date";
import { throwError } from "../../../../../services/lib/ErrorThrower";
import history from "../../../../../routes/history";
import Servicos from "../../../../../context";

class Xls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      redirect: false,
      loading: false,
      modalProcesso: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState({ files: files });
  }

  async uploadFiles() {
    if (localStorage.getItem("analysis_id")) {
      this.setState({ uploadProgress: {}, uploading: true });
      const promises = [];
      this.state.files.forEach(file => {
        promises.push(this.sendRequest(file));
      });
      try {
        await Promise.all(promises);

        this.setState({ successfullUploaded: true, uploading: false });
      } catch (e) {
        // Not Production ready! Do some error handling here instead...
        this.setState({ successfullUploaded: true, uploading: false });
      }
    }
  }

  sendRequest(file) {
    return new Promise(async (resolve, reject) => {
      //   {
      //     "bucketName": "analytics-import-file",
      //     "region": "us-east-1",
      //     "KeyId": "AKIAI522KNOEVKPCR55Q",
      //     "secretKey": "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"
      // }
      this.context.acoes.bloquearMenu();

      var config = {
        dirName: localStorage.getItem("analysis_id") /* optional */
      };
      try {
        var signed = await api.get(
          "/analysis/" +
            localStorage.getItem("analysis_id") +
            "/posturl?filename=" +
            file.name
        );
        this.context.acoes.setAccess(signed.data.requester_profile);
      } catch (error) {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.desbloquearMenu();
        this.context.acoes.setAccess(error.response.data.requester_profile);
      }

      //   console.log("signed", signed.data.signedurl)
      //   alert("oi")
      //   // var credentials = await api.get("/credentials")
      //   // AWS.config.credentials = new AWS.Credentials(
      //   //   credentials.data.data.KeyId,
      //   //   credentials.data.data.secretKey,
      //   //   credentials.data.data.SessionToken
      //   // );
      //   // AWS.config.update({
      //   //   region: credentials.data.data.region
      //   // });
      //   var s3 = new AWS.S3();
      //   // var params = {
      //   //   Body: file,
      //   //   Bucket: credentials.data.data.bucketName,
      //   //   Key: `${localStorage.getItem("analysis_id") + "/" + file.name}`,
      //   //   ContentType: file.type,
      //   //   // ServerSideEncryption: "AES256"
      //   // };
      //   var params = {
      //     Body: file,
      //     Bucket: credentials.data.data.bucketName,
      //     Key: `${localStorage.getItem("analysis_id") + "/" + file.name}`,
      //     ContentType: file.type,
      //     // ServerSideEncryption: "AES256"
      //   };
      // //   s3.getSignedUrl('putObject', {
      // //     Bucket: 'myBucket',
      // //     // Expires: 60*60,
      // //     Key: 'myKey'
      // // }, function (err, url) {
      // //     console.log(url);
      // // });
      //   var location = `https://${credentials.data.data.bucketName}.s3.${credentials.data.data.region}.amazonaws.com/${localStorage.getItem(
      //     "analysis_id"
      //   )}/${file.name}`;
      //   var s3Upload = s3
      //     .putObject(params)
      //     .on("httpUploadProgress", progress => {
      //       const copy = { ...this.state.uploadProgress };
      //       copy[file.name] = {
      //         state: "pending",
      //         percentage: (progress.loaded / progress.total) * 100
      //       };
      //       this.setState({ uploadProgress: copy });
      //     })
      //     .send((err, data) => {
      //       if (err) {
      //         const copy = { ...this.state.uploadProgress };
      //         copy[file.name] = { state: "error", percentage: 0 };
      //         this.setState({ uploadProgress: copy });
      //       }
      //       // console.log("FINESHED", data)
      //       const copy = { ...this.state.uploadProgress };
      //       copy[file.name] = { state: "done", percentage: 100 };
      //       this.setState({ uploadProgress: copy });
      //       let payload = { caminhoArquivo: location };
      //       this.context.acoes.desbloquearMenu();
      //       this.postCSV(payload);
      //     });
      //   console.log(s3Upload);

      // Keys returned from STS
      // config.bucketName = "analytics-import-file";
      // config.region = "us-east-1";
      // config.accessKeyId = "ASIA5G5XXSHTLVHY4IEQ";
      // config.secretAccessKey = "j16t4xqIiLPz7rtwQK1O0rQvN8covheqOIC77p5m";
      // config.SessionToken = "FQoGZXIvYXdzEIL//////////wEaDGiH4jJQyQHOwvYe1yLzARlXnPB8Ptx3c4cQYnxpgJwlTzbqWY7GUBojQdGmVONiK+k0x2Osj8nz2XnLFjerzN3KNFXkk1TwsOr3LaSeh22NFRmF2Co19emtjwYAHhOQ++9NGUrmr/h823SRqXuuoLDgt3vv0M6oTJ0pYbnXtX/PnzyvVMwdTJHiyjpGUN613GTG3A0FpqhyRpb+Vf9Ja34QrhdiyuUx1sVZGVts1cT2+nVk6ala7MkAJVx1xme9OlS+6oU/+2HbxaP07soChMT0b12sae34Ioc+h5COJm9+9GHo9y5JhDfovGhswVccs0qYZw1hZaamEbKyt6VpXltULiieuYvnBQ=="
      const req = new XMLHttpRequest();
      throwError(config, file);
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        let url = signed.data.signedurl;
        let url_decoded = decodeURI(url);
        const fileName = decodeURI(file.name);
        let location = url_decoded.substr(0, url_decoded.indexOf(fileName) + fileName.length);
        // resolve(location);
        let payload = { caminhoArquivo: location };
        this.postCSV(payload);
        // this.setState({ loading: true });
        // this.context.acoes.showLoading();
        // if (localStorage.getItem("analysis_id")) {
        //   api
        //     .post(
        //       "/analysis/" + localStorage.getItem("analysis_id") + "/csv",
        //       payload
        //     )
        //     .then(response => {
        //       this.context.acoes.hideLoading();
        //       let relation = response.data.data.primeirasLinhas;
        //       this.setState({ loading: false });
        //       localStorage.setItem("info", response.data.requester_hash);
        //       this.context.acoes.liberar();
        //     })
        //     .catch(error => {
        //       this.context.acoes.hideLoading();
        //       this.context.acoes.showError();
        //       this.context.acoes.setMessage(error.response.data.message);
        //       this.context.acoes.setErrorStatus(error.response.status);
        //       if (error.response.status != 500) {
        //         localStorage.setItem(
        //           "info",
        //           error.response.data.requester_hash
        //         );
        //       }
        //     });
        // } else {
        // let error = "Não existe uma análise atribuida a esse arquivo";
        // this.context.acoes.hideLoading();
        // this.context.acoes.showError();
        // this.context.acoes.setMessage(error);
        // this.context.acoes.setErrorStatus(400);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("Content-Type", file.type);
      req.open("PUT", signed.data.signedurl);
      req.setRequestHeader("Content-Type", file.type);

      req.send(file);
    });

    // req.upload.addEventListener("progress", event => {
    //   if (event.lengthComputable) {
    // const copy = { ...this.state.uploadProgress };
    // copy[file.name] = {
    //   state: "pending",
    //   percentage: (event.loaded / event.total) * 100
    // };
    // this.setState({ uploadProgress: copy });
    //   }
    // });

    // req.upload.addEventListener("load", event => {
    //   const copy = { ...this.state.uploadProgress };
    //   copy[file.name] = { state: "done", percentage: 100 };
    //   this.setState({ uploadProgress: copy });
    //   // let location = `${url}${config.dirName ? config.dirName + "/" : ""}${
    //   //   file.name
    //   // }`;
    //   // resolve(location);
    //   let location

    //   this.context.acoes.desbloquearMenu();

    //   let payload = { caminhoArquivo: location };
    //   this.setState({ loading: true });
    //   this.context.acoes.showLoading();

    //   } else {
    //     let error = "Não existe uma análise atribuida a esse arquivo";
    //     this.context.acoes.hideLoading();
    //     this.context.acoes.showError();
    //     this.context.acoes.setMessage(error);
    //     this.context.acoes.setErrorStatus(400);
    //   }
    // });
    // });
  }
  postCSV = payload => {
    if (localStorage.getItem("analysis_id")) {
      this.context.acoes.showLoading();
      api
        .post(
          "/analysis/" + localStorage.getItem("analysis_id") + "/csv",
          payload
        )
        .then(response => {
          this.context.acoes.desbloquearMenu();

          this.context.acoes.hideLoading();
          // let relation = response.data.data.primeirasLinhas;
          this.setState({ loading: false });

          this.context.acoes.liberar();
          this.context.acoes.setAccess(response.data.requester_profile);

          this.openCloseProcesso();
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.desbloquearMenu();
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    }
  };
  modalProcessoClose = () => {
    history.push("/home");
    this.setState({ modalProcesso: false });
  };
  openCloseProcesso = () => {
    this.setState({ modalProcesso: true });
  };

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="./a.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    // if (this.state.successfullUploaded) {
    //   return (
    //     <button
    //       disabled
    //       onClick={() =>
    //         this.setState({ files: [], successfullUploaded: false })
    //       }
    //     >
    //       finalizado
    //     </button>
    //   );
    // } else {
    return (
      <button
        // disabled={this.state.files.length !== 0 || !this.state.uploading || localStorage.getItem("analysis_id")? true : false}
        disabled={
          this.state.files.length !== 0 &&
          localStorage.getItem("analysis_id") !== "null" &&
          localStorage.getItem("analysis_id") &&
          localStorage.getItem("analysis_id").length === 32 &&
          !(this.state.uploading || this.state.successfullUploaded)
            ? false
            : true
        }
        onClick={this.uploadFiles}
      >
        Enviar Arquivo
      </button>
    );
    // }
  }

  render() {
    return (
      <div className="Upload pb-1">
        <Modal
          {...this.props}
          dialogClassName="modal-80w excluir"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          analise={this.state.selecionado}
          show={this.state.modalProcesso}
          onHide={e => this.modalProcessoClose()}
        >
          {/* <Modal.Header closeButton /> */}
          <Modal.Body
            className="position-relative d-flex justify-content-center"
            style={{ paddingTop: "10%" }}
          >
            <div>
              <h4 style={{ fontSize: 14 }}>
                Esta análise está em processamento. Por favor, aguarde alguns
                minutos.
              </h4>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
            <button
              className="btn-success mb-3"
              style={{ width: 200, height: 50, fontSize: 14 }}
              onClick={e => history.push("/home")}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default Xls;
Xls.contextType = Servicos;

import React, { Component } from "react";
import api from "../../../services/api";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import AlgarTech from "../../../assets/imgs/AlgarTech.svg";

import "react-toastify/dist/ReactToastify.css";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Button,
  Form,
  List,
  Charts,
  Listagem,
  Logo
} from "./styles";
import Card from "../../../components/Card";
import ChartsComponent from "../../../components/Charts";
import { isAssignmentExpression } from "@babel/types";
import Servicos from "../../../context";
import history from "../../../routes/history";
import AlgarLogo from "../../../assets/imgs/logo-gray.svg";
import Algar from "../../../assets/imgs/logo-icon.svg";

const Msg = () => (
  <div>
    <img src={Algar} style={{ height: 25 }} />
    <small className="ml-1">Lista de analises irá se atualizar!</small>
  </div>
);
var notNow = false;
var listUP = [];
var timeReload = null;
export default class History extends Component {
  state = {
    full: false,
    analyses: [],
    selecionado: 0,
    show: false,
    showGraphs: false,
    processando: false,
    modalShow: false,
    favorito: null,
    msgStatus: ""
  };

  analyses = () => {
    this.context.acoes.showLoading();
    api
      .get("/analysis")
      .then(response => {
        this.setState({ analyses: response.data.data.Items });
        // this.setState({ selecionado: response.data.data.Items[0] });
        // this.setState({ loading: true });
        if (this.state.selecionado != 0) {
          let analise = response.data.data.Items.filter(
            valor => valor.analysis_id == this.state.selecionado.analysis_id
          );

          this.setState({ favorito: analise[0].favorite });
        }
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);
        this.setState({ ...this.state, show: true });
        // toast.dismiss();
        // this.setState({ processando: false });
        // notNow = false;
      })
      .catch(error => {
        if (error.response.status !== 404) {
          
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
        }
        this.context.acoes.setAccess(error.response.data.requester_profile);
        this.context.acoes.hideLoading();
      });
    // this.context.acoes.hideLoading();
  };

  componentDidMount() {
    // this.context.acoes.showLoading();
    this.analyses();
    localStorage.setItem("analysis_id", null);
  }
  selececao = analise => {
    if(analise.status == "deleted"){
      this.setState({
        msgStatus:
          "Esta análise está excluída!"
      });
      setTimeout(() => {
        this.openClose();
      }, 50);
    }
    else{



      this.setState({ selecionado: analise, showGraphs: false });

      localStorage.setItem("analysis_id", analise.analysis_id);
      switch (analise.next_stage_pending) {
        case "column-relations":
          history.push("home/columnrelation");
          break;
        case "topics":
          history.push("home/topicos");
          break;
        case "stopwords":
          history.push("home/stopwords");
          break;
        case "base-import":
          history.push("home/imports");
          break;
        case "failed":
          if (analise.data_pipeline_stage !== "graphs") {
            if (analise.data_pipeline_stage === "topics")
              history.push("home/topicos");
            if (analise.data_pipeline_stage === "import")
              history.push("/home/imports");
            else history.push("home/" + analise.data_pipeline_stage);
          }
          this.setState({
            msgStatus:
              "Esta análise está com problemas. Por favor, contate o suporte técnico"
          });
          setTimeout(() => {
            this.openClose();
          }, 50);

          break;
        case "graphs":
          setTimeout(() => {
            this.setState({ showGraphs: true });
          }, 100);

          break;
        case "n/a":
          this.setState({
            msgStatus:
              "Esta análise está em processamento. Por favor, aguarde alguns minutos"
          });
          setTimeout(() => {
            this.openClose();
          }, 50);
          break;
        default:
          history.push("home");
          break;
      }
    }
  };
  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.processando !== this.state.processando) {
  //     if (this.state.processando == true) {
  //       this.setState({ processando: false });
  //       if (this.state.reCall === true) {
  //         setTimeout(() => {
  //           this.setState({reCall: false})
  //           this.reCall();
  //         }, 200000);
  //       }
  //     } else {
  //     }
  //     // this.reCall()
  //   } else {
  //     // this.setState({ processando: false });
  //   }
  // }
  componentWillUnmount() {
    notNow = false;
    timeReload = null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.processando !== nextState.processando) {
      if (nextState.processando === true) {
        setTimeout(() => {
          this.reCall();
          return true;
        }, timeReload);
      }
      // }, 150);
    } else {
      if (this.state.showGraphs !== nextState.showGraphs) {
        return true;
      } else if (this.state.full !== nextState.full) {
        return true;
      } else if (this.state.selecionado !== nextState.selecionado) {
        return true;
      } else if (this.state.modalShow != nextState.modalShow) {
        return true;
      }

      return false;
    }

    return true;
  }
  reCall = () => {
    // this.context.acoes.showLoading();
    api
      .get("/analysis")
      .then(response => {
        toast.dismiss();

        // this.setState({ loading: true });
        this.setState({ analyses: response.data.data.Items });
        // this.setState({
        //   selecionado: response.data.data.Items[0]
        // });

        this.setState({ processando: false });
        notNow = false;
        this.context.acoes.setAccess(response.data.requester_profile);
        let analise = response.data.data.Items.filter(
          valor => valor.analysis_id == this.state.selecionado.analysis_id
        );

        // this.context.acoes.hideLoading();

        // this.context.acoes.hideLoading();
        // this.setState({ ...this.state, show: true });
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);
      });
    // this.context.acoes.hideLoading();
  };
  avancarStopWords = () => {
    localStorage.setItem("analysis_id", this.state.selecionado.analysis_id);
    history.push("home/stopwords");
  };
  refresh = () => {
    this.analyses();
  };
  modalClose = () => {
    this.setState({ ...this.state, modalShow: false });
    // this.forceUpdate();
  };
  openClose = () => {
    this.setState({ ...this.state, modalShow: true });
    // this.forceUpdate();
  };
  create = () => {
    localStorage.setItem("analysis_id", null);
    history.push("/home/imports");
  };

  updateCards = (stage, status, user, id) => {
    var update = this.state.processando;
    if (update === false && user === localStorage.getItem("data")) {
      listUP.push(id);
      if (notNow === false) {
        if (stage === "classification") {
          if (
            status === "RUNNING" ||
            status === "WAITING ON QUEUE" ||
            status === "STARTING" ||
            status === "SUCCEEDED"
          ) {
            notNow = true;
            this.setState({ processando: true });
            // toast.info("Lista de análises irá se atualizar!", {
            //   position: "top-center",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   draggable: true
            // });
            timeReload = 30000;
          }
        }
        if (stage === "stopwords") {
          if (
            status === "SUBMITTED" ||
            status === "PENDING" ||
            status === "RUNNABLE" ||
            status === "RUNNING" ||
            status === "STARTING" ||
            status === "criando base de tópicos"
          ) {
            notNow = true;
            this.setState({ processando: true });
            // toast.info("Lista de análises irá se atualizar!", {
            //   position: "top-center",
            //   autoClose: 78000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   draggable: true
            // });
            timeReload = 78000;
          }
        }
        if (stage === "import") {
          if (
            status === "SUBMITTED" ||
            status === "PENDING" ||
            status === "RUNNABLE" ||
            status === "RUNNING" ||
            status === "STARTING"
          ) {
            notNow = true;
            this.setState({ processando: true });
            // toast.info("Lista de análises irá se atualizar!", {
            //   position: "top-center",
            //   autoClose: 78000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: false,
            //   draggable: true
            // });
            timeReload = 40000;
          }
        }
      }
    }
  };
  render() {
    let displayCharts = this.state.showGraphs;
    return (
      <>
        <div className="d-flex flex-wrap align-items-center h-100">
          <ToastContainer />
          <Modal
            {...this.props}
            dialogClassName="modal-80w excluir"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            analise={this.state.selecionado}
            show={this.state.modalShow}
            onHide={e => this.modalClose()}
          >
            {/* <Modal.Header closeButton /> */}
            <Modal.Body
              className="position-relative d-flex justify-content-center"
              style={{ paddingTop: "10%" }}
            >
              <div>
                <h4 style={{ "text-align": "center", fontSize: 14 }}>
                  {this.state.msgStatus}
                </h4>
              </div>
            </Modal.Body>

            <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
              <button
                className="btn mb-3"
                style={{ width: 200, height: 50, fontSize: 14 }}
                onClick={e => this.modalClose()}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          <List
            className={this.state.full ? "d-none" : "h-100 border-right scroll"}
            style={{ overflowX: "hidden" }}
          >
            <Container className="">
              <Header
                className="d-flex flex-wrap align-items-center w-100"
                style={{ height: "20px" }}
              >
                <div className="col-12 pl-4">
                  <Title>
                    Análise{this.state.analyses.length > 1 ? "s" : ""}
                  </Title>
                </div>
                <div
                  className="d-flex flex-wrap w-100"
                  style={{ marginLeft: "2%" }}
                >
                  <Subtitle className="col-7">Histórico</Subtitle>
                  {this.context.acoes.access === "Operação" ? (
                    <div className="mr-0 col-5 text-success d-flex align-items-center" />
                  ) : (
                    <div
                      style={{
                        cursor: "pointer"
                        // marginTop: "10%"
                      }}
                      className="mr-0 col-5 text-success d-flex align-items-center"
                      onClick={this.create}
                    >
                      Criar Análise{" "}
                      <span
                        className="icon-plus ml-2 font-weight-bold"
                        role="button"
                      />
                    </div>
                  )}
                </div>
                <div className="d-flex flex-wrap " />
              </Header>
            </Container>
            <section className="">
              <div className="d-flex flex-wrap w-100">
                <Listagem className="col-12 " style={{ marginTop: "-3%" }}>
                  {this.state.analyses.map(
                    (analise, index) => (
                      this.updateCards(
                        analise.data_pipeline_stage,
                        analise.data_pipeline_status,
                        analise.user_id
                      ),
                      (
                        <Card
                          analise={analise}
                          style={{ zIndex: 219 }}
                          key={index}
                          selecionado={this.selececao}
                          update={this.analyses}
                        />
                      )
                    )
                  )}
                </Listagem>
              </div>
            </section>
          </List>
          <Charts className={this.state.full ? "w-100" : ""}>
            {displayCharts ? (
              <ChartsComponent
                analise={this.state.selecionado}
                fullScreen={e => this.setState({ full: !this.state.full })}
                favorito={this.state.favorito}
                refresh={this.analyses}
              />
            ) : (
              <div
                className="d-flex flex-wrap align-items-center w-100"
                style={{
                  flexDirection: "column",
                  marginTop: "25%",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <div>
                  <img src={Algar} alt="Algar" height={65} className="mb-5" />
                </div>
                <div>
                  <h4>Selecione uma análise para iniciar</h4>
                </div>
              </div>
            )}
          </Charts>
        </div>
      </>
    );
  }
}
History.contextType = Servicos;

import React, { Component, useState } from "react";
import Select from "react-select";
import Servicos from "../../context";
import Form from "react-bootstrap/Form";
import { KeyboardDatePicker } from "@material-ui/pickers";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 12
  })
};

export default class Filter extends Component {
  state = {
    analise_id: "",
    selectedservice_users: null,
    selectedrequester_users: null,
    selectedtopics: null,
    selectedticket_status: null,
    selectedautomatizaveis: null,
    selectedsla: null,
    openfrom: null,
    opento: null,
    numerodoticket: null,
    topics: [],
    ticket_status: [],
    automatizaveis: [],
    requester_users: [],
    service_users: [],
    sla_violados: []
  };
  topics = selectedtopics => {
    this.setState({ selectedtopics });
  };
  ticket_status = selectedticket_status => {
    this.setState({ selectedticket_status });
  };
  sla = selectedsla => {
    this.setState({ selectedsla });
  };
  automatizaveis = selectedautomatizaveis => {
    this.setState({ selectedautomatizaveis });
  };
  requester_users = selectedrequester_users => {
    this.setState({ selectedrequester_users });
  };
  service_users = selectedservice_users => {
    this.setState({ selectedservice_users });
  };
  getInicio = openfrom => {
    this.setState({ openfrom });
  };
  getFim = opento => {
    this.setState({ opento });
  };
  getNumeroTicket = numerodoticket => {
    this.setState({ numerodoticket });
  };
  number_ticket = numerodoticket => {
    if (numerodoticket.length === 0) {
      this.setState({ numerodoticket: null });
    } else {
      this.setState({ numerodoticket });
    }
  };

  componentDidUpdate(prevProps) {
    
    if (this.props.filtro !== prevProps.filtro) {
      this.setState({
        topics: this.props.filtro[0],
        automatizaveis: this.props.filtro[4],
        requester_users: this.props.filtro[2],
        service_users: this.props.filtro[1],
        sla_violados: this.props.filtro[3],
        ticket_status: this.props.filtro[5],
        numerodoticket: this.props.filtro[6]
      });
      return true;
    }

    return true;
  }
  
  filtrar = () => {
    this.context.acoes.showLoading();
    var {
      selectedservice_users,
      selectedrequester_users,
      selectedtopics,
      selectedticket_status,
      selectedautomatizaveis,
      selectedsla,
      openfrom,
      opento,
      numerodoticket
    } = this.state;
    var filtro = {
      selectedservice_users: "",
      selectedrequester_users: "",
      selectedtopics: "",
      selectedticket_status: "",
      selectedautomatizaveis: "",
      selectedsla: "",
      openfrom: "",
      opento: "",
      numerodoticket: ""
    };
    if (
      this.state.selectedautomatizaveis ||
      this.state.selectedautomatizaveis === null
    ) {
      if (selectedautomatizaveis === null) {
        filtro.selectedautomatizaveis = selectedautomatizaveis;
      } else {
        let lista = [];
        for (let x in selectedautomatizaveis) {
          lista.push(selectedautomatizaveis[x].value);
        }
        filtro.selectedautomatizaveis = lista;
      }
    }
    if (this.state.selectedtopics || this.state.selectedtopics === null) {
      if (selectedtopics === null) {
        filtro.selectedtopics = selectedtopics;
      } else {
        let lista = [];
        for (let x in selectedtopics) {
          lista.push(selectedtopics[x].value);
        }
        filtro.selectedtopics = lista;
      }
    }

    if (
      this.state.selectedticket_status ||
      this.state.selectedticket_status === null
    ) {
      if (selectedticket_status === null) {
        filtro.selectedticket_status = selectedticket_status;
      } else {
        let lista = [];
        for (let x in selectedticket_status) {
          lista.push(selectedticket_status[x].value);
        }
        filtro.selectedticket_status = lista;
      }
    }

    if (
      this.state.selectedservice_users ||
      this.state.selectedservice_users === null
    ) {
      if (selectedservice_users === null) {
        filtro.selectedservice_users = selectedservice_users;
      } else {
        let lista = [];
        for (let x in selectedservice_users) {
          lista.push(selectedservice_users[x].value);
        }
        filtro.selectedservice_users = lista;
      }
    }
    if (
      this.state.selectedrequester_users ||
      this.state.selectedrequester_users === null
    ) {
      if (selectedrequester_users === null) {
        filtro.selectedrequester_users = selectedrequester_users;
      } else {
        let lista = [];
        for (let x in selectedrequester_users) {
          lista.push(selectedrequester_users[x].value);
        }
        filtro.selectedrequester_users = lista;
      }
    }
    if (this.state.selectedsla || this.state.selectedsla === null) {
      if (selectedsla === null) {
        filtro.selectedsla = selectedsla;
      } else {
        let lista = [];
        for (let x in selectedsla) {
          lista.push(selectedsla[x].value);
        }
        filtro.selectedsla = lista;
      }
    }
    if (this.state.numerodoticket || this.state.numerodoticket === null) {
      filtro.numerodoticket = numerodoticket;
    }
    if (this.state.openfrom || this.state.openfrom === null) {
      filtro.openfrom = openfrom;
    }
    if (this.state.opento || this.state.openfrom === null) {
      filtro.opento = this.state.opento;
    }
    setTimeout(() => {
      this.props.filtrando(filtro);
    }, 100);
  };

  render() {
    const {
      selectedservice_users,
      selectedrequester_users,
      selectedtopics,
      selectedticket_status,
      selectedautomatizaveis,
      selectedsla,
      topics,
      ticket_status,
      openfrom,
      numerodoticket,
      opento,
      automatizaveis,
      requester_users,
      service_users,
      sla_violados
    } = this.state;
    return (
      <div className="d-flex flex-wrap justify-content-between pt-5 pb-4 pl-1 pr-1">
        <div className="col-4 mb-1">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Data Abertura
          </small>
          <div className="d-flex">
            <KeyboardDatePicker
              clearable
              cancelLabel="Cancelar"
              clearLabel="Limpar"
              format="dd/MM/yyyy"
              value={this.state.openfrom}
              invalidDateMessage="Formato de data inválido"
              onChange={date => this.getInicio(date)}
            />
            <KeyboardDatePicker
              clearable
              cancelLabel="Cancelar"
              clearLabel="Limpar"
              format="dd/MM/yyyy"
              value={this.state.opento}
              invalidDateMessage="Formato de data inválido"
              emptyLabel=""
              onChange={date => this.getFim(date)}
            />
          </div>
        </div>
        <div className="col-4 mb-1">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Usuário Solicitante
          </small>
          <Select
            styles={customStyles}
            value={selectedrequester_users}
            onChange={this.requester_users}
            placeholder="Selecionar"
            options={requester_users}
            isMulti
          />
        </div>
        <div className="col-4">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Tópico
          </small>
          <Select
            styles={customStyles}
            value={selectedtopics}
            onChange={this.topics}
            options={topics}
            placeholder="Selecionar"
            isMulti
          />
        </div>
        <div className="col-6">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Status
          </small>
          <Select
            styles={customStyles}
            value={selectedticket_status}
            onChange={this.ticket_status}
            placeholder="Selecionar"
            options={ticket_status}
            isMulti
          />
        </div>
        <div className="col-6">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Número do Ticket
          </small>
          <Form.Control
            styles={customStyles}
            onChange={e => this.number_ticket(e.target.value)}
            defaultValue={this.state.numerodoticket}
          />
        </div>
        <div className="col-4 mb-1" />
        
        <div className="d-flex justify-content-end col-12 mt-3">
          <button
            className={"w-25 btn-success"}
            style={{
              borderRadius: 5,
              float: "none",
              marginRight: 0,
              marginTop: 0,
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
            onClick={this.filtrar}
          >
            Filtrar
          </button>
        </div>
      </div>
    );
  }
}
Filter.contextType = Servicos;

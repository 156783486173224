import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import history from "../../routes/history";

import {
  Nav,
  NavMobile,
  HeaderNav,
  BodyNav,
  FooterNav,
  Logo,
  Person,
  Title,
  ProfilePic
} from "./styles";
import Algar from "../../assets/imgs/logo-icon.svg";
import Menu from "../../assets/imgs/icons/menu.svg";
import User from "../../assets/imgs/user.png";
import AlgarLogo from "../../assets/imgs/logo-gray.svg";
import AlgarTech from "../../assets/imgs/AlgarTech.svg";
import { Link } from "react-router-dom";
import api from "../../services/api";
import Servicos from "../../context";
import Modal from "react-bootstrap/Modal";

class Sidenav extends Component {
  state = {
    user: "",
    modalShow: false
  };
  modalClose = () => {
    this.setState({ modalShow: false });
  };
  openClose = () => this.setState({ ...this.state, modalShow: true });
  handleNav = hamburgerMenu => {
    if (!this.context.acoes.menuBloqueado) {
      this.props.handleMenu(hamburgerMenu);
    } else {
      this.openClose();
    }
  };
  redirectTo = path => {
    if (!this.context.acoes.menuBloqueado) {
      localStorage.setItem("analysis_id", null);
      history.push(path);
    } else {
      this.openClose();
    }
  };

  exit = () => {
    if (!this.context.acoes.menuBloqueado) {
      localStorage.removeItem("info");
      localStorage.removeItem("data");
      localStorage.removeItem("profile");
      history.push("/");
    } else {
      this.openClose();
    }
  };
  componentDidMount() {
    // this.context.acoes.profileCall();
  }
  render() {
    const { user } = this.state;
    return (
      <>
        <Nav
          className={this.props.menu ? "w-20 position-fixed h-100" : "h-100"}
          // style={{width: this.props.menu ? "20%": ""}}
        >
          <Modal
            {...this.props}
            dialogClassName="modal-80w excluir"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            analise={this.state.selecionado}
            show={this.state.modalShow}
            onHide={e => this.modalClose()}
          >
            {/* <Modal.Header closeButton /> */}
            <Modal.Body
              className="position-relative d-flex justify-content-center"
              style={{ paddingTop: "10%" }}
            >
              <div>
                <h4>Por favor aguarde o final do processamento.</h4>
              </div>
            </Modal.Body>

            <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
              <button
                className="btn mb-3"
                style={{ width: 200, height: 50, fontSize: 14 }}
                onClick={e => this.modalClose()}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          <HeaderNav className="bg-bluelight">
            <Logo
              src={Menu}
              onClick={e => this.handleNav(true)}
              alt="Algar"
              height="20vh"
              width="20vh"
              className={this.props.menu ? "d-none" : ""}
              style={{ objectFit: "cover", cursor: "pointer" }}
            />
            <div
              // className="arrow-menu"
              className={
                this.props.menu ? "arrow-menu position-absolute" : "d-none"
              }
              onClick={e => this.handleNav(true)}
              style={{ cursor: "pointer" }}
            >
              <span className="icon-Grupo-68 m-2" />
            </div>

            <Person
              className={
                this.props.menu ? "d-flex w-100 pt-4 flex-wrap" : "d-none"
              }
            >
              <div className="d-flex justify-content-between m-0 p-2 align-items-center w-100">
                <ProfilePic
                  onClick={e => {
                    this.redirectTo("/home/users?user=" + localStorage.getItem("data"))
                    this.handleNav(true);
                  }}
                  src={localStorage.getItem("user_avatar")}
                  alt="Algar"
                  height="50"
                  className="mr-4"
                  style={{ cursor: "pointer" }}
                />
                <div className="pr-1 ml-0">
                  <Title className="text-white h5">Portal Analytics</Title>
                  <small className="text-white">INTUITIVE ALGAR TECH</small>
                </div>
              </div>
              <div
                className="d-flex justify-content-between"
                onClick={e => {
                  this.redirectTo("/home/users?user=" + localStorage.getItem("data"))
                  this.handleNav(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <small
                  className="text-white h6"
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {localStorage.getItem("data")}
                </small>
              </div>
            </Person>
          </HeaderNav>
          <BodyNav>
            <ul>
              <li>
                <Link
                  onClick={e => {
                    this.redirectTo("/home");
                  }}
                  className={
                    this.props.location.pathname === "/home" ? "active" : ""
                  }
                >
                  {" "}
                  <Tooltip title="Análises" placement="right">
                    <span className="icon-history" />
                  </Tooltip>
                  {/* <span className="icon-history" /> */}
                  <h5 className={this.props.menu ? "d-block" : "d-none"}>
                    Análises
                  </h5>
                </Link>
              </li>
              <li>
                <Link
                  onClick={e => {
                    this.redirectTo("/home/users");
                  }}
                  className={
                    this.props.location.pathname === "/home/users"
                      ? "active"
                      : ""
                  }
                >
                  {/* <Logo src={Algar} alt="Algar" height="25" /> */}
                  <Tooltip title="Usuários" placement="right">
                    <span className="icon-team" />
                  </Tooltip>
                  <h5 className={this.props.menu ? "d-block" : "d-none"}>
                    Usuários
                  </h5>
                </Link>
              </li>
              {this.context.acoes.access === "Operação" ? null : (
                <li>
                  <Link
                    onClick={e => {
                      this.redirectTo("/home/operations");
                    }}
                    className={
                      this.props.location.pathname === "/home/operations" ||
                      this.props.location.pathname === "/home/operations/new"
                        ? "active"
                        : ""
                    }
                  >
                    {/* <Logo src={Algar} alt="Algar" height="25" /> */}
                    <Tooltip title="Operações" placement="right">
                      <span className="icon-Grupo-50" />
                    </Tooltip>

                    <h5 className={this.props.menu ? "d-block" : "d-none"}>
                      Operações
                    </h5>
                  </Link>
                </li>
              )}
              <li style={{ cursor: "pointer" }}>
                <a onClick={e => this.exit()}>
                  <Tooltip title="Sair" placement="right">
                    <span className="icon-logout" />
                  </Tooltip>

                  <h5
                    className={
                      this.props.menu ? "d-block text-white" : "d-none"
                    }
                  >
                    Sair
                  </h5>
                </a>
              </li>
            </ul>
          </BodyNav>
          <FooterNav>
            <Logo
              src={this.props.menu ? AlgarTech : Algar}
              alt="Algar"
              height={this.props.menu ? "45" : "35"}
              className="mb-5"
            />
          </FooterNav>
        </Nav>
        <NavMobile>
          <HeaderNav className="bg-bluelight">
            <Person className="d-flex justify-content-between align-items-center w-100">
              <ProfilePic
                src={localStorage.getItem("user_avatar")}
                alt="Algar"
                height="35"
                className="m-4"
              />
              <Logo
                src={this.props.menu ? AlgarLogo : Algar}
                alt="Algar"
                height="35"
                className="m-4"
              />
            </Person>
          </HeaderNav>
          <BodyNav>
            <ul>
              <li>
                <Link
                  onClick={e => {
                    this.redirectTo("/home");
                    this.handleNav();
                  }}
                  className={
                    this.props.location.pathname === "/home" ? "active" : ""
                  }
                >
                  <span className="icon-history" />
                </Link>
              </li>
              {this.context.acoes.profile.user_profile === "Operação" ? null : (
                <li>
                  <Link
                    onClick={e => {
                      this.redirectTo("/home/imports");
                      this.handleNav();
                    }}
                    className={
                      this.props.location.pathname === "/home/imports"
                        ? "active"
                        : ""
                    }
                  >
                    <span className="icon-upload" />
                  </Link>
                </li>
              )}
              <li>
                <Link
                  onClick={e => {
                    this.redirectTo("/home/users");
                    this.handleNav();
                  }}
                  className={
                    this.props.location.pathname === "/home/users"
                      ? "active"
                      : ""
                  }
                >
                  <span className="icon-team" />
                </Link>
              </li>
              {this.context.acoes.access === "Operação" ? null : (
                <li>
                  <Link
                    onClick={e => {
                      this.redirectTo("/home/operations");
                      this.handleNav();
                    }}
                    className={
                      this.props.location.pathname === "/home/operations"
                        ? "active"
                        : ""
                    }
                  >
                    <span className="icon-Grupo-50" />
                  </Link>
                </li>
              )}
              <li onClick={e => this.exit()}>
                <span className="icon-logout" />
              </li>
            </ul>
          </BodyNav>
        </NavMobile>
      </>
    );
  }
}

export default Sidenav;
Sidenav.contextType = Servicos;

import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Servicos from "../../context";
// import { Container } from './styles';

export default class Filter2 extends Component {
  state = {
    openfrom: null,
    opento: null,
    conf_from: null,
    conf_to: null,
    selectedOption: null
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };
  getInicio = openfrom => {
    this.setState({ openfrom });
  };
  getFim = opento => {
    this.setState({ opento });
  };
  getConfInicio = conf_from => {
    if (conf_from.length === 0) this.setState({ conf_from: null });
    else this.setState({ conf_from });
  };
  getConfFim = conf_to => {
    if (conf_to.length === 0) this.setState({ conf_to: null });
    else this.setState({ conf_to });
  };

  filtrar = () => {
    this.context.acoes.showLoading();
    var { conf_from, conf_to, openfrom, opento } = this.state;
    var filtro = {
      conf_from: "",
      conf_to: "",
      openfrom: "",
      opento: ""
    };

    if (this.state.conf_from || this.state.conf_from === null) {
      filtro.conf_from = conf_from;
    }
    if (this.state.conf_to || this.state.conf_to === null) {
      filtro.conf_to = this.state.conf_to;
    }
    if (this.state.openfrom || this.state.openfrom === null) {
      filtro.openfrom = openfrom;
    }
    if (this.state.opento || this.state.opento === null) {
      filtro.opento = this.state.opento;
    }
    setTimeout(() => {
      this.props.filtrando(filtro);
    }, 100);
  };
  render() {
    const { selectedOption } = this.state;
    return (
      <div className="d-flex flex-wrap justify-content-between pt-5 pb-4 pl-1 pr-1">
        <div className="col-6">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            Data Abertura
          </small>
          <div className="d-flex">
          <KeyboardDatePicker
              clearable
              cancelLabel="Cancelar"
              clearLabel="Limpar"
              format="dd/MM/yyyy"
              value={this.state.openfrom}
              invalidDateMessage='Formato de data inválido'
              // InputAdornmentProps={{ position: "start" }}
              onChange={date => this.getInicio(date)}
            />
            <KeyboardDatePicker
              clearable
              cancelLabel="Cancelar"
              clearLabel="Limpar"
              format="dd/MM/yyyy"
              value={this.state.opento}
              invalidDateMessage='Formato de data inválido'
              emptyLabel=""
              // InputAdornmentProps={{ position: "start" }}
              onChange={date => this.getFim(date)}
            />
          </div>
        </div>
        <div className="col-6">
          <small
            style={{ fontSize: 14 }}
            className="color-bluedark font-weight-normal w-100 d-block"
          >
            % Confiança
          </small>
          <div className="d-flex">
            <Form.Control
              id="conffrom"
              onChange={e => this.getConfInicio(e.target.value)}
              type="number"
              className="col-12 col-md-6"
            />
            <Form.Control
              id="confto"
              onChange={e => this.getConfFim(e.target.value)}
              type="number"
              className="col-12 col-md-6"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end col-12 mt-3">
          <button
            className={"w-25 btn-success"}
            style={{
              borderRadius: 5,
              float: "none",
              marginRight: 0,
              marginTop: 0,
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 0
            }}
            onClick={this.filtrar}
          >
            Filtrar
          </button>
        </div>
      </div>
    );
  }
}
Filter2.contextType = Servicos;

import React, { Fragment } from "react";
import { GlobalStyle } from "./styles";
import Routes from "./routes";
import Acoes from "./context/acoes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/pt-BR";

const App = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
    <Acoes>
      <Fragment>
        <GlobalStyle />
        <Routes />
      </Fragment>
    </Acoes>
  </MuiPickersUtilsProvider>
);

export default App;

import React, { Component } from "react";
import Switch from "react-switch";

import api from "../../../services/api";
import Servicos from "../../../context/index";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Form,
  Section,
  List,
  ListItem,
  Logo
} from "./styles";
import User from "../../../assets/imgs/user.png";
import Operation from "../../../components/Modal/Operation";
import history from "../../../routes/history";

export default class Operations extends Component {
  state = {
    operations: [],
    operation: "",
    loading: false,
    modalShow: false,
    parameter: "",
    message: ""
  };
  componentDidMount() {
    setTimeout(() => {
      if (this.context.acoes.profile.user_profile === "Operação") {
        history.push("/home");
      }
    }, 3000);
    this.context.acoes.showLoading();
    this.operations();
  }
  search(parameter) {
    this.setState({ message: "" });
    this.context.acoes.showLoading();
    api
      .get("operations/?search=" + parameter)
      .then(response => {
        if (response.data.data.length < 1) {
          this.setState({ message: "Nenhum registro encontrado!" });
        }
        this.context.acoes.hideLoading();
        this.setState({ operations: response.data.data });
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.setAccess(error.response.data.requester_profile);
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
      });
  }
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      this.setState({ message: "" });
      this.context.acoes.showLoading();
      api
        .get("operations/?search=" + e.target.value)
        .then(response => {
          if (response.data.data.length < 1) {
            this.setState({ message: "Nenhum registro encontrado!" });
          }
          this.context.acoes.hideLoading();
          this.setState({ operations: response.data.data });
          this.context.acoes.setAccess(response.data.requester_profile);
        })
        .catch(error => {
          // this.setState({message: "Nenhum registro encontrado!"});
          this.context.acoes.setAccess(error.response.data.requester_profile);
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
        });
    }
  };
  operations = () => {
    this.context.acoes.showLoading();
    api
      .get("operations")
      .then(response => {
        this.setState({ operations: response.data.data });
        this.setState({ loading: true });
        this.context.acoes.setAccess(response.data.requester_profile);

        this.context.acoes.hideLoading();
        if (response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

        if (error.response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      });
  };

  update = index => {
    this.context.acoes.showLoading();
    var operation = this.state.operations[index];
    var payload = {
      active: !operation.active
      // image: operation.image ? user.user_avatar : "/",
      // description:user.user_email,
      // name: user.user_name,
      // user_operations: operations
    };
    api
      .put("/operations/" + operation.id, payload)
      .then(response => {
        this.operations();
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.showError();

        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

        this.context.acoes.hideLoading();
      }, this);

    // try {
    //   const data = api.put("/operations/" + operation.id, payload);
    //   localStorage.setItem("info", data.response.data.requester_hash);
    //   this.operations();
    // } catch (error) {
    //   // this.context.acoes.hideLoading();
    //   // this.context.acoes.showError();
    //   // this.context.acoes.setMessage(error.response.data.message);
    //   // this.context.acoes.setErrorStatus(error.response.status);
    // }
  };
  // state = { modalShow: false, value: 0 };
  modalClose = () => {
    // this.context.loading.show()
    this.operations();
    this.setState({ ...this.state, modalShow: false });
  };
  openClose = index => {
    let operation = this.state.operations[index];
    this.setState({ ...this.state, modalShow: true, operation: operation });
  };
  refresh = () => {
    this.operations();
  };
  render() {
    return (
      <Container className="d-flex h-100 flex-wrap ">
        <Operation
          show={this.state.modalShow}
          onHide={e => this.modalClose()}
          operation={this.state.operation}
          update={this.refresh}
        />
        <Header
          className="d-flex flex-wrap align-items-center w-100"
          style={{ height: "20px" }}
        >
          <div className="col-12 " style={{ marginTop: "1%" }}>
            <Title>Operações</Title>
          </div>
          <div className="d-flex flex-wrap w-100">
            <Subtitle className="col-12 col-md-6">
              Gerenciamento de operações
            </Subtitle>
            {/* <div className="col-12 col-md-6 d-flex justify-content-end flex-wrap"> */}
            {this.context.acoes.profile.user_profile !== "Operação" ? (
              <div
                style={{ cursor: "pointer" }}
                className="mr-0 col-6 col-md-2 text-success d-flex align-items-center po"
                onClick={e => this.props.history.push("/home/operations/new")}
              >
                Criar Operação{" "}
                <span
                  className="icon-plus ml-2 font-weight-bold"
                  role="button"
                />
              </div>
            ) : null}
            <div className="mr-0 ml-0 col-6 col-md-4">
              <input
                style={{
                  width: "100%",
                  borderRadius: "5vw",
                  border: "1px solid #ccc",
                  padding: "3px 10px"
                }}
                onKeyDown={event => this._handleKeyDown(event)}
                type="text"
                placeholder="Buscar"
                className=""
                value={this.state.parameter}
                onChange={e => this.setState({ parameter: e.target.value })}
              />
              <button
                style={{
                  position: "absolute",
                  padding: "3px 10px",
                  background: "transparent",
                  border: "0px solid",
                  right: "20px"
                }}
                onClick={() => this.search(this.state.parameter)}
              >
                <span className="icon-search" />
              </button>
            </div>
            {/* </div> */}
          </div>
        </Header>
        <Section
          className="col-12"
          style={{ height: "55%", cursor: "pointer" }}
        >
          {this.state.loading ? (
            <List>
              {this.state.message != "" ? (
                <ListItem>
                  <div className="col-12 col-md-4 d-flex">
                    <small
                      className="col-12 col-md-9 d-flex"
                      style={{ color: "#808184", fontSize: 14 }}
                    >
                      {this.state.message}
                    </small>
                  </div>
                </ListItem>
              ) : (
                this.state.operations.map((dado, index) => (
                  <ListItem className="flex-wrap mb-4">
                    <div
                      className="col-12 col-md-4 d-flex"
                      onClick={e => this.openClose(index)}
                    >
                      <Logo
                        src={dado.image}
                        alt="Algar"
                        height="50"
                        style={{ cursor: "pointer" }}
                      />
                      <div className="col-9">
                        <h6 className="color-bluedark font-weight-normal">
                          Nome
                        </h6>
                        <small style={{ color: "#808184" }}>
                          {dado.operation_name}
                        </small>
                      </div>
                    </div>
                    <div
                      className="col-6 col-md-5"
                      onClick={e => this.openClose(index)}
                    >
                      <div>
                        <h6 className="color-bluedark font-weight-normal">
                          Descrição
                        </h6>
                        <small style={{ color: "#808184" }}>
                          {dado.description}
                        </small>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div>
                        <h6 className="color-bluedark font-weight-normal">
                          Status
                        </h6>
                        <div
                          className="switch_input d-flex align-items-center justify-content-between w-75 ml-0"
                          style={{ color: "#808184" }}
                        >
                          INATIVO
                          <label htmlFor="small-radius-switch">
                            <Switch
                              checked={dado.active}
                              onChange={() => {
                                this.update(index);
                              }}
                              onColor="#d0d0d0"
                              onHandleColor="#00A550"
                              offColor="#d0d0d0"
                              offHandleColor="#FA4D4D"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "orange",
                                    paddingRight: 2
                                  }}
                                />
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                    fontSize: 15,
                                    color: "orange",
                                    paddingRight: 2
                                  }}
                                />
                              }
                              className="react-switch"
                              id="icon-switch"
                            />
                          </label>
                          {/* <Switch
                          className=""
                          value={dado.active}
                          on={true}
                          off={false}
                          style={{
                            transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                          }}
                          onChange={() => {
                            this.update(index);
                          }}
                          styles={{
                            trackChecked: {
                              backgroundColor: "#cccccc"
                            },
                            button: {
                              backgroundColor: "red"
                            },
                            buttonChecked: {
                              backgroundColor: "green"
                            }
                          }}
                        /> */}
                          ATIVO
                        </div>
                      </div>
                    </div>
                  </ListItem>
                ))
              )}
            </List>
          ) : (
            "loading"
          )}
        </Section>
      </Container>
    );
  }
}

Operations.contextType = Servicos;

import { ResponsivePieCanvas } from "@nivo/pie";
import React, { Component } from "react";

// var data = require("./teste.json");

export default class Donuts extends Component {
  selectLineLegend = (point, event) => {
  };
  state={
    data:[]
  }
  componentDidMount(){
    this.setState({data: this.props.data})
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.data !== prevProps.data) {
      this.setState({data:this.props.data})
    }
  }
  render() {
    var porcentagem = (this.props.donutWordsAutomatizavel[0].number_of_tickets*100)/this.props.donutWords[0].number_of_tickets
   
    return (
      <div className="d-flex flex-wrap text-center h-75">
        <div className="col-2 h-100 pt-4">
          <div className="pt-3 pb-3">
            <h4>{this.props.donutWords[0].number_of_tickets}</h4>
            <small>Chamados Totais</small>
          </div>
          <div className="pt-3 pb-3">
            <h4>{this.props.donutWordsAutomatizavel[0].number_of_tickets}</h4>
            <small>Automatizáveis Totais</small>
          </div>
          <div className="pt-3 pb-3">
            <h4>{porcentagem == "NaN" ? "0%" : porcentagem.toFixed(2)}%</h4>
            <small>Automatizáveis</small>
          </div>
        </div>
        <div className={this.props.full ? "donutFull" : "donuts"}>
          <ResponsivePieCanvas
            data={this.state.data}
            margin={{ top: 40, right: 200, bottom: 40, left: 80 }}
            pixelRatio={2}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            colors={{ scheme: "paired" }}
            borderColor={{ from: "color", modifiers: [["darker", 0.6]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: "color" }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#ffffff"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            onClick={this.selectLineLegend}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: "ruby"
                },
                id: "dots"
              },
              {
                match: {
                  id: "c"
                },
                id: "dots"
              },
              {
                match: {
                  id: "go"
                },
                id: "dots"
              },
              {
                match: {
                  id: "python"
                },
                id: "dots"
              },
              {
                match: {
                  id: "scala"
                },
                id: "lines"
              },
              {
                match: {
                  id: "lisp"
                },
                id: "lines"
              },
              {
                match: {
                  id: "elixir"
                },
                id: "lines"
              },
              {
                match: {
                  id: "javascript"
                },
                id: "lines"
              }
            ]}
            legends={[
              {
                anchor: "right",
                direction: "column",
                translateX: 140,
                itemWidth: 60,
                itemHeight: 14,
                itemsSpacing: 2,
                symbolSize: 14,
                symbolShape: "circle",
                onClick: this.selectLineLegend
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Form_control } from "../../../components/Modal/User/styles";
import Switch from "react-input-switch";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Button,
  Section,
  Logo,
  ListItem,
  List
} from "./styles";
import User from "../../../assets/imgs/default.png";
import Servicos from "../../../context";
import api from "../../../services/api";

export default class Profile extends Component {
  state = { value: 0,
    logo_user: "",
    user_email: "",
    user_name: "",
    user_profile: "",
    active: false
  };
  update = () => {
    this.context.acoes.showLoading();
    
    let payload = {
      active: this.state.active,
      user_avatar: this.state.logo_user
        ? this.state.logo_user
        : this.context.acoes.profile.user_avatar,
      user_email: this.state.user_email
        ? this.state.user_email
        : this.context.acoes.profile.user_email,
      user_name: this.state.user_name
        ? this.state.user_name
        : this.context.acoes.profile.user_name,
      user_profile: this.state.user_profile
        ? this.state.user_profile
        : this.context.acoes.profile.user_profile

      // user_operations: operations
    };
    api
      .put("users/" + this.context.acoes.profile.user_id, payload)
      .then(response => {
        this.context.acoes.hideLoading();
        this.setState({ logo_user: "" });
        ///return this.props.update()
        // return this.props.onHide();
        // this.users()
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        // this.context.acoes.setMessage(error.response.data.message);
        // this.context.acoes.setErrorStatus(error.response.status);
        //localStorage.setItem("info", error.response.data.requester_hash);
      //   // return this.props.onHide();
      });
      //return this.context.acoes.onHide();
    // .then(this.users())
  };

  render() {
    return (
      <Container className="d-flex flex-wrap align-items-center ">
        <Header className="d-flex flex-wrap align-items-center w-100">
          <div className="col-12 ">
            <Title>Perfil</Title>
          </div>
          <div className="d-flex flex-wrap w-100">
            <Subtitle className="col-12">Gerenciamento do perfil</Subtitle>
          </div>
        </Header>
        {this.context.acoes.profile ? (<Section className="col-12 col-md-11 jumbotron bg-white">
          <div className="w-100 d-flex flex-wrap align-items-center">
            <div className="col-12 col-md-3 text-center">
              <Logo src={this.context.acoes.profile.user_avatar} className="rounded-circle" height={150} />
            </div>
            <div className="col-12 col-md-3">
              <div className="pt-2 pb-2">
                <div className="mb-4">
                  <h6 className="color-bluedark font-weight-normal">Nome</h6>
                  <input
                    type="text"
                    defaultValue={this.context.acoes.profile.user_name}
                    onChange={e => this.setState({ user_name: e.target.value })}
                    className="w-100"
                  />
                </div>
                <div>
                  <h6 className="color-bluedark font-weight-normal">Usuário</h6>
                  <input
                    type="text"
                    value={this.context.acoes.profile.user_id}
                    disabled
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="pt-2 pb-2">
                <div className="mb-4">
                <h6 className="color-bluedark font-weight-normal">Perfil</h6>
                  
                <Form_control
                
                style={{ height: 25, fontSize: 16 }}
                defaultValue={this.context.acoes.profile.user_profile}
                    onChange={e => this.setState({ user_profile: e.target.value })}
                id="exampleFormControlSelect1"
              >
                <option>Administrador</option>
                <option>Operação</option>
                <option>Mesa Digital</option>
                <option>Pré-venda</option>
              </Form_control>                 
                </div>
                <div>
                  <h6 className="color-bluedark font-weight-normal">E-mail</h6>
                  <input
                    type="text"
                    defaultValue={this.context.acoes.profile.user_email}
                    onChange={e => this.setState({ user_email: e.target.value })}
                    className="w-100"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="pt-2 pb-2">
                <div className="mb-4">
                  <h6 className="color-bluedark font-weight-normal">
                    Operações
                  </h6>
                  {typeof this.context.acoes.profile.user_operations !== "undefined" &&
                      this.context.acoes.profile.user_operations.length > 0 ? (
                        <small>
                          {this.context.acoes.profile.user_operations.map(
                            op => op.operation_name + " "
                          )}
                        </small>
                      ) : (
                        <small>Sem Operação</small>
                      )}
                </div>
                <div>
                  <h6 className="color-bluedark font-weight-normal">Status</h6>
                  <div className="switch_input d-flex align-items-center justify-content-between w-100 pl-4 pr-4 ml-0">
                  INATIVO
                  <Switch
                    className="m-0"
                    value={this.context.acoes.profile.active}
                    on={true}
                    off={false}
                    style={{
                      transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                    }}
                    onChange={value =>
                      this.setState({ active: !this.state.active })
                    }
                    styles={{
                      trackChecked: {
                        backgroundColor: "#cccccc"
                      },
                      button: {
                        backgroundColor: "#999"
                      },
                      buttonChecked: {
                        backgroundColor: "green"
                      }
                    }}
                  />
                  ATIVO
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
            {/* <Button
              className="btn"
              variant="secondary"
              onClick={e => this.props.onHide()}
            >
              EXCLUIR
            </Button> */}
            <Button
              syle={{ marginTop: "20px"}}
              onClick={this.update}
              className="btn btn-primary"
              variant="success"
              style={{ width: "200px", height: "50px",fontSize: 16, textAlign: "center", display:"flex", justifyContent: "center", alignItems: "center"}}
            >
              SALVAR
            </Button>
          </div>
        </Section>): "Loading"}
      </Container>
    );
  }
}
Profile.contextType = Servicos;
import axios from 'axios';
import Servicos from '../context';
// impokkrt {update} from "../context/acoes"

const baseurl = process.env.REACT_APP_BASE_URL
const api = axios.create({baseURL: baseurl})
const isHandlerEnabled = (config={}) => {
  return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? 
    false : true
}
const requestHandler = async(request) => {
  if (isHandlerEnabled(request)) {
    // Modify request here
    request.headers['requester_id'] = await localStorage.getItem("data")
    request.headers['requester_authorization'] =  await localStorage.getItem("info")
  }
  return await request
}
api.interceptors.request.use(
  request => requestHandler(request)
)


const errorHandler = async(error) => {
  if (isHandlerEnabled(error.config)) {

    // if(error.response.status < 500){
      if(error.response.status !== 401){
        if(error.response.data.requester_hash)
          await localStorage.setItem('info',error.response.data.requester_hash)
      }
    // }

  }
  return await Promise.reject({ ...error })
}

const successHandler = async(response) => {
  if (isHandlerEnabled(response.config)) {
    if(response.data.requester_hash)
        await localStorage.setItem('info',response.data.requester_hash)
     
      // this.context.acoes.profileCall();  }
  }
  return response
}
api.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
)
export default api;
// api.contextType = Servicos;

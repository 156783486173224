import React, { Component } from "react";

import "./card/TOPICO-DATA-CARD-INFO.css";
import Button from "react-bootstrap/Button";
import TopicosCard from "./topicos-card";
import { Link } from "react-router-dom";

import api from "../../../../services/api";
import history from "../../../../routes/history";
import Servicos from "../../../../context";
import { Container, Header, Title, Subtitle } from "./styles";

//import "./card/TOPICO-MODAL.css";

var topicosProperties = {};

class Topicos extends Component {
  state = {
    palavras: [],
    analysis_id: "",
    datas: [],
    labelButton: "Finalizar",
    listaPalavras: [],
    allClassificationsDone : false
  };
 
  componentDidMount() {
    setTimeout(() => {
      if(this.context.acoes.profile.user_profile === "Operação"){
        history.push("/home")
      }
    }, 3000);
    this.topics();
  }
  topics = () => {
    this.context.acoes.showLoading();
    if (
      localStorage.getItem("analysis_id") != undefined &&
      localStorage.getItem("analysis_id") != "" &&
      localStorage.getItem("analysis_id") != "null"
    ) {
      api
        .get("analysis/" + localStorage.getItem("analysis_id") + "/topics")
        .then(response => {
          this.setState({ ...this.state, datas: response.data.topics });
          
          this.context.acoes.hideLoading();
          if(response.data.requester_profile === "Operação"){
            history.push("/home")
          }
        this.context.acoes.setAccess(response.data.requester_profile);

        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

          // if(error.response.status === 500){
          //   history.push("/home")
          // }
          if(error.response.data.requester_profile === "Operação"){
            history.push("/home")
          }
        });
    } else {
      this.context.acoes.showError();
      this.context.acoes.hideLoading();
      this.context.acoes.setMessage("Não foi selecionado uma análise");
      this.context.acoes.setErrorStatus(403);

      history.push("/home");
    }
  };
  returnStopWords = () => {
    history.push("/home/stopwords");
  };
  finalizar() {
    this.context.acoes.showLoading();
    this.setState({ labelButton: "Loading..." });
    // let payload = {
    //
    // };
    // this.state.datas.map(data => )

    var classificacaoTopicos = {};
    var automacaoTopicos = {};
    var descricaoTopicos = {};

    Object.keys(topicosProperties).map((key, index) => {
      let data = topicosProperties[key]
      classificacaoTopicos[key] = data.classificacao;
      automacaoTopicos[key] = data.automatizavel;
      descricaoTopicos[key] = data.description.join();
    });

    var payload = {
      classificacaoTopicos,
      automacaoTopicos,
      descricaoTopicos
    };

    
    api
      .post(
        "/analysis/" + localStorage.getItem("analysis_id") + "/classification",
        payload, 
      )
      .then(response => {
        this.setState({ labelButton: "Finalizado" });

        localStorage.removeItem("analysis_id");
        this.context.acoes.hideLoading();
        history.push("/home");
        this.context.acoes.setAccess(response.data.requester_profile);

      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);


      });
  }


  handlePalavras = e => {
    let palavra = e.label;
    if (this.state.listaPalavras.indexOf(palavra) < 0) {
      this.setState(prevState => ({ palavras: [...this.state.palavras, e] }));
      this.setState(prevState => ({
        listaPalavras: [...this.state.listaPalavras, palavra]
      }));
    }
  };

  checkAllClassificationsDone = () => {

    let flagAllClassificationsDone = this.state.datas.length == Object.keys(topicosProperties).length

    if (flagAllClassificationsDone){

      Object.values(topicosProperties).map((data) => {
        
        
        flagAllClassificationsDone = flagAllClassificationsDone && data.classificacao != ""

      })


    }
    this.setState({allClassificationsDone : flagAllClassificationsDone})
    setTimeout(() => {
    }, 1000);
  }

  setCategoria = (label, topic,description) => {
    if (label != ""){
    if (!topicosProperties[topic]){
      let obj = {
        classificacao: label,
        automatizavel: false,
        description: description
      };
      topicosProperties[topic] = obj;
      }
    topicosProperties[topic].classificacao = label;
    this.checkAllClassificationsDone();
    }
  };

  setAutomatizavel = (hidden, topic, description) => {
    if (!topicosProperties[topic]){
      let obj = {
        classificacao: "",
        automatizavel: false,
        description: description
      };
      topicosProperties[topic] = obj;
      }

    topicosProperties[topic].automatizavel = hidden;
    this.checkAllClassificationsDone();
  };

  addClassificacaoTopic = (topic, description) => {
  };
  render() {
    return (
      <div className="TOPICOS-SCROLL">
        <div className="TOPICOS">
          <div className="TOPICOS-HEADER">
          <Title>Análises</Title>
            <Subtitle>Classificação de tópicos</Subtitle>
          </div>
          <div className="TOPICOS-BODY">
            {this.state.datas.map((data,index) => (
              <TopicosCard
                palavras={this.state.palavras}
                data={data}
                onPalavras={this.handlePalavras}
                setCategoria={this.setCategoria}
                setAutomatizavel={this.setAutomatizavel}
                addClassificacaoTopic={this.addClassificacaoTopic}
              />
            ))}
          </div>
        </div>
        <div className="TOPICOS-ACTIONS">
          {/* <Link to="app/histories"> */}
          <button
            onClick={() => this.returnStopWords()}
            style={{
              marginRight: "10px",
              backgroundColor: "grey",
              width: "200px",
              height: "50px",
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Retornar a stopwords
          </button>
          <Button
            disabled={
              this.state.allClassificationsDone ? false : true
            }
            variant={
              this.state.allClassificationsDone
                ? "success"
                : "secondary"
            }
            style={{
              width: "200px",
              height: "50px",
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              // backgroundColor: "grey",
              alignItems: "center",
              margin:0
            }}
            onClick={() => this.finalizar()}
          >
            {this.state.labelButton}
          </Button>
          {/* </Link> */}
        </div>
      </div>
    );
  }
}

export default Topicos;
Topicos.contextType = Servicos;

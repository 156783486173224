import React from "react";
import PropsTypes from "prop-types";

const cellNotDropped = {
  width: 200,
  border: "1px solid #888888",
  height: 30,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#000",
  background: "#fff",
  cursor: "pointer",
  fontSize: 12
};

const cellDropped = {
  width: 200,
  height: 30,
  display: "flex",
  justifyContent: "center",
  color: "white",
  // background: "rgba(43, 44, 67, 0.8)",
  // background: "red",
  cursor: "pointer",
  alignItems: "center",
  fontSize: 12,
  border:"1px solid gray"
  // marginTop: "60%"
};

const cellNotDroppedNotAlowed = {
  width: "200px",
  border: "1px solid #888888",
  height: "50%",
  display: "flex",
  "justify-content": "center",
  "align-items": "center",
  color: "#000",
  background: "#fff",
  cursor: "pointer",
  "font-size": "12px",
  background: `repeating-linear-gradient(
        45deg,
        #bc606d,
        #bc606d 5px,
        #ffffff 5px,
        #ffffff 10px
      )`
};

export default class Draggable extends React.Component {
  state = {
    drop: false,
    allowDrop: true,
    moving: false
  };
  drag = e => {
    this.setState({ moving: true });
    e.dataTransfer.setData("transfer", e.target.id);
  };
  drop = e => {
    try {
      this.setState({ moving: false });
      this.setState({ drop: this.props.isElementDropped(this.props.id) });
    } catch (err) {
      console.log(err);
    }
  };

  noAllowDrop = e => {
    e.stopPropagation();
    this.setState({ allowDrop: false });
  };

  noAllowDropBack = e => {
    e.stopPropagation();
    this.setState({ allowDrop: true });
  };
  estilo = () => {};
  render() {
    return (
      <div
        id={this.props.id}
        draggable="true"
        onDragEnd={this.drop}
        onDragLeave={this.noAllowDropBack}
        onDragStart={this.drag}
        onDragOver={this.noAllowDrop}
        style={
          this.state.moving
            ? cellNotDropped
            : this.state.drop
            ? cellDropped
            : !this.state.allowDrop
            ? cellNotDroppedNotAlowed
            : cellNotDropped
        }
      >
        {this.props.children}
      </div>
    );
  }
}

Draggable.propsTypes = {
  id: PropsTypes.string,
  style: PropsTypes.object,
  children: PropsTypes.node
};

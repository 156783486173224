import styled from "styled-components";

export const Container = styled.div`
  height: 30%;
  background: #fff;
  padding: 0px 1%;
  overflow-y: scroll;
`;
export const Header = styled.div`
  margin: 5% 0% 3.5%;
`;
export const Title = styled.h2`
  color: #000;
`;
export const Subtitle = styled.small`
  font-size: 18px;
  color: #d0d0d0;
`;
export const Form = styled.form`
  color: #d0d0d0;
  position: relative;
  input {
    width: 100%;
    border-radius: 5vw;
    border: 1px solid #ccc;
    padding: 3px 10px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
  button {
    position: absolute;
    padding: 3px 10px;
    background: transparent;
    border: 0px solid;
    right: 20px;
    @media (max-width: 900px) {
      margin: 4% 0%;
    }
  }
`;

export const Section = styled.section`
  width: 100%;
  color: #d0d0d0;
  h6 {
    margin-bottom: 2px;
  }
  .switch_input {
  }
`;

export const List = styled.ul`
  width: 100%;
  color: #d0d0d0;
  @media (max-width: 900px) {
    padding-bottom: 40%;
  }
`;

export const Logo = styled.img`
  border-radius: 50px;
  max-width: 50px;
  max-height: 50px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
`;

export const ListItem = styled.li`
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 80px;
  display: flex;
  color: #d0d0d0;
  background: #fff;
  align-items: center;

  small {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    width: 150px;
    display: inline-block;
    height: 20px;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    height: auto;
    padding-top: 15px;
    div {
      margin-bottom: 10px;
    }
  }
`;

import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import LoadingScreen from "react-loading-screen";

// import { Container } from './styles';

import Spinner from "../../assets/loading.svg";
import Sidenav from "../../components/Sidenav";
import Servicos from "../../context/";

import History from "./History";
import Imports from "./Imports/import/import";
import ColumnRelation from "./Imports/import/ColumnRelation/relation";
import Users from "./Users";
import Operations from "./Operations";
import NewOperation from "./NewOperation";
import Profile from "./User";
import StopWords from './Imports/import/stopwords';
import Topicos from './Imports/import/topicos';
import ImportFile from './Imports/import/importfile';

import Error from "../../components/Modal/Error";
import history from "../../routes/history";

export const Section = styled.div`
  transition: 0.6s;
  background: #f8f8f8;
  width: 95%;
  overflow-x: hidden;
  overflow-y: hidden;
  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;
export const Home = styled.div`
  display: flex;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export default class Portal extends Component {
  state = { modalShow: this.context.acoes.visibleError, menu: false };
  modalClose = () => {
    let erro = this.context.acoes.errorStatus
      if(erro != ""){
        if(erro === 401){
          this.context.acoes.exit()
        }
        if(erro === 500){
          history.push("/home")
        }
        this.context.acoes.hideError();
      }
      this.context.acoes.hideError();
  };
  openClose = () => this.setState({ ...this.state, modalShow: true });
  handleMenu = (hamburgerMenu) => {    
    if (hamburgerMenu === true){
      this.setState({
        menu: !this.state.menu
      });
    }
    else{
      this.setState({
        menu: false
      });
    }
  };
  render() {
    
    return (
      <Servicos.Consumer>
        {context => (
          <Home className="w-100 d-flex h-100 position-fixed">
            <LoadingScreen
              loading={context.acoes.visibleLoading}
              bgColor="rgba(0,0,0,0.8)"
              // spinnerColor="#9ee5f8"
              textColor="#676767"
              logoSrc={Spinner}
              style={{zIndex:1060}}
              text=""
            />
            <Error
              show={context.acoes.visibleError}
              onHide={e => this.modalClose()}
              message={context.acoes.message}
              status={context.acoes.errorStatus}
            />
            <Sidenav
              handleMenu={this.handleMenu}
              menu={this.state.menu}
              {...this.props}
            />
            <Section className={this.state.menu ? "w-80 mr-0" : ""}>
              <Switch>
                <Route exact path="/home" component={History} />
                <Route path="/home/imports" component={Imports} />
                <Route exact path="/home/columnrelation" component={ColumnRelation} />
                <Route exact path="/home/stopwords" component={StopWords} />
                <Route exact path="/home/topicos" component={Topicos} />
                <Route exact path="/home/users" component={Users} />
                <Route exact path="/home/operations" component={Operations} />
                <Route exact path="/home/importfile" component={ImportFile} />
                <Route
                  exact
                  path="/home/operations/new"
                  component={NewOperation}
                />
                <Route exact path="/home/profile" component={Profile} />
              </Switch>
            </Section>
          </Home>
        )}
      </Servicos.Consumer>
    );
  }
}
Portal.contextType = Servicos;

import React from "react";
import * as d3 from "d3";

import "./index.css";
var first = null;
var leng = null;
class Lines extends React.Component {
  state = {
    selected: {}
  };
  componentDidMount() {
    this.svgBars();
  }
  componentWillUnmount() {
    leng = null;
    first = null;
  }
  componentDidUpdate() {
    d3.selectAll("#bars > *").remove();
    this.svgBars();
  }
  getArray() {
    const values = this.props.data;
    leng = values.length;
    first = values[0].value
    return values.slice(0, 10);
  }
  decr(pr1, pr2) {
    return pr2 - pr1;
  }
  onWordClick(e) {}
  svgBars = word => {
    var scale = d3
      .scaleLinear()
      .domain([0, 141356])
      .range([0, 200]);

    const raiz = d3
      .select("#bars")
      .selectAll("div")
      .data(this.getArray())
      .enter()
      .append("div")
      .attr("class", "line-pai");

    const text = raiz
      .append("div")
      .attr("class", "line-text")
      .text(function(d, i) {
        return d.text;
      });

    const line = raiz
      .append("div")
      .attr("class", "line")
      .append("div")
      .attr("class", "dados")
      .style("width", function(d) {
        return (200/first)*d.value +"px"
      })
      .on("click", (d, i) => {
        this.props.selectWord(d);
      })
      .style("font-size", "10px")
      .style("text-align", "center")
      .style("display", "flex")
      .style("align-items", "center")
      .style("justify-content", "center")
      .style("background-color", (d, i) => {
        return !d.select ? "rgba(25,50,87,1)" : "rgba(59,199,95,1)";
      })
      .transition()
      .duration(500)
      .text(function(d, i) {
        return d.value;
      });
  };
  render() {
    return (
      <div className="d-flex flex-wrap w-50">
        <div className="h-0 mb-2 mr-5 pr-5" style={{ color: "#2b2c43" }}>
          <h4 style={{ textAlign: "center" }}>
            {this.props.totalTickets[0].number_of_tickets}
          </h4>
          <small>Chamados Totais</small>
        </div>
        <div id="bars" className="h-75 mb-3" />
      </div>
    );
  }
}
export default Lines;

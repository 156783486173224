import styled from "styled-components";

export const Title = styled.h2`
  color: #000;
`;
export const Subtitle = styled.small`
  font-size: 18px;
  color: #d0d0d0;
`;

export const Container = styled.div`
  height: 30%;
  width: 100%;
  background: #fff;
  padding: 0px 1%;
`;

export const Header = styled.div`
  padding: 20% 0% 13.5%;
  @media (max-width: 900px) {
    padding: 5% 0% 0%;
  }
`;
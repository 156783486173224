import React, { Component } from "react";
import "./SQL.css";
import api from "../../../../../services/api";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import "./apirest.css";
import { Redirect } from "react-router-dom";
import Servicos from "../../../../../context";
// import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";

class Apirest extends Component {
  state = {
    auth: false,
    request: false,
    auth_url: "www.exemplo.com/login",
    auth_header_header1: "client_id",
    auth_header_header2: "client_secret",
    auth_header_header3: "Content-Type",
    auth_header_header1_value: "",
    auth_header_header2_value: "",
    auth_header_header3_value: "",
    auth_body: "",
    request_url: "www.exemplo.com/credentials/base",
    request_header_header1: "client_id",
    request_header_header2: "client_secret",
    request_header_header3: "Content-Type",
    request_header_header1_value: "",
    request_header_header2_value: "",
    request_header_header3_value: "",
    redirect: false
  };

  send = () => {
    var {
      auth_url,
      auth_header_header1,
      auth_header_header2,
      auth_header_header3,
      auth_header_header1_value,
      auth_header_header2_value,
      auth_header_header3_value,
      auth_body,
      request_url,
      request_header_header1,
      request_header_header2,
      request_header_header3,
      request_header_header1_value,
      request_header_header2_value,
      request_header_header3_value
    } = this.state;

    let payload = {
      tipoLeitura: "rest",
      chamadaAutenticacao: "POST",
      uriAutenticacao: auth_url,
      headerAutenticacao: {
        [auth_header_header1]: auth_header_header1_value,
        [auth_header_header2]: auth_header_header2_value,
        [auth_header_header3]: auth_header_header3_value
      },
      bodyAutenticacao: {
        grant_type: auth_body
      },
      chamadaConsulta: "GET",
      uriConsulta: request_url,
      headerConsulta: {
        [request_header_header1]: request_header_header1_value,
        [request_header_header2]: request_header_header2_value,
        [request_header_header3]: request_header_header3_value
      }
    };
    this.context.acoes.bloquearMenu();

    this.context.acoes.showLoading();
    api
      .post("/analysis/" + localStorage.getItem("info") + "/rest", payload)
      .then(response => {
        this.context.acoes.desbloquearMenu();
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile)
        this.context.acoes.liberar();
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.desbloquearMenu();
        this.context.acoes.setAccess(error.response.data.requester_profile)
      });
  };
  redirect() {
    if (this.state.redirect) {
      return <Redirect to="/app/columnrelation" />;
    }
  }
  render() {
    const {
      auth,
      request,
      auth_url,
      auth_header_header1,
      auth_header_header2,
      auth_header_header3,
      auth_header_header1_value,
      auth_header_header2_value,
      auth_header_header3_value,
      auth_body,
      request_url,
      request_header_header1,
      request_header_header2,
      request_header_header3,
      request_header_header1_value,
      request_header_header2_value,
      request_header_header3_value
    } = this.state;

    return (
      <div className="API-REST" style={{ marginTop: "5%" }}>
        <div
          className="API-REST-AUTH"
          onClick={() => this.setState({ auth: !auth })}
          aria-controls="AUTH"
          aria-expanded={auth}
        >
          <div className="API-REST-AUTH-METHOD">
            <h1>POST</h1>
          </div>
          <div className="API-REST-AUTH-URL">
            <h1>{auth_url}</h1>
          </div>
          <div className="API-REST-AUTH-HEADER">
            <h1>3 types</h1>
          </div>
          <div className="API-REST-AUTH-BODY">
            <h1>Body</h1>
          </div>
          <div className="API-REST-AUTH-ICON">
            <h1>OK</h1>
          </div>
        </div>
        <Collapse in={this.state.auth} className="API-REST-COLLAPSE">
          <div id="AUTH">
            <div className="API-REST-AUTH-URL-DATA">
              <div className="API-REST-TITLE-URL">URL:</div>
              <div className="API-REST-CONTENT-URL">
                <input
                  type="text"
                  placeholder="www.exemplo.com.b/credentials/login/"
                  onChange={event =>
                    this.setState({ auth_url: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="API-REST-AUTH-HEADER-DATA">
              Headers:
              <div className="input-todo">
                <input
                  type="text"
                  id="client_id"
                  placeholder="client_id"
                  defaultValue={auth_header_header1}
                  onChange={e =>
                    this.setState({ auth_header_header1: e.target.value })
                  }
                />
                <input
                  type="text"
                  id="client_id-data"
                  placeholder="exemplo"
                  onChange={event =>
                    this.setState({
                      auth_header_header1_value: event.target.value
                    })
                  }
                />
              </div>
              <div className="input-todo">
                <input
                  type="text"
                  id="client_secret"
                  placeholder="client_secret"
                  defaultValue={auth_header_header2}
                  onChange={e =>
                    this.setState({ auth_header_header2: e.target.value })
                  }
                />
                <input
                  type="text"
                  id="client_secret-data"
                  placeholder="exemplo"
                  onChange={event =>
                    this.setState({
                      auth_header_header2_value: event.target.value
                    })
                  }
                />
              </div>
              <div className="input-todo">
                <input
                  type="text"
                  id="Content-Type"
                  placeholder="Content-Type"
                  defaultValue={auth_header_header3}
                  onChange={e =>
                    this.setState({ auth_header_header3: e.target.value })
                  }
                />
                <input
                  type="text"
                  id="Content-Type-data"
                  placeholder="JSON"
                  onChange={event =>
                    this.setState({
                      auth_header_header3_value: event.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="API-REST-AUTH-BODY-DATA">
              <div className="API-REST-TITLE-BODY">Body:</div>
              <div className="API-REST-CONTENT-BODY">
                <textarea
                  onChange={event =>
                    this.setState({ auth_body: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
        </Collapse>

        <div
          className="API-REST-AUTH"
          onClick={() => this.setState({ request: !request })}
          aria-controls="REQUEST"
          aria-expanded={request}
        >
          <div className="API-REST-AUTH-METHOD">
            <h1>GET</h1>
          </div>
          <div className="API-REST-AUTH-URL">
            <h1>{request_url}</h1>
          </div>
          <div className="API-REST-AUTH-HEADER">
            <h1>3 types</h1>
          </div>
          <div className="API-REST-AUTH-BODY">
            <h1>no-body</h1>
          </div>
          <div className="API-REST-AUTH-ICON">
            <h1>OK</h1>
          </div>
        </div>
        <Collapse in={this.state.request} className="API-REST-COLLAPSE">
          <div id="REQUEST">
            <div className="API-REST-AUTH-URL-DATA">
              <div className="API-REST-TITLE-URL">URL:</div>
              <div className="API-REST-CONTENT-URL">
                <input
                  type="text"
                  placeholder="www.exemplo.com.b/credentials/login/"
                  onChange={event =>
                    this.setState({ request_url: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="API-REST-AUTH-HEADER-DATA">
              Headers:
              <div className="input-todo">
                <input
                  type="text"
                  id="client_id"
                  placeholder="client_id"
                  defaultValue={request_header_header1}
                  onChange={e =>
                    this.setState({ request_header_header1: e.target.value })
                  }
                />
                <input
                  type="text"
                  id="client_id-data"
                  placeholder="exemplo"
                  onChange={event =>
                    this.setState({
                      request_header_header1_value: event.target.value
                    })
                  }
                />
              </div>
              <div className="input-todo">
                <input
                  type="text"
                  id="client_secret"
                  placeholder="client_secret"
                  defaultValue={request_header_header2}
                  onChange={e =>
                    this.setState({
                      request_header_header2_value: e.target.value
                    })
                  }
                />
                <input
                  type="text"
                  id="client_secret-data"
                  placeholder="exemplo"
                  onChange={event =>
                    this.setState({
                      request_header_header2_value: event.target.value
                    })
                  }
                />
              </div>
              <div className="input-todo">
                <input
                  type="text"
                  id="Content-Type"
                  placeholder="Content-Type"
                  defaultValue={request_header_header3}
                  onChange={e =>
                    this.setState({ request_header_header3: e.target.value })
                  }
                />
                <input
                  type="text"
                  id="Content-Type-data"
                  placeholder="JSON"
                  onChange={event =>
                    this.setState({
                      request_header_header3_value: event.target.value
                    })
                  }
                />
              </div>
            </div>
          </div>
        </Collapse>
        <button
          className="API-REST-BUTTON"
          className={request_url && auth_url && request_header_header1 && request_header_header1_value && auth_header_header1 && auth_header_header1_value ? "btn-success" : "btn"}
          disabled={request_url && auth_url && request_header_header1 && request_header_header1_value && auth_header_header1 && auth_header_header1_value ? false : true}
          onClick={this.send}
          style={{
            width: "200px",
            height: "50px",
            fontSize: 16,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          Conectar
        </button>
        {/* <h2>Em processo de atualização</h2> */}
      </div>
    );
  }
}
export default Apirest;
Apirest.contextType = Servicos;

import React, { Component } from "react";
import ReactWordcloud from "react-wordcloud";
var words = [];


const options = {
  width: "100%"
};
class Info extends Component {
  state = {
    words: [],
    selected: false,
    dados: [],
    show: false
  };
  componentDidMount() {
    this.setState({ words: this.props.data });
  }

  getCallback = callback => {
    return word => {
      this.props.selectedWord(word.text);
    };
  };

  render() {
    var { words, show } = this.state;
    const callbacks = {
      onWordClick: this.getCallback()
    };
    return (
      <div
        className="w-50 d-flex justify-content-center mt-3 mb-3"
        style={{
          backgroundColor: "white",
          boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.1)",
          height: 460
        }}
      >
        <ReactWordcloud
          options={{
            // fontFamily: 'courier new',
            fontSizes: [15, 35]
            // fontStyle: 'italic',
            // fontWeight: 'bold',
          }}
          words={words}
        />
      </div>
    );
  }
}

export default Info;

import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Switch from "react-switch";
import { Button, Logo, OperationList } from "./styles";
import ReactFileReader from "react-file-reader";

import User from "../../../assets/imgs/user.png";
import Servicos from "../../../context";
import api from "../../../services/api";
import Filter from "../../Filter/Chart3";

//import { MDBCloseIcon } from "mdbreact";

export default class Operation extends Component {
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.operation !== prevProps.operation) {
      var list=[];
      this.setState({ active: this.props.operation.active });
      //let other_users = this.props.operation.other_users;
      for (let x in this.props.operation.other_users ) {
       list.push(this.props.operation.other_users[x]);
      }
      this.setState({ other_users: list.sort() });
      let op_names = this.props.operation.users_in_operation;
      op_names.sort();
      list.sort();

      this.setState({ op_names: op_names, other_users: list });
      this.setState({ name: this.props.operation.operation_name });
    }
  }
  deleteUser = (index, name) => {
    var vetor = this.state.op_names;
    this.setState({ users_in_operation: vetor.splice(index, 1) });
    var lista = this.state.other_users;
    let remover = lista.filter((valor, index) => valor !== name);
    // if(remover.length < 0){
    //   this.setState({ other_users: remover });
    // }else{
    //   lista.push(remover[0])
    // }
    remover.push(name);
    remover.sort();
    this.setState({ other_users: remover });
  };
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      let users = this.state.users_in_operation;
      users.push(e.target.value);
      this.setState({ ...this.state, users });
    }
  };
  state = {
    user_name: "",
    user_email: "",
    user_profile: "",
    active: "",
    logo_operation: "",
    operations: [],
    op_names: [],
    user: ""
  };
  handleFiles = files => {
    if (files.fileList[0].size < 400000) {
      this.setState({ logo_operation: files.base64 });
    } else {
      alert("Arquivo muito grande!");
    }
  };
  // excluir = () => {
  //   this.context.acoes.showLoading();
  //   this.setState({ logo_operation: "" });
  //   let id = this.props.operation.id
  //   api
  //     .delete("/operations/" + id)
  //     .then(response => {
  //       this.context.acoes.hideLoading();
  //       return this.props.onHide();
  //     })
  //     .catch(error => {
  //       this.context.acoes.hideLoading();
  //       this.context.acoes.showError();
  //       this.context.acoes.setMessage(error.response.data.message);
  //       this.context.acoes.setErrorStatus(error.response.status);
  //       return this.props.onHide();
  //     });
  // };
  update = () => {
    this.context.acoes.showLoading();
    let id = this.props.operation.id;
    let payload = {
      operation_name: this.state.name
        ? this.state.name
        : this.props.operation.name,
      // description: this.state.description,
      image: this.state.logo_operation
        ? this.state.logo_operation
        : this.props.operation.image,
      active: this.state.active,
      users_in_operation: this.state.op_names
    };
    api
      .put("/operations/" + id, payload)
      .then(response => {
        this.setState({ logo_operation: "" });
        this.setState({ op_names: [] });
        this.setState({ name: "" });
        // this.context.acoes.hideLoading();
        return this.props.update();
        // return this.props.update();
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        return this.props.onHide();
        // this.setState({ modalShow: true });
        // this.setState({ loading: false });
        // return <ErrorModal show={this.state.modalShow} onHide={modalClose} />;
      });
    return this.props.onHide();
  };
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (e.target.value.indexOf("@") >= 0) {
        let users = this.state.op_names;
        users.push(e.target.value);
        this.setState({ ...this.state, users });
        e.target.value = "";
      }
    }
  };
  _handleBlur = e => {
    if (e.target.value.indexOf("@") >= 0) {
      let users = this.state.op_names;
      users.push(e.target.value);
      this.setState({ ...this.state, users });

      e.target.value = "";
    }
  };
  UsersToOperation = info => {
    let users = this.state.op_names;
    users.push(info);
    users.sort();
    this.setState({ users });
  };

  render() {
    return (
      <Modal
        {...this.props}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-white mr-0 mb-0" closeButton />
        <Modal.Body className="m-0 w-100 d-flex flex-wrap pt-1 ">
          <div className="col-12 mb-1">
            <div className="col-12 text-center">
              <ReactFileReader
                id="files"
                fileTypes={[".png", ".jpg", ".jpeg"]}
                base64={true}
                multipleFiles={false}
                handleFiles={this.handleFiles}
              >
                <Logo
                  src={
                    this.state.logo_operation
                      ? this.state.logo_operation
                      : this.props.operation.image
                  }
                  alt="Algar"
                  height="80"
                  className="mb-3"
                  style={{ cursor: "pointer" }}
                />
              </ReactFileReader>
            </div>
          </div>
          <div className="col-12 mb-1">
            <div className="col-12 mb-4">
              <h6 className="color-bluedark font-weight-normal">Nome</h6>
              <input
                type="text"
                defaultValue={this.props.operation.operation_name}
                className="w-100"
                value={
                  this.state.name
                    ? this.state.name
                    : this.props.operation.operation_name
                }
                onChange={e => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="col-12 mb-4">
              <h6 className="color-bluedark font-weight-normal">Usuário</h6>
              <Filter
                operation={this.state.other_users}
                selectedUser={this.UsersToOperation}
                placeholder="Selecionar..."
              />
              {/* <input
                type="text"
                isFocused="false"
                className="w-100 col-10"
                onKeyDown={event => this._handleKeyDown(event)}
                onBlur={event => this._handleBlur(event)}
                style={{ width: "80%"}}
              />
              <span
                  className="icon-plus ml-2 font-weight-bold col-3"
                  role="button"
                  style={{ width: "20%", cursor: "pointer"}}
                  onClick={event => this._handleKeyDown(event)}
                /> */}
            </div>
            <div className="col-12 mb-1">
              <h6 className="color-bluedark font-weight-normal">Usuários</h6>
              {typeof this.state.op_names !== "undefined" &&
              this.state.op_names.length > 0 ? (
                <OperationList className="d-flex flex-wrap">
                  {this.state.op_names.map((name, index) => (
                    <span class="badge" style={{ marginRight: "5px" }}>
                      {name}{" "}
                      <h7
                        onClick={() => this.deleteUser(index, name)}
                        style={{
                          fontWight: "bold",
                          cursor: "pointer",
                          marginLeft: "10px"
                        }}
                      >
                        X
                      </h7>
                    </span>
                  ))}
                </OperationList>
              ) : (
                // <textarea
                //   rows={this.state.op_names.length}
                //   defaultValue={this.state.op_names.map((name, index) => name)}
                //   className="w-100 form-control"
                // />
                "Sem usuários associados a essa operação!"
              )}
            </div>
          </div>
          <div className="col-12 p-4">
            <div className="d-flex align-items-center flex-wrap justify-content-between">
              <h6 className="color-bluedark font-weight-normal col-12 col-md-6">
                Status
              </h6>
              <div className="switch_input d-flex align-items-center justify-content-between col-12 col-md-6 pl-4 pr-4 ml-0">
                {/* INATIVO
                <Switch
                  className="m-0"
                  value={this.state.active}
                  on={true}
                  off={false}
                  style={{
                    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }]
                  }}
                  onChange={value =>
                    this.setState({ active: !this.state.active })
                  }
                  styles={{
                    trackChecked: {
                      backgroundColor: "#cccccc"
                    },
                    button: {
                      backgroundColor: "red"
                    },
                    buttonChecked: {
                      backgroundColor: "green"
                    }
                  }}
                /> */}
                <label className="pr-2">INATIVO</label>
                <label htmlFor="small-radius-switch">
                  <Switch
                    checked={this.state.active}
                    onChange={value =>
                      this.setState({ active: !this.state.active })
                    }
                    onColor="#d0d0d0"
                    onHandleColor="#00A550"
                    offColor="#d0d0d0"
                    offHandleColor="#FA4D4D"
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "orange",
                          paddingRight: 2
                        }}
                      />
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "orange",
                          paddingRight: 2
                        }}
                      />
                    }
                    className="react-switch"
                    id="icon-switch"
                  />
                </label>
                <label className="pl-2">ATIVO</label>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="m-0 w-100 border-white">
          <div className="col-12 col-md-6 d-flex align-items-center">
            <Button
              onClick={this.update}
              className="btn-success"
              style={{
                cursor: "pointer",
                width: "200px",
                height: "50px",
                fontSize: 16,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              SALVAR
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
Operation.contextType = Servicos;

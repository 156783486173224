import React, { Component } from "react";

import {
  Container,
  Logo,
  Login,
  Title,
  PrimaryBtn,
  Credentails
} from "./styles";

import Algar from "../../assets/imgs/AlgarTech.svg";
import Evolution from "../../assets/svgs/animated-back.svg";
import api from "../../services/api";
import history from "../../routes/history";
import Servicos from "../../context";
import { isAuthenticated } from "../../routes/auth";

class Main extends Component {
  state = {
    ip: localStorage.getItem("ip")
      ? localStorage
          .getItem("ip")
          .substr(0, localStorage.getItem("ip").length - 1)
      : "",
    code: localStorage.getItem("code") ? localStorage.getItem("code") : ""
  };
  componentWillMount() {
    isAuthenticated() ? history.push("/home") : history.push("/");
  }
  logar = () => {
    if (process.env.REACT_APP_STAGE === "local") {
      setTimeout(() => {
        let ip = this.state.ip + ",";
        localStorage.setItem("ip", ip);
        localStorage.setItem("code", this.state.code);
      }, 20);

      setTimeout(() => {
        api
          .get("/oauth/credentials/?code=" + this.state.code)
          .then(response => {
            localStorage.setItem("info", response.data.data.hash);
            localStorage.setItem("data", response.data.data.name);
            localStorage.setItem("user", response.data.data.user);
            localStorage.setItem("user_avatar", response.data.data.user_avatar)
            this.context.acoes.setAccess("Operação")
            history.push("/home");
          })
          .catch(error => {
            this.context.acoes.hideLoading();
            this.context.acoes.showError();
            if (error.response) {
              this.context.acoes.setMessage(error.response.data.message);
              this.context.acoes.setErrorStatus(error.response.status);
            } else {
              this.context.acoes.setMessage("Falha ao logar");
              this.context.acoes.setErrorStatus(403);
            }
          });
      }, 50);
    } else {
      let responseType = "code";
      let oauthUrl =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";
      var oauthUri =
        oauthUrl +
        "?client_id=" +
        process.env.REACT_APP_CLIENT_ID +
        "&response_type=" +
        responseType +
        ("&redirect_uri=" +
          encodeURIComponent(process.env.REACT_APP_REDIRECT_URI)) +
        "&scope=user.read&response_mode=fragment";
      let title = "Login Algar Tech";
      return window.location.replace(
        oauthUri,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no"
      );
    }
  };
  render() {
    return (
      <Container style={{ backgroundImage: `url(${Evolution})` }}>
        <div className="col-12 col-md-3 d-flex align-items-center justify-content-center flex-wrap">
          <div className="text-center">
            <Logo src={Algar} alt="Algar" height="50" className="mb-5" />
            <Login className="bg-gray text-center p-2 align-content-center">
              <div className="mb-5 col-12">
                <Title className="color-bluedark">Portal Analytics</Title>
                <small className="w-100 text-secondary">
                  intuitive algar tech
                </small>
              </div>
              <br />
              <Credentails
                style={{
                  display:
                    process.env.REACT_APP_STAGE !== "local" ? "none" : "flex"
                }}
              >
                <input
                  type="text"
                  defaultValue={
                    localStorage.getItem("ip")
                      ? localStorage
                          .getItem("ip")
                          .substr(0, localStorage.getItem("ip").length - 1)
                      : ""
                  }
                  placeholder="Ip sem virgula"
                  onChange={e =>
                    this.setState({ ...this.state, ip: e.target.value })
                  }
                />
                <input
                  type="text"
                  placeholder="Code"
                  defaultValue={
                    localStorage.getItem("code")
                      ? localStorage.getItem("code")
                      : ""
                  }
                  onChange={e =>
                    this.setState({ ...this.state, code: e.target.value })
                  }
                />
              </Credentails>
              <PrimaryBtn
                className="rounded-75 bg-bluelight"
                onClick={this.logar}
              >
                Login algar tech
              </PrimaryBtn>
            </Login>
          </div>
        </div>
      </Container>
    );
  }
}

export default Main;
Main.contextType = Servicos;

import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

// import { Container } from "./styles";

export default class Error extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        dialogClassName="modal-80w excluir"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        {/* <Modal.Header closeButton /> */}
        <Modal.Body className="position-relative  text-center d-flex align-items-center flex-wrap pt-5 pb-5">
          <h3 className="text-danger">Error !</h3>
          <br />
          <p className="text-break">{this.props.message}</p>
        </Modal.Body>

        <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
          <button className="btn col-12" onClick={e => this.props.onHide()} style={{ width: "200px", height: "50px", fontSize: 14}}>
            FECHAR
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

import React from "react";
import PropsTypes from "prop-types";

const columnDroppable = {
  width: "100%",
  height: "-webkit-fill-available",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: 20,
  // alignItems: "center",
  color: "rgb(43, 44, 67)"
  // backgroundColor:"rgba(43, 44, 67, 0.8)"
};
const columnDropped = {
  width: "100%",
  height: "-webkit-fill-available",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  color: "rgb(43, 44, 67)",
  backgroundColor: "rgba(43, 44, 67, 0.8)",
  paddingTop: "65%"
};

const columnDroppableAllowDropStyle = {
  width: "100%",
  height: "-webkit-fill-available",
  display: "flex",
  justifyContent: "center",
  // alignItems: "center",
  color: "rgb(43, 44, 67)",
  border: "2px dashed #00A54F",
  "border-radius": "25px"
  //   backgroundColor:"rgba(43, 44, 67, 0.8)"
};

export default class Droppable extends React.Component {
  state = {
    drop: false,
    allowDrop: false
  };

  drop = e => {
    if (!this.props.isColumnDropped("coluna" + e.target.id)) {
      e.preventDefault();
      const data = e.dataTransfer.getData("transfer");
      var droppedElement = document.getElementById(data);
      e.target.appendChild(droppedElement);
      var column = e.target.id;
      let a = { column: [column, data] };
      let b = '{"coluna' + e.target.id + '":"' + data + '"}';

      this.props.setColumnRelation(b);
      this.setState({ drop: this.props.isElementDropped(data) });
    }
  };
  allowDrop = e => {
    e.preventDefault();
    this.props.setColumnDragOver(e.target.id);
    this.setState({
      allowDrop: !this.props.isColumnDropped("coluna" + e.target.id)
    });
  };
  leftDrop = e => {
    e.preventDefault();
    this.props.setColumnDragOver(null);
    this.setState({ allowDrop: false });
  };
  move = () => {
    this.setState({ drop: false });
  };
  render() {
    return (
      <div
        // allowDrop={this.props.isColumnDropped("coluna" + this.props.id)}
        id={this.props.id}
        onDragLeave={this.leftDrop}
        onDrop={this.drop}
        onDragOver={e => this.allowDrop(e)}
        style={
          this.props.isColumnDropped("coluna" + this.props.id)
            ? columnDropped
            : this.props.isColumnDragOver(this.props.id)
            ? columnDroppableAllowDropStyle
            : columnDroppable
        }
      >
        {this.props.children}
      </div>
    );
  }
}

Droppable.propsTypes = {
  id: PropsTypes.string,
  style: PropsTypes.object,
  children: PropsTypes.node
};

import React, { Component } from "react";
import ReactWordcloud from "react-wordcloud";

var words = [];

class Info extends Component {
  state = {
    words: [],
    selected: false,
    dados: [],
    show: false
  };
  componentDidMount() {
    let arr = this.props.data
    for(let i in arr){
      if (arr[i].text.length > 20){
        arr[i].text = arr[i].text.substring(0,20) + "..."
      }
    }
    this.setState({ words: arr });
  }
 shouldComponentUpdate(nextProps, nextState){
  if(this.state.words !== nextState.words){
    return true
  }
  return false
 }

  getCallback = callback => {
    return word => {


      this.props.selectedservice_users(word.text);
    };
  };
  render() {
    var { words, show } = this.state;

    const callbacks = {
      onWordClick: this.getCallback()
    };
    return (
      <div
        className="w-50 d-flex justify-content-center "
        style={{ backgroundColor: "white" }}
      >
        <ReactWordcloud
          // options={{
          //   enableTooltip: true,
          //   deterministic: false,
          //   fontFamily: "impact",
            // fontSizes: [5, 40],
          //   fontStyle: "normal",
          //   fontWeight: "normal",
          //   padding: 1,
          //   rotations: 1,
          //   rotationAngles: [0, 50],
          //   scale: "sqrt",
          //   spiral: "archimedean",
          //   transitionDuration: 1000
          // }}
          // callbacks={callbacks}
          words={words}
        />
      </div>
    );
  }
}

export default Info;

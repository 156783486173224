import React, { Component } from "react";
import { Container } from "../../../pages/Portal/Imports/styles";

import Modal from "react-bootstrap/Modal";
import Card from "../../Card";
import api from "../../../services/api";
import Servicos from "../../../context";


// import { Container } from "./styles";


export default class Excluir extends Component {
  deleteAnalysis = () => {
    this.context.acoes.showLoading();
    api
      .delete("/analysis/" + this.props.analise.analysis_id)
      .then(response => {
       
        this.context.acoes.hideLoading();
        this.props.update()
        window.location.reload()
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        
      });
      return this.props.onHide()
  };
  render() {
    return (
      <Modal
        {...this.props}
        dialogClassName="modal-80w excluir"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton /> */}
        <Modal.Body className="position-relative d-flex justify-content-center">
          <div className="card position-absolute">
            <Card
              analise={this.props.analise}
              style={{ zIndex: 219 }}
              // key={index}
              // selecionado={this.selececao}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
          <button className="btn mb-3" style={{width: 200, height:50, fontSize: 14, marginRight:0}} onClick={e=> this.deleteAnalysis()} >EXCLUIR</button>
          <button className="btn mb-3" style={{width: 200, height:50, fontSize: 14, marginLeft:0}} onClick={e=> this.props.onHide()}>CANCELAR</button>
        </Modal.Footer>
      </Modal>
    );
  }
}
Excluir.contextType = Servicos;
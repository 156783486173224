import React, { Component } from "react";
import Servicos from "./index";
import history from "../routes/history";
import api from '../services/api'

const validadeData = data => {
  if (
    data !== null &&
    data !== undefined &&
    data !== "undefined" &&
    data !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};
class Acoes extends Component {
  showLoading = () => {
    this.setState({ acoes: { ...this.state.acoes, visibleLoading: true } });
  };

  hideLoading = () => {
    this.setState({ acoes: { ...this.state.acoes, visibleLoading: false } });
  };

  showError = () => {
    this.setState({ acoes: { ...this.state.acoes, visibleError: true } });
  };

  hideError = () => {
    this.setState({ acoes: { ...this.state.acoes, visibleError: false } });
  };
  setMessage = text => {
    this.setState({ acoes: { ...this.state.acoes, message: text } });
  };
  sair = () => {
    localStorage.removeItem("info");
    localStorage.removeItem("data");
    window.location.href ="/"
  };
  setErrorStatus = status => {
    this.setState({ acoes: { ...this.state.acoes, errorStatus: status } });
  };
  liberar = () => {
    this.setState({ acoes: { ...this.state.acoes, liberado: !this.state.acoes.liberado } });
  };

  bloquearMenu = () => {
    this.setState({ acoes: { ...this.state.acoes, menuBloqueado: true } });
  };

  desbloquearMenu = () => {
    this.setState({ acoes: { ...this.state.acoes, menuBloqueado: false } });
  };
  access = perfil =>{
    if(validadeData(perfil)){
      this.setState({ acoes: { ...this.state.acoes, access: perfil } });
    } else {
      this.setState({ acoes: { ...this.state.acoes, access: "Operação" } });
    }  
  };



  update =async()=>{
    let user = await api.get("/users/" + localStorage.getItem("data"))
    this.access(user.data.requester_profile)
    return await localStorage.setItem("user_avatar",user.data.data.user_avatar)
  }

  state = {
    acoes: {
      visibleLoading: false,
      showLoading: this.showLoading,
      hideLoading: this.hideLoading,
      visibleError: false,
      showError: this.showError,
      hideError: this.hideError,
      setMessage: this.setMessage,
      message: "",
      setErrorStatus: this.setErrorStatus,
      errorStatus: "",
      exit: this.sair,
      profileCall:this.update,
      setAccess:this.access,
      access:"",
      profile:"",
      liberado:false,
      liberar: this.liberar,
      bloquearMenu: this.bloquearMenu,
      desbloquearMenu: this.desbloquearMenu,
      menuBloqueado:false,
    }
  };

  render() {
    return (
      <Servicos.Provider value={this.state}>
        {this.props.children}
      </Servicos.Provider>
    );
  }
}

export default Acoes;

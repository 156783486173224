import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  cursor:pointer;
  display: block;
  border-radius: 15px;
  position:relative;  
  .img {
    height: 160px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
  }
  .progress {
    backgound: #fff;
    width: 85px;
    height: 0.8rem;
    div {
      margin: 0px;
    }
  }
  .status {
    bottom:55px;
    right: 20px;
  }
  .favorite {
    // top: 15px;
    right: 0;
  }
`;
export const Title = styled.h5`
  color: #000;
  margin-bottom: 2px;
`;
export const SubTitle = styled.h6`
  color: #808184;
  margin-bottom: 2px;
`;
export const Date = styled.small`
  color: #d0d0d0;
`;

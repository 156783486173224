import React, { Component } from "react";
import "./Column.css";
import Button from "react-bootstrap/Button";
import api from "../../../../../services/api";
import { Redirect } from "react-router-dom";
import DndTest from "./Dnd/DndTest";
import Modal from "react-bootstrap/Modal";
import Droppable from "./Dnd/Droppable";
import DroppableBack from "./Dnd/DroppableBack";
import Draggable from "./Dnd/Draggable";
import history from "../../../../../routes/history";
import Servicos from "../../../../../context";
import { Container, Header, Title, Subtitle } from "./styles";

let relationsToPayload = {};
let columnDragOver = null;

class ColumnRelation extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  state = {
    relation: "",
    selected: "",
    redirect: false,
    colunas: false,
    show: false,
    hidden: false,
    readyToAdvance: false,
    overflow: "scroll"
  };

  componentDidMount() {
    relationsToPayload = {};
    setTimeout(() => {
      if (this.context.acoes.profile.user_profile === "Operação") {
        history.push("/home");
      }
    }, 3000);
    this.context.acoes.showLoading();
    if (
      localStorage.getItem("analysis_id") != undefined &&
      localStorage.getItem("analysis_id") != "" &&
      localStorage.getItem("analysis_id") != "null"
    ) {
      api
        .get(
          "/analysis/" +
            localStorage.getItem("analysis_id") +
            "/columnstorelate"
        )
        .then(response => {
          this.setState({
            ...this.state,
            colunas: true
          });
          this.context.acoes.setAccess(response.data.requester_profile);

          this.setState({
            relation: response.data.primeirasLinhas
          });
          this.context.acoes.hideLoading();
          if (response.data.requester_profile === "Operação") {
            history.push("/home");
          }
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);

          if (error.response.data.requester_profile === "Operação") {
            history.push("/home");
          }
          history.push("/home");
        });
    } else {
      history.push("/home");
    }
  }
  componentWillUnmount() {
    relationsToPayload = [];
    columnDragOver = null;
  }

  send = () => {
    this.setState({ overflow: "hidden" });
    this.context.acoes.showLoading();

    let payload = {
      relacionamentoColunas: {}
    };

    payload.relacionamentoColunas = relationsToPayload;

    api
      .post(
        "/analysis/" + localStorage.getItem("analysis_id") + "/columnrelations",
        payload
      )
      .then(response => {
        localStorage.setItem("profile", response.data.requester_profile);
        this.context.acoes.setAccess(response.data.requester_profile);

        this.context.acoes.hideLoading();
        history.push("/home/stopwords");
      })
      .catch(error => {
        this.context.acoes.setAccess(error.response.data.requester_profile);

        this.setState({ overflow: "scroll" });
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
      });
  };

  cancelar = () => {
    this.context.acoes.showLoading();
    this.modalClose();
    api
      .delete("/analysis/" + localStorage.getItem("analysis_id") + "/hard")
      .then(response => {
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);

        localStorage.setItem("profile", response.data.requester_profile);
        history.push("/home");
      })
      .catch(error => {
        if (error.response.status != 403) {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        } else {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        }
      });
  };

  redirect() {
    if (this.state.redirect) {
      return <Redirect to="/app/stopwords" />;
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  setColumnRelation = relation => {
    var dataParsed = JSON.parse(relation);
    let index = Object.values(relationsToPayload).indexOf(
      Object.values(dataParsed)[0]
    );
    if (index >= 0) {
      delete relationsToPayload[Object.keys(relationsToPayload)[index]];
    }
    relationsToPayload = Object.assign(relationsToPayload, dataParsed);

    this.verifiReadyToAdvance();
  };

  droppedToDroppableBack = e => {
    const data = e.dataTransfer.getData("transfer");
    var droppedElement = document.getElementById(data);
    e.target.appendChild(droppedElement);
    let relation = '{"coluna":"' + data + '"}';

    var dataParsed = JSON.parse(relation);
    let index = Object.values(relationsToPayload).indexOf(
      Object.values(dataParsed)[0]
    );

    if (index >= 0) {
      delete relationsToPayload[Object.keys(relationsToPayload)[index]];
    }

    this.verifiReadyToAdvance();
  };

  verifiReadyToAdvance = () => {
    let valuesReady = Object.values(relationsToPayload);
    let newFlag =
      valuesReady.includes("cod_chamado") &&
      valuesReady.includes("cod_chamado") &&
      valuesReady.includes("data_abertura") &&
      valuesReady.includes("usuario_solicitante") &&
      valuesReady.includes("descricao");

    this.setState({ readyToAdvance: newFlag });
  };

  isElementDropped = elementId => {
    return Object.values(relationsToPayload).indexOf(elementId) >= 0;
  };

  isColumnDropped = columnId => {
    return Object.keys(relationsToPayload).indexOf(columnId) >= 0;
  };

  setColumnDragOver = columnId => {
    columnDragOver = columnId;
  };

  isColumnDragOver = columnId => {
    return columnId == columnDragOver;
  };

  handleShow() {
    this.setState({ show: true });
    this.send();
  }
  modalClose = () => {
    this.setState({ ...this.state, modalShow: false });
  };
  openClose = () => {
    this.setState({ ...this.state, modalShow: true });
  };
  render() {
    const { selected, relation, colunas } = this.state;
    return (
      <div className="RELATION">
        <Modal
          {...this.props}
          dialogClassName="modal-80w excluir"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          analise={this.state.selecionado}
          show={this.state.modalShow}
          onHide={e => this.modalClose()}
        >
          {/* <Modal.Header closeButton /> */}
          <Modal.Body
            className="position-relative d-flex justify-content-center"
            style={{ paddingTop: "10%" }}
          >
            <div>
              <h4 style={{ "text-align": "center" }}>
                Tem certeza que deseja cancelar o processo?
              </h4>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
            <button
              className="btn-success mb-3"
              style={{ width: 200, height: 50, fontSize: 14, marginRight: 0 }}
              onClick={e => this.cancelar()}
            >
              Sim
            </button>
            <button
              className="btn mb-3"
              style={{ width: 200, height: 50, fontSize: 14, marginLeft: 0 }}
              onClick={e => this.modalClose()}
            >
              Não
            </button>
          </Modal.Footer>
        </Modal>
        {this.redirect()}
        <div className="HEADER-TOP">
          <div className="HEADER-INFO">
            <Title>Análises</Title>
            <Subtitle>Análise da base de dados</Subtitle>
          </div>
        </div>
        <div className="RELATION-BODY">
          {typeof relation !== "undefined" && relation.length > 0 && colunas ? (
            <div
              className="RELATION-INFO"
              style={{ overflowX: this.state.overflow }}
            >
              {relation.map((data, index) => (
                <div className="RELATION-COLUMN" key={index + 1}>
                  <div className="block-info">
                    <Droppable
                      id={index + 1}
                      setColumnRelation={this.setColumnRelation}
                      isElementDropped={this.isElementDropped}
                      isColumnDropped={this.isColumnDropped}
                      setColumnDragOver={this.setColumnDragOver}
                      isColumnDragOver={this.isColumnDragOver}
                    />
                  </div>
                  {/* <label style={{ fontSize: 14, fontWeight:"bold" }}>Coluna {index + 1}</label> */}
                  {data[Object.keys(data)[0]].map(info =>
                    typeof relation !== "undefined" && relation.length > 0 ? (
                      <div className="REALATION-DATA">{info}</div>
                    ) : (
                      <div className="REALATION-DATA">Sem dados</div>
                    )
                  )}
                </div>
              ))}
            </div>
          ) : (
            "loading"
          )}
          <div>
            <small>
              Clique e arraste a palavra para a coluna correspondente
            </small>
          </div>
          <DroppableBack
            id="categorias"
            droppedToDroppableBack={this.droppedToDroppableBack}
          >
            <Draggable
              id="cod_chamado"
              isElementDropped={this.isElementDropped}
            >
              Código do chamado *
            </Draggable>
            <Draggable
              id="data_abertura"
              isElementDropped={this.isElementDropped}
            >
              Data de abertura *
            </Draggable>
            <Draggable
              id="usuario_afetado"
              isElementDropped={this.isElementDropped}
            >
              Usuário afetado
            </Draggable>
            <Draggable
              id="usuario_solicitante"
              isElementDropped={this.isElementDropped}
            >
              Usuário solicitante *
            </Draggable>
            <Draggable id="descricao" isElementDropped={this.isElementDropped}>
              Descrição *
            </Draggable>
            <Draggable id="status" isElementDropped={this.isElementDropped}>
              Status
            </Draggable>
            <Draggable
              id="sla_violado"
              isElementDropped={this.isElementDropped}
            >
              SLA violado
            </Draggable>
          </DroppableBack>
          {/* <DndTest/> */}
        </div>
        <div className="RELATION-ACTIONS">
          <button
            className="btn"
            style={{
              width: "200px",
              height: "50px",
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              marginRight: "60px",
              cursor: "pointer"
            }}
            onClick={e => this.openClose()}
          >
            CANCELAR
          </button>
          <button
            disabled={this.state.readyToAdvance ? false : true}
            className={this.state.readyToAdvance ? "btn-success" : "btn"}
            // variant={this.state.readyToAdvance ? "success" : "secondary"}
            style={{
              width: "200px",
              height: "50px",
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
              cursor: "pointer"
            }}
            onClick={this.handleShow}
          >
            AVANÇAR
          </button>
        </div>
      </div>
    );
  }
}

export default ColumnRelation;

ColumnRelation.contextType = Servicos;

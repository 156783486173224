import React, { Component } from "react";
import Switch from "react-switch";

import api from "../../../services/api";

import {
  Container,
  Header,
  Title,
  Subtitle,
  Form,
  Section,
  List,
  ListItem,
  Logo
} from "./styles";

import User from "../../../assets/imgs/user.png";

import UserEdit from "../../../components/Modal/User";
import Servicos from "../../../context/";

export default class Users extends Component {
  state = {
    users: [],
    loading: false,
    active: false,
    user: "",
    modalShow: false,
    value: 0,
    parameter: "",
    message: ""
  };
  componentDidMount() {
    this.context.acoes.showLoading();
    this.users();
   
    setTimeout(() => {
      this.openModalForParamUser();
    }, 2500);

  }

  openModalForParamUser(){

    const search = this.props.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const user_email = params.get('user'); // bar

    let index = this.findIndexForUser(user_email);

    if (index != undefined){
      this.openClose(index);
    }

  }
  findIndexForUser = (user_email) => {
    let indexFound = undefined
    this.state.users.map((dado, index) => {
      if (dado.user_email == user_email){
        indexFound = index;
      }
    })

    return indexFound;

  }
  search(parameter) {
    this.setState({message: ""});
    this.context.acoes.showLoading();
    api
      .get("users/?search=" + parameter)
      .then(response => {
        this.context.acoes.hideLoading();
        if(response.data.data.length < 1) {
          this.setState({message: "Nenhum registro encontrado!"});
        }
        this.setState({ users: response.data.data });
        this.context.acoes.setAccess(response.data.requester_profile);
        
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError()
        this.context.acoes.setMessage(error.response.data.message)
        this.context.acoes.setErrorStatus(error.response.status)
        this.context.acoes.setAccess(error.response.data.requester_profile);
        
      })
  }
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      this.setState({message: ""});
      this.context.acoes.showLoading();
      api
      .get("users/?search=" + e.target.value)
      .then(response => {
        this.context.acoes.hideLoading();
        if(response.data.data.length < 1) {
          this.setState({message: "Nenhum registro encontrado!"});
        }
        this.setState({ users: response.data.data });
        this.context.acoes.setAccess(response.data.requester_profile);
        
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError()
        this.context.acoes.setMessage(error.response.data.message)
        this.context.acoes.setErrorStatus(error.response.status)
        this.context.acoes.setAccess(error.response.data.requester_profile);

        
      })
      }
  };
  update = index => {
    this.context.acoes.showLoading();
    let user = this.state.users[index];
    let payload = {
      active: !user.active,
      user_avatar: user.user_avatar ? user.user_avatar : "/",
      user_email: user.user_email,
      user_name: user.user_name
      // user_operations: operations
    };
    api
      .put("users/" + user.user_id, payload)
      .then(response => {
        this.context.acoes.setAccess(response.data.requester_profile);
        
        this.context.acoes.hideLoading()
        this.users();
      })
      .catch(error => {
        this.context.acoes.hideLoading()
        this.context.acoes.showError()
        this.context.acoes.setMessage(error.response.data.message)
        this.context.acoes.setErrorStatus(error.response.status)
        this.context.acoes.setAccess(error.response.data.requester_profile);
        
    
      });
    // .then(this.users())
  };

  users = () => {
    this.context.acoes.showLoading()
    api
      .get("users")
      .then(response => {
        this.setState({ users: response.data.data });
        // 
        this.setState({ loading: true });
        // this.context.acoes.profileCall()
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);

      })
      .catch(error => {
        this.context.acoes.hideLoading()
        this.context.acoes.showError()
        this.context.acoes.setMessage(error.response.data.message)
        this.context.acoes.setErrorStatus(error.response.status)
        this.context.acoes.setAccess(error.response.data.requester_profile);

        // 
        // this.setState({ modalShow: true });
        // this.setState({ loading: false });
        // return <ErrorModal show={this.state.modalShow} onHide={modalClose} />;
      }, this);
  };

  modalClose = () => {
    // this.users();
    this.setState({ ...this.state, modalShow: false });
  };
  openClose = index => {
    let user = this.state.users[index];
    this.setState({ ...this.state, modalShow: true, user: user });
  };
  refresh=()=>{
    this.context.acoes.showLoading()
    this.users()
  }
  defineOperationsToUser = (operation_id,flagSetUserOperation,caller) => {

    if (!flagSetUserOperation){
      this.state.user.user_operations.map((op, index) => {
          if (op.operation_id == operation_id){
            this.state.user.other_operations.push(op)
            this.state.user.user_operations.splice(index,1);
          }
      })      
    } else {

      this.state.user.other_operations.map((op, index) => {
        if (op.operation_id == operation_id){
          this.state.user.user_operations.push(op)
          this.state.user.other_operations.splice(index,1);
        }
    })
    
    

    }
  }

  render() {
    return (
      // <IsLoading.Consumer>
      // {context => (
      <Container className="d-flex h-100 flex-wrap ">
        <UserEdit
          show={this.state.modalShow}
          onHide={e => this.modalClose()}
          user={this.state.user}
          update={this.refresh}
          defineOperationsToUser={this.defineOperationsToUser}
        />
        <Header className="d-flex flex-wrap align-items-center w-100" style={{height: "20px"}}>
          <div className="col-12 " style={{ marginTop: "1%"}}>
            <Title>Usuários</Title>
          </div>
          <div className="d-flex flex-wrap w-100">
            <Subtitle className="col-12 ">
              Gerenciamento de usuários e perfis
            </Subtitle>
            {/* <Form className="col-12 col-md-6 d-flex justify-content-end"> */}
              <div className="mr-0 col-12 col-md-4">
              <input style={{ width: "100%", borderRadius: "5vw", border: "1px solid #ccc", padding: "3px 10px" }} onKeyDown={event => this._handleKeyDown(event)} type="text" placeholder="Buscar" className="" value={this.state.parameter} onChange={e => this.setState({ parameter: e.target.value })}/>
                <button style={{ position: "absolute", padding: "3px 10px", background: "transparent", border: "0px solid", right: "20px" }}onClick={() => this.search(this.state.parameter)}>
                  <span className="icon-search" />
                </button>
              </div>
            {/* </Form> */}
          </div>
        </Header>
        <Section className="col-12" style={{ height: "55%",cursor:"pointer"}}>
          {this.state.loading ? (
            <List>
              {this.state.message != "" ? 
              <ListItem>
                <div 
                  className="col-12 col-md-4 d-flex"
                >
                  <small className="col-12 col-md-9 d-flex" style={{ color: "#808184", fontSize:14}}>
                    {this.state.message}
                  </small>
                </div>
              </ListItem> 
              :
              this.state.users.map((dado, index) => (
                <ListItem key={dado.user_id} className="flex-wrap mb-4">
                  <div
                    className="col-12 col-md-3 d-flex"
                    onClick={e => this.openClose(index)}
                  >
                    <Logo src={dado.user_avatar} alt="Algar" height="50vh" width="50vh" style={{ cursor:"pointer", objectFit: "cover"}}/>
                    <div className="col-9">
                      <h6 className="color-bluedark font-weight-normal" >
                        Nome
                      </h6>
                      <small style={{color:"#808184"}}>{dado.user_name}</small>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2"
                    onClick={e => this.openClose(index)}
                  >
                    <div>
                      <h6 className="color-bluedark font-weight-normal">
                        Usuário
                      </h6>
                      <small style={{color:"#808184"}}>{dado.user_id}</small>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2"
                    onClick={e => this.openClose(index)}
                  >
                    <div>
                      <h6 className="color-bluedark font-weight-normal">
                        E-mail
                      </h6>
                      <small style={{color:"#808184"}}>{dado.user_email}</small>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2"
                    onClick={e => this.openClose(index)}
                  >
                    <div>
                      <h6 className="color-bluedark font-weight-normal">
                        Perfil
                      </h6>
                      <small style={{color:"#808184"}}>{dado.user_profile}</small>
                    </div>
                  </div>
                  <div
                    className="col-6 col-md-2 "
                    onClick={e => this.openClose(index)}
                  >
                    <div>
                      <h6 className="color-bluedark font-weight-normal">
                        Operações
                      </h6>
                      {typeof dado.user_operations !== "undefined" &&
                      dado.user_operations.length > 0 ? (
                        <small style={{color:"#808184"}}>
                            {dado.user_operations.map( (op, index) => 
                              index+1 < dado.user_operations.length ?  op.operation_name + ", " :  op.operation_name  
                            )
                          }
                        </small>
                      ) : (
                        <small style={{color:"#808184"}}>Sem Operações</small>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-1">
                    <div>
                      <h6 className="color-bluedark font-weight-normal">
                        Status
                      </h6>
                      <div className="switch_input" style={{"height":"100%","width":"100%"}}>
                        
                      <label htmlFor="small-radius-switch">
                      <Switch
                        disabled={(localStorage.getItem("data") === dado.user_id) ? true : false}
                        checked={dado.active}
                        onChange={() => {
                          this.update(index);
                        }}
                        onColor="#d0d0d0"
                        onHandleColor="#00A550"
                        offColor="#d0d0d0"
                        offHandleColor="#FA4D4D"
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          >
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "orange",
                              paddingRight: 2
                            }}
                          >
                          </div>
                        }
                        className="react-switch"
                        id="icon-switch"
                        
                      />
                      </label>                        
                        {/* <Switch
                          value={dado.active}
                          on={true}
                          off={false}
                          onChange={() => {
                            this.update(index);
                          }}
                          styles={{
                            trackChecked: {
                              backgroundColor: "#cccccc"
                            },
                            button: {
                              backgroundColor: "red"
                            },
                            buttonChecked: {
                              backgroundColor: "green"
                            }
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                </ListItem>
              ))}
            </List>
          ) : (
            "loading"
          )}
        </Section>
      </Container>
      // )}
      // </IsLoading.Consumer>
    );
  }
}

Users.contextType = Servicos;
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, Redirect } from "react-router-dom";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import "./import.css";
import "./stopwords.css";
import api from "../../../../services/api";
import Servicos from "../../../../context";
import history from "../../../../routes/history";
import Error from "../../../../components/Modal/Error";
import Tooltip from "@material-ui/core/Tooltip";
import { Container, Header, Title, Subtitle } from "./styles";
import importIcon from "../../../../assets/svgs/file.svg";
import { ToastContainer, toast } from "react-toastify";

import {
  IoIosCloseCircleOutline,
  IoIosHelpCircleOutline
} from "react-icons/io";

import { List, ListItem } from "./styles";
import { Section } from "../..";

class StopWords extends Component {
  state = {
    operation: "",
    focus: false,
    show: false,
    StopWords: [],
    options: false,
    arroz: [],
    qtd: "",
    redirect: false,
    stopselecionado: [],
    modalShow: false,
    modalProcesso: false,
    modalNome: false,
    nomeLista: "",
    lists: [],
    showLists: false,
    liberado: false,
    msgStatus: "",
    should: false
  };

  constructor(props) {
    function Trim(str) {
      return str.replace(/^\s+|\s+$/g, "");
    }
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  modalClose = () => {
    this.setState({ modalShow: false });
  };
  modalProcessoClose = info => {
    this.setState({ modalProcesso: false });
    if (info === true) history.push("/home");
  };
  modalNomeClose = () => {
    this.setState({ modalNome: false });
  };
  openClose = () => this.setState({ ...this.state, modalShow: true });
  openCloseProcesso = () => {
    this.setState({ modalProcesso: true });
  };
  openCloseNome = () => {
    this.setState({ modalNome: true });
  };
  getStopWords = () => {
    api
      .get("/analysis/" + localStorage.getItem("analysis_id") + "/stopwords")
      .then(response => {
        this.setState({ arroz: response.data.stop_words });
        this.setState({ StopWords: response.data.stop_words });
        this.setState({ stopselecionado: response.data.stop_words });
        this.setState({ qtd: response.data.quantidadeTopicos });
        this.context.acoes.setAccess(response.data.requester_profile);

        if (response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

        if (error.response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      });
  };

  componentDidMount() {
    this.start();
    // if(localStorage.getItem('stopwords')){
    //   this.setState({stopselecionado:localStorage.getItem('stopwords')})
    // }
  }
  start = async () => {
    await this.getLists();
    if (localStorage.getItem("analysis_id") != null) {
      await this.getStopWords();
    }
    this.context.acoes.showLoading();
    api
      .get("/analysis/" + localStorage.getItem("analysis_id"))
      .then(response => {
        this.setState({ operation: response.data.data.operation_id });
        this.context.acoes.hideLoading();
        this.context.acoes.setAccess(response.data.requester_profile);

        if (response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);

        if (error.response.data.requester_profile === "Operação") {
          history.push("/home");
        }
      });
  };
  passing(value) {
    var res = [];

    if (value != "") {
      res = value.split(",");
    }
    this.setState({ arroz: res });
  }
  handleChange(value) {
    let newValue = "";

    if (value != "") {
      let arrayOfWords = value.split(",");
      let indexesToRemove = [];
      arrayOfWords.map((text, index) => {
        let newText = text.trimStart();
        newText = newText.trimEnd();

        if (newText != "") {
          arrayOfWords[index] = newText;
        } else {
          indexesToRemove.push(index);
        }
      });

      indexesToRemove.map((indexToRemove, i) => {
        arrayOfWords.splice(indexToRemove - i, 1);
      });

      newValue = arrayOfWords.join(",");
    }
    this.setState({ StopWords: newValue });
    this.passing(newValue);
  }
  salvar = () => {
    if (this.state.stop_words) {
      this.context.acoes.showLoading();
      api
        .put(
          "/operations/" + this.state.operation + "/stopwords",
          this.state.arroz
        )
        .then(response => {
          this.context.acoes.hideLoading();
          this.context.acoes.setAccess(response.data.requester_profile);
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    }
  };
  deleteStopword = index => {
    var vetor = this.state.arroz;
    if (index >= 0) {
      vetor.splice(index, 1);
      this.setState({ arroz: vetor });
      document.getElementById("textAreaStopWords").value = vetor.join();
    }
  };
  send = () => {
    this.context.acoes.showLoading();
    localStorage.setItem("stopwords", this.state.arroz);
    let payload = {
      operacao: this.state.operation,
      stopWords: this.state.arroz,
      quantidadeTopicos: parseInt(this.state.qtd)
    };
    if (this.state.qtd > 9) {
      if (this.state.arroz && this.state.qtd) {
        api
          .post(
            "/analysis/" + localStorage.getItem("analysis_id") + "/stopwords",
            payload
          )
          .then(response => {
            this.context.acoes.hideLoading();
            this.setState({
              qtd: "",
              msgStatus:
                "Esta análise está em processamento. Por favor, aguarde alguns minutos.",
              should: true
            });
            setTimeout(() => {
              this.openCloseProcesso();
            }, 50);
            this.context.acoes.setAccess(response.data.requester_profile);

            // history.push("/home");
          })
          .catch(error => {
            this.context.acoes.hideLoading();
            this.context.acoes.showError();
            this.context.acoes.setMessage(error.response.data.message);
            this.context.acoes.setErrorStatus(error.response.status);
            this.context.acoes.setAccess(error.response.data.requester_profile);
          });
      } else {
        this.context.acoes.hideLoading();
      }
    } else {
      this.setState({ modalShow: true });
      this.context.acoes.hideLoading();
    }
  };
  loadStop = () => {
    this.context.acoes.showLoading();
    api
      .get("/operations/" + this.state.operation + "/stopwords")
      .then(response => {
        this.context.acoes.hideLoading();
        this.setState({ ...this.state, stopWords: response.data.stop_words });
        this.setState({ ...this.state, options: true });
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);
      });
  };
  handleClose() {
    this.setState({ show: false });
    this.setState({ options: false });
  }

  handleShow() {
    this.loadStop();

    this.setState({ show: true });
  }

  cancelar = () => {
    api
      .delete("/analysis/" + localStorage.getItem("analysis_id") + "/hard")
      .then(response => {
        this.context.acoes.hideLoading();
        return history.push("/home");
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        if (error.response.status != 403) {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        } else {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        }
      });
  };

  getLists = () => {
    api
      .get("/operations/" + localStorage.getItem("analysis_id") + "/stopwords")
      .then(response => {
        this.setState({ lists: response.data.stop_words });
        this.context.acoes.setAccess(response.data.requester_profile);
      })
      .catch(error => {
        this.context.acoes.hideLoading();
        this.context.acoes.showError();
        this.context.acoes.setMessage(error.response.data.message);
        this.context.acoes.setErrorStatus(error.response.status);
        this.context.acoes.setAccess(error.response.data.requester_profile);
      });
  };

  adicionarStopwords = () => {
    this.context.acoes.showLoading();
    // localStorage.setItem("stopwords", this.state.arroz);
    let payload = {
      list_name: this.state.nomeLista,
      stop_words: this.state.arroz
    };
    if (this.state.arroz) {
      this.modalNomeClose();
      api
        .put(
          "/operations/" + localStorage.getItem("analysis_id") + "/stopwords",
          payload
        )
        .then(response => {
          this.context.acoes.hideLoading();
          this.setState({
            msgStatus: "Lista " + this.state.nomeLista + " salva com sucesso!",
            should: false
          });
          setTimeout(() => {
            this.openCloseProcesso();
          }, 50);

          // history.push("/home");
          this.setState({ nomeLista: "" });
          this.setState({ liberado: false });
          this.context.acoes.setAccess(response.data.requester_profile);

          // toast.success("Stop Words salva!", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: false,
          //   draggable: true
          // });
          this.getLists();
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);
        });
    } else {
      this.context.acoes.hideLoading();
    }
  };
  selectList = stop_words => {
    this.setState({ stopselecionado: stop_words });
    this.setState({ arroz: stop_words });
    this.setState({ showLists: false });
  };
  showLists = () => {
    this.setState({ showLists: true });
  };
  closeLists = () => {
    this.setState({ showLists: false });
  };
  set = e => {
    this.setState({ stopselecionado: e.target.value, liberado: true });
    this.handleChange(e.target.value);
  };
  render() {
    const { arroz, qtd, focus, stopselecionado } = this.state;
    return (
      <Servicos.Consumer>
        {context => (
          <div className="IMPORT">
            <ToastContainer />

            <Modal
              {...this.props}
              dialogClassName="modal-80w excluir"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              analise={this.state.selecionado}
              show={this.state.modalProcesso}
              onHide={e => this.modalProcessoClose()}
            >
              {/* <Modal.Header closeButton /> */}
              <Modal.Body
                className="position-relative d-flex justify-content-center"
                style={{ paddingTop: "10%" }}
              >
                <div>
                  <h4 style={{ fontSize: 14 }}>{this.state.msgStatus}</h4>
                </div>
              </Modal.Body>

              <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
                <button
                  className="btn-success mb-3"
                  style={{ width: 200, height: 50, fontSize: 14 }}
                  onClick={e => this.modalProcessoClose(this.state.should)}
                >
                  OK
                </button>
              </Modal.Footer>
            </Modal>
            <Error
              show={this.state.modalShow}
              onHide={e => this.modalClose()}
              message={"O campo quantidade de tópicos deve ser preenchido!"}
              status={401}
            />
            <Modal
              {...this.props}
              show={this.state.modalNome}
              onHide={e => this.modalNomeClose()}
              dialogClassName="modal-80w excluir"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              {/* <Modal.Header closeButton /> */}
              <Modal.Body className="position-relative  text-center d-flex align-items-center flex-wrap pt-5 pb-5">
                <br />
                <p>Nome da lista de stop words</p>
                {/* <div className="STOPWORDS-BODY"> */}
                <input
                  className="w-100 form-control"
                  type="text"
                  // defaultValue={
                  //   this.state.stopselecionado ? this.state.stopselecionado : ""
                  // }
                  onChange={e => this.setState({ nomeLista: e.target.value })}
                  // placeholder="Adicione as stopwords com virgula separando"
                />
                {/* </div> */}
              </Modal.Body>

              <Modal.Footer className="border-white d-flex flex-wrap justify-content-center">
                <button
                  //className="btn"
                  onClick={e => this.adicionarStopwords()}
                  disabled={!!this.state.nomeLista ? false : true}
                  className={!!this.state.nomeLista ? "btn-success" : "btn"}
                  style={{
                    width: "200px",
                    height: "50px",
                    fontSize: 14,
                    marginRight: 0
                  }}
                >
                  SALVAR
                </button>
                <button
                  className="btn"
                  onClick={e => this.modalNomeClose()}
                  style={{
                    width: "200px",
                    height: "50px",
                    fontSize: 14,
                    marginTop: "10px",
                    marginLeft: 0
                  }}
                >
                  CANCELAR
                </button>
              </Modal.Footer>
            </Modal>
            <div className="IMPORT-LEFT">
              <Container className=" ">
                <Header
                  className="d-flex flex-wrap align-items-center w-100"
                  style={{ height: "20px" }}
                >
                  <div
                    className="col-12 "
                    style={{ marginTop: "10%", marginLeft: "3.5%" }}
                  >
                    <Title>Análises</Title>
                  </div>
                  <div
                    className="d-flex flex-wrap w-100"
                    style={{ marginLeft: "3.5%" }}
                  >
                    <Subtitle className="col-12">Adicionar stop words</Subtitle>
                  </div>
                </Header>
              </Container>

              <div className="IMPORT-LEFT-FORM-STOP">
                <div className="STOPWORDS">
                  <div className="STOPWORDS-HEADER">
                    <h1>Stop Words *</h1>
                    <div className="d-flex justify-content-around w-100 mb-2">
                      <Tooltip title="Salvar Stop Words" placement="top">
                        <div className="d-flex">
                          <span
                            onClick={() => this.openCloseNome()}
                            style={{
                              display:
                                this.state.arroz.length > 0 &&
                                this.state.liberado
                                  ? "flex"
                                  : "none",
                              margin: 0,
                              marginLeft: "0!important",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                            className="icon-plus font-weight-bold"
                            role="button"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Importar Stop Words" placement="top">
                        <div>
                          <img
                            onClick={() => this.showLists()}
                            style={{
                              width: 20,
                              margin: 0,
                              cursor: "pointer",
                              display:
                                this.state.lists.length > 0 ? "flex" : "none"
                            }}
                            src={importIcon}
                            // className="icon-plus ml-2 font-weight-bold"
                            role="button"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="STOPWORDS-BODY">
                    <textarea
                      id="textAreaStopWords"
                      className="w-100 form-control"
                      type="text"
                      value={stopselecionado}
                      onChange={e => this.set(e)}
                      placeholder="Adicione as stopwords com virgula separando"
                    />
                  </div>
                </div>

                <div className="QTD-TOPICOS">
                  <div className="QTD-TOPICOS-HEADER">
                    <h1>Quantidade *</h1>
                    <Tooltip
                      title="O número de tópicos deve ser maior ou igual a 10."
                      placement="right"
                    >
                      <div style={{ margin: 0, marginLeft: "5%" }}>
                        <span
                          class="far fa-question-circle"
                          // data-toggle="tooltip"
                          // data-placement="top"
                          // title="O número de tópicos deve ser maior ou igual a 10."
                          style={{
                            color: "rgba(128, 129, 132, 1)",
                            fontSize: 12,
                            // cursor: "pointer",
                            margin: "0!important",
                            marginLeft: "5%"
                          }}
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="QTD-TOPICOS-BODY">
                    <input
                      min="0"
                      style={{ fontSize: 14 }}
                      defaultValue={this.state.qtd ? this.state.qtd : null}
                      onChange={event => {
                        this.setState({ qtd: event.target.value });
                        if (Number(event.target.value) === 0) {
                          event.target.value = "";
                          this.setState({ focus: false, qtd: "" });
                        }
                      }}
                      type={focus ? "number" : "text"}
                      onFocus={() => this.setState(state => ({ focus: true }))}
                      placeholder="Adicione a quantidade de tópicos"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="IMPORT-LEFT-BUTTON-STOP">
                {/* <div className="IMPORT-LEFT-BUTTON"> */}
              </div>
            </div>
            {this.state.showLists == true ? (
              <div className="IMPORT-RIGHT">
                {/* <div className="IMPORT-RIGHT-STOPWORDS"> */}
                {/* <Section > */}
                <List>
                  {this.state.lists.map((list, index) => (
                    <ListItem key={index} className="flex-wrap mb-4">
                      <div>
                        <h6 className="color-bluedark font-weight-normal">
                          Nome
                        </h6>
                        <small style={{ color: "#808184" }}>
                          {list.list_name}
                        </small>
                      </div>
                      <div>
                        <h6 className="color-bluedark font-weight-normal">
                          Stopwords
                        </h6>

                        <small style={{ color: "#808184" }}>
                          {list.stop_words.map(stop => stop + ", ")}
                        </small>
                      </div>
                      <div>
                        <button
                          onClick={() => this.selectList(list.stop_words)}
                          className="bg-bluelight"
                        >
                          Selecionar
                        </button>
                      </div>
                    </ListItem>
                  ))}
                </List>
                {/* </Section> */}
                <div className="IMPORT-RIGHT-BUTTON">
                  <button
                    onClick={() => this.closeLists()}
                    style={{
                      marginRight: "5px",
                      width: "200px",
                      height: "50px",
                      fontSize: 16,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    className="btn"
                  >
                    CANCELAR
                  </button>
                </div>
                {/* </div> */}
              </div>
            ) : (
              <div className="IMPORT-RIGHT">
                <div className="IMPORT-RIGHT-HEADER">
                  <p>Stop words</p>
                </div>
                <div className="IMPORT-RIGHT-STOPWORDS">
                  {arroz.map((item, index) => (
                    <Badge bsPrefix="BADGES-STOPWORDS" variant="primary">
                      {item}{" "}
                      <IoIosCloseCircleOutline
                        size={23}
                        onClick={() => this.deleteStopword(index)}
                        style={{
                          width: "30px",
                          heigth: "30px",
                          cursor: "pointer",
                          marginLeft: "15px"
                        }}
                      />
                    </Badge>
                  ))}
                </div>
                <div className="IMPORT-RIGHT-BUTTON">
                  <button
                    onClick={this.send}
                    style={{
                      width: "200px",
                      height: "50px",
                      fontSize: 16,
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    disabled={
                      this.state.qtd > 9 && this.state.arroz.length > 0
                        ? false
                        : true
                    }
                    className={
                      this.state.qtd > 9 && this.state.arroz.length > 0
                        ? "btn-success"
                        : "btn"
                    }
                  >
                    AVANÇAR
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Servicos.Consumer>
    );
  }
}

export default StopWords;
StopWords.contextType = Servicos;

import React, { Component, Fragment } from "react";
import "./SQL.css";
import api from "../../../../../services/api";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import Servicos from "../../../../../context";

class Sql extends Component {
  state = {
    host: "",
    port: "",
    user: "",
    password: "",
    comandoSQL: ""
    // redirect: false
  };
  validate = data => {
    if (
      data !== null &&
      data !== undefined &&
      data !== "undefined" &&
      data !== "null" &&
      data !== "" &&
      data.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  send = () => {
    var { host, port, user, password, comandoSQL } = this.state;
    if (
      this.validate(host) &&
      this.validate(port) &&
      this.validate(user) &&
      this.validate(password) &&
      this.validate(comandoSQL) &&
      this.validate(localStorage.getItem("analysis_id"))
    ) {
      this.context.acoes.bloquearMenu();

      let payload = {
        sql_import: {
          codigoAnalise: localStorage.getItem("analysis_id"),
          tipoLeitura: "sql",
          host: host,
          port: port,
          user: user,
          password: password,
          sql_commands: comandoSQL
        }
      };
      this.context.acoes.showLoading();

      api
        .post(
          "/analysis/" + localStorage.getItem("analysis_id") + "/sql",
          payload
        )
        .then(response => {
          this.context.acoes.hideLoading();
          this.context.acoes.desbloquearMenu();
          this.context.acoes.liberar();
          this.context.acoes.setAccess(response.data.requester_profile);

          this.setState({password:""})
        })
        .catch(error => {
          this.context.acoes.hideLoading();
          this.context.acoes.showError();
          this.context.acoes.setMessage(error.response.data.message);
          this.context.acoes.setErrorStatus(error.response.status);
          this.context.acoes.setAccess(error.response.data.requester_profile);

        });
    }
  };
  redirect() {
    if (this.state.redirect) {
      return <Redirect to="/app/columnrelation" />;
    }
  }
  render() {
    var { host, port, user, password, comandoSQL } = this.state;
    return (
      <Fragment>
        <div className="d-flex align-items-center sqlHeader">
          <div className="SQL-HEADER">
            <div className="col-9 pl-0">
              <input
                className="SQL-INPUT-ONE"
                type="text"
                placeholder="HOST"
                onChange={event => this.setState({ host: event.target.value })}
              />
            </div>
            <div className="col-3 pr-0">
              {" "}
              <input
                className="SQL-INPUT-ONE"
                type="number"
                min="0"
                placeholder="Porta"
                onChange={event => this.setState({ port: event.target.value })}
              />
            </div>
            <div className="col-6 pl-0 mb-2 mt-1">
              <input
                className="SQL-INPUT-ONE"
                type="text"
                placeholder="Usuário"
                onChange={event => this.setState({ user: event.target.value })}
              />
            </div>
            <div className="col-6 pr-0 mb-2 mt-1">
              {" "}
              <input
                className="SQL-INPUT-ONE"
                type="password"
                placeholder="Senha"
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
              />
            </div>
          </div>
          <button
            disabled={this.validate(host) &&
              this.validate(port) &&
              this.validate(user) &&
              this.validate(password) &&
              this.validate(comandoSQL) ? false : true}
            className={
              this.validate(host) &&
              this.validate(port) &&
              this.validate(user) &&
              this.validate(password) &&
              this.validate(comandoSQL) ? "btn-success" : "btn"
            }
            onClick={e => this.send()}
            style={{
              width: "100px",
              height: "50px",
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft:"2%"
            }}
          >
            Conectar
          </button>
        </div>

        <div className="SQL-BODY">
          <textarea
            className="SQL-TEXT-AREA"
            type="text"
            rows="2"
            placeholder="SQL QUERY"
            style={{maxHeight:180, minHeight:150}}
            onChange={event =>
              this.setState({ comandoSQL: event.target.value })
            }
          />
        </div>
      </Fragment>
    );
  }
}
export default Sql;
Sql.contextType = Servicos;
